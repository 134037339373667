import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCEIMFOCertificateOrderSearchHistoryItem} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';

@Component({
    selector: 'lcei-mfo-certificate-order-search-history-item',
    template: `
        <div class="xs-flex-row xs-align-items-center xs-width-full xs-justify-content-space-between {{ styleClass }}" [ngClass]="{ 'xsi-header-stripe-discrete-darker-5': stripe }">
            <div class="xs-flex-column xs-overflow-hidden">
                <xsi-text
                        [applyClickableStyle]="isClickable()"
                        (clickEvent)="onClick($event)"
                        [fontWeight500]="true"
                        [color]="isClickable() ? '' : 'secondary'"
                        [value]="(isClickable() ? '' : '<small>' + (TR_BASE + 'textType.byVerificationCode' | translate) + '</small> &nbsp; ') + data.value"
                        [ellipsis]="true">
                </xsi-text>
                <div class="xs-flex-row xs-align-items-baseline xs-overflow-hidden xs-mt-5">
                    <xs-time-ago
                            class="xs-overflow-hidden"
                            [givenDate]="data.on"
                            [shortMode]="false"
                            [live]="false"
                            styleClass="xs-display-block xsi-color-secondary xs-font-size-small xs-ellipsis">
                    </xs-time-ago>
                    <xsi-text
                            class="xs-overflow-hidden"
                            [ellipsis]="true"
                            [value]="TR_BASE + 'history.assistedBy' | translate: {fullName: data.assistedBy.fullName}"
                            size="small" color="secondary"
                            styleClass="xs-ml-5">
                    </xsi-text>
                </div>
            </div>
            <div class="xs-flex-column">
                <xsi-chip
                        [data]="{ label: TR_BASE + 'type.' + data.type }"
                        labelStyleClass="xs-min-width-75 xs-font-weight-500"
                        styleClass="xs-height-20 xs-ml-10">
                </xsi-chip>
            </div>
        </div>
    `
})
export class LCEIMFOCertificateOrderSearchHistoryItemComponent implements OnInit {

    readonly TR_BASE: string = 'lcei.mfo.features.certificateOrder.search.';

    @Input() styleClass?: string;

    @Input() data: LCEIMFOCertificateOrderSearchHistoryItem;

    @Input() stripe?: boolean;

    @Output() clickEvent = new EventEmitter<LCEIMFOCertificateOrderSearchHistoryItem>();

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public onClick(event: Event): void {
        if (!this.isClickable()) {
            event.stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(this.data);
    }

    public isClickable(): boolean {
        return this.data.type === 'text' && this.data.searchTextType !== 'byVerificationCode';
    }
}
