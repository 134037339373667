import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_CERTIFICATE_TYPE, LCECertificate} from '@lce/core';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {XSIIcon} from '@xs/ionic/common';
import {LCEI_SHARED_ICON} from '../../../api/constants/lcei-shared-icon.constant';
import {XSSize, XSTranslationService} from '@xs/common';
import {XSIDataSectionHeader} from '@xs/ionic/core';

@Component({
    selector: 'lcei-certificate-record',
    templateUrl: 'lcei-certificate-record.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateRecordComponent implements OnInit {

    readonly TR_BASE: string = 'lcei.shared.certificateOrder.';

    readonly TR_BASE_CERTIFICATE_TYPE = LCE_TR_BASE_CERTIFICATE_TYPE;

    readonly ICON_CERTIFICATE: XSIIcon = {value: LCEI_SHARED_ICON.certificate, styleClass: 'lcei-certificate-icon-size-25'};

    readonly ICON_MENU: XSIIcon = {
        value: LCEI_SHARED_ICON.menu,
        size: XSSize.MEDIUM,
        extendClickableArea: true,
        extendClickableAreaSize: XSSize.INTERMEDIATE,
        onClick: () => this.onMenuClick()
    };

    @Input() styleClass?: string;
    @Input() sectionHeaderStyleClass?: string;

    @Input() data: LCECertificate;
    @Input() facilityFullName: string;

    @Input() showSectionHeader?: boolean;
    @Input() sectionHeader?: XSIDataSectionHeader;

    constructor(private translationService: XSTranslationService) {
    }

    get issueDateLabel(): string {
        return 'lce.core.label.' + (this.data.issueDateUseYear ? 'yearOfIssue' : 'issueDate');
    }

    get issueDateFormat(): string {
        const language: XSLanguage = this.translationService.getCurrentLanguage();
        return this.data.issueDateUseYear ? 'yyyy' : (language === XSLanguage.FRENCH ? 'XS_DATE_FORMAT_DD_MM_YYYY' : 'XS_DATE_FORMAT_YYYY_MM_DD');
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        XSAssert.notEmpty(this.data, 'data');

        this.buildSectionHeader('certificate', 'certificateDescription');
    }

    public hasMenu(): boolean {
        return false;
    }

    private onMenuClick(): void {
        console.log('---| Certificate Menu clicked (not implemented yet !).');
    }

    private buildSectionHeader(titleKey: string, subtitleKey: string): void {
        if (XSUtils.isEmpty(this.sectionHeader)) this.sectionHeader = {title: undefined!};

        if (XSUtils.isEmpty(this.sectionHeader!.icon)) this.sectionHeader!.icon = this.ICON_CERTIFICATE;

        if (XSUtils.isEmpty(this.sectionHeader!.title)) {
            this.sectionHeader!.title = {
                size: 'default',
                color: 'primary',
                fontWeight600: true,
                value: this.TR_BASE + titleKey
            };
        }

        if (XSUtils.isEmpty(this.sectionHeader!.subtitle)) {
            this.sectionHeader!.subtitle = {
                size: 'small',
                color: 'secondary',
                value: this.TR_BASE + subtitleKey
            };
        }
    }
}


