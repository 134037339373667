<div class="xs-flex-column xs-width-full {{ styleClass }}">
    <lcei-facility-partial
            *ngIf="isDestination(DESTINATION_TYPE.FACILITY)"
            [avatarColor]="avatarColor"
            [avatarPosition]="avatarPosition"
            [data]="destinationFacility.facility"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [label]="label.value"
            [showAvatar]="showIcon"
            [showCode]="full"
            [styleClass]="dataStyleClass">
    </lcei-facility-partial>
    <lcei-service-point-partial
            *ngIf="isDestination(DESTINATION_TYPE.SERVICE_POINT)"
            [avatarColor]="avatarColor"
            [avatarPosition]="avatarPosition"
            [data]="destinationServicePoint.servicePoint"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [label]="label.value"
            [showAvatar]="showIcon"
            [showCode]="full"
            [styleClass]="dataStyleClass">
    </lcei-service-point-partial>
    <xsi-ivar
            *ngIf="isDestination(DESTINATION_TYPE.CUSTOM_LOCATION)"
            [avatarPosition]="avatarPosition"
            [avatar]="getAvatar(DESTINATION_TYPE.CUSTOM_LOCATION)"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [line1]="label"
            [line2]="{ value: destinationCustomLocationLine2, size: 'intermediate', fontWeight500: true }"
            [line3]="toLine3Text(destinationCustomLocationLine3)"
            [styleClass]="dataStyleClass">
    </xsi-ivar>
    <xsi-ivar
            *ngIf="isDestination(DESTINATION_TYPE.INTERNATIONAL)"
            [avatarPosition]="avatarPosition"
            [avatar]="getAvatar(DESTINATION_TYPE.INTERNATIONAL)"
            [disabled]="disabled"
            [ellipsis]="ellipsis"
            [line1]="label"
            [line2]="{ value: destinationInternationalLine2, size: 'intermediate', fontWeight500: true }"
            [line3]="toLine3Text(destinationInternationalLine3)"
            [styleClass]="dataStyleClass">
    </xsi-ivar>
</div>