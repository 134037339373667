import {Injectable} from '@angular/core';
import {LCEIAuthenticationService} from '@lce/ionic/shared';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';

@Injectable({providedIn: 'root'})
export class LCEIMFOAuthenticationService extends LCEIAuthenticationService {

    protected constructor(protected storageService: LCEIMFOStorageService) {
        super(storageService);
    }
    
    public openPINCodeAuthenticationModal(): void {
        // TODO: open auth modal.
    }

    public async isAuthenticated(): Promise<boolean> {
        // TODO : We need retrieve the municipal employee user's token and token expiration and check if his actually authenticated.
        // const terminalRegistration = await this.storageService.getStoredTerminalRegistration();
        // return this.isTokenValid(terminalRegistration.token, terminalRegistration.token) && !XSUtils.isEmpty(user);
        return true;
    }
}
