import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEICertificateOrderRecordBaseComponent} from '../lcei-certificate-order-record-base.component';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE, LCECertificateOrderProcuration} from '@lce/core';

@Component({
    selector: 'lcei-certificate-order-record-procuration',
    templateUrl: 'lcei-certificate-order-record-procuration.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateOrderRecordProcurationComponent extends LCEICertificateOrderRecordBaseComponent implements OnInit {

    readonly TR_BASE: string = 'lcei.shared.certificateOrder.procuration.';

    readonly ICON_PROCURATION: XSIIcon = {value: LCEI_SHARED_ICON.procuration, size: '22px'};

    readonly TR_BASE_IDENTITY_DOCUMENT_TYPE = LCE_TR_BASE_IDENTITY_DOCUMENT_TYPE;

    @Input() styleClass?: string;

    @Input() data: LCECertificateOrderProcuration;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildSectionHeader('sectionTitle', 'sectionDescription');
    }

    public hasIdentityDocument(): boolean {
        return !XSUtils.isEmpty(this.data.identityDocumentType) && !XSUtils.isEmpty(this.data.identityDocumentNumber);
    }

    public isRepresentativeCustomer(): boolean {
        return !XSUtils.isEmpty(this.data.representativeCustomer);
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.ICON_PROCURATION;
    }
}


