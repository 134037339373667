<ion-header class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="close()"
            [cancelButton]="true"
            backButtonDefaultHref="/settings"
            cancelButtonLabel="xsi.core.label.close"
            title="{{ TR_BASE }}title"
            titlePosition="bottom-center"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page xs-pt-25 xs-pb-50">

            <xsi-icon-renderer
                    [data]="ICON_CERTIFICATE_ORDER"
                    class="xs-flex-row-center xs-width-full xs-mb-50"
                    styleClass="xs-filter-brightness-98">
            </xsi-icon-renderer>

            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <!-- General Section -->
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-column xs-width-full xs-plr-15 xs-mb-10">
                <xsi-text [fontWeight500]="true" color="secondary" size="default" value="{{ TR_BASE }}general"></xsi-text>
            </div>
            <ion-card class="xs-mtb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar
                                [line1]="{ value:TR_BASE + 'showTitleIcon.label' }"
                                [line2]="{ value:TR_BASE + 'showTitleIcon.description', size: 'small', color: 'secondary' }">
                        </xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'showTitleIcon')"
                            [checked]="createOptions?.showTitleIcon"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar
                                [line1]="{ value:TR_BASE + 'showSectionDescription.label' }"
                                [line2]="{ value:TR_BASE + 'showSectionDescription.description', size: 'small', color: 'secondary' }">
                        </xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'showSectionDescription')"
                            [checked]="createOptions?.showSectionDescription"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <ion-card class="xs-mt-25-imp xs-mb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar
                                [line1]="{ value:TR_BASE + 'inputBorder.label' }"
                                [line2]="{ value:TR_BASE + 'inputBorder.description', size: 'small', color: 'secondary' }">
                        </xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'inputBorder')"
                            [checked]="createOptions?.inputBorder"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <ion-card class="xs-mt-25-imp xs-mb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar
                                [line1]="{ value:TR_BASE + 'useCertificateIssueFullDate.label' }"
                                [line2]="{ value:TR_BASE + 'useCertificateIssueFullDate.description', size: 'small', color: 'secondary' }">
                        </xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'useCertificateIssueFullDate')"
                            [checked]="createOptions?.useCertificateIssueFullDate"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <ion-card class="xs-mt-25-imp xs-mb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label (click)="reset()" [innerHTML]="TR_BASE + 'reset' | translate" class="xs-clickable" color="primary"></ion-label>
                </ion-item>
            </ion-card>

            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <!-- Confirm Section -->
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-15 xs-mb-10">
                <xsi-text [fontWeight500]="true" color="secondary" size="default" value="{{ TR_BASE }}confirm.title"></xsi-text>
                <xsi-text color="secondary" size="small" styleClass="xs-mt-1" value="{{ TR_BASE }}confirm.subtitle"></xsi-text>
            </div>
            <ion-card class="xs-mtb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'confirm.beforeCreate' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'confirm.beforeCreate')"
                            [checked]="createOptions?.confirm?.beforeCreate"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'confirm.beforeClose' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'confirm.beforeClose')"
                            [checked]="createOptions?.confirm?.beforeClose"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'confirm.beforeReset' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'confirm.beforeReset')"
                            [checked]="createOptions?.confirm?.beforeReset"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <!-- Can Use Section -->
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-15 xs-mb-10">
                <xsi-text [fontWeight500]="true" color="secondary" size="default" value="{{ TR_BASE }}canUse.title"></xsi-text>
                <xsi-text color="secondary" size="small" styleClass="xs-mt-1" value="{{ TR_BASE }}canUse.subtitle"></xsi-text>
            </div>
            <ion-card class="xs-mtb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'canUse.customerPrimaryPhoneNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'canUse.customerPrimaryPhoneNumber')"
                            [checked]="createOptions?.canUse?.customerPrimaryPhoneNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'canUse.stampTokenCode' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'canUse.stampTokenCode')"
                            [checked]="createOptions?.canUse?.stampTokenCode"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'canUse.queueNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'canUse.queueNumber')"
                            [checked]="createOptions?.canUse?.queueNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <!-- Required Section -->
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-15 xs-mb-10">
                <xsi-text [fontWeight500]="true" color="secondary" size="default" value="{{ TR_BASE }}required.title"></xsi-text>
                <xsi-text color="secondary" size="small" styleClass="xs-mt-1" value="{{ TR_BASE }}required.subtitle"></xsi-text>
            </div>
            <ion-card class="xs-mtb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'required.customerPrimaryPhoneNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'required.customerPrimaryPhoneNumber')"
                            [checked]="createOptions?.required?.customerPrimaryPhoneNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'required.stampTokenCode' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'required.stampTokenCode')"
                            [checked]="createOptions?.required?.stampTokenCode"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'required.queueNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'required.queueNumber')"
                            [checked]="createOptions?.required?.queueNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <!-- Description Section -->
            <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-15 xs-mb-10">
                <xsi-text [fontWeight500]="true" color="secondary" size="default" value="{{ TR_BASE }}description.title"></xsi-text>
                <xsi-text color="secondary" size="small" styleClass="xs-mt-1" value="{{ TR_BASE }}description.subtitle"></xsi-text>
            </div>
            <ion-card class="xs-mtb-0-imp" mode="ios">
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'description.customerPrimaryPhoneNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'description.customerPrimaryPhoneNumber')"
                            [checked]="createOptions?.description?.customerPrimaryPhoneNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'description.stampTokenCode' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'description.stampTokenCode')"
                            [checked]="createOptions?.description?.stampTokenCode"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'description.queueNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'description.queueNumber')"
                            [checked]="createOptions?.description?.queueNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'description.certificateReferenceNumber' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'description.certificateReferenceNumber')"
                            [checked]="createOptions?.description?.certificateReferenceNumber"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label [innerHTML]="TR_BASE + 'description.certificateIssueDate' | translate"></ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'description.certificateIssueDate')"
                            [checked]="createOptions?.description?.certificateIssueDate"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>

        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>

