import {Injectable} from '@angular/core';
import {XSINetworkService} from '@xs/ionic/base';
import {XSIGeolocationService} from '@xs/ionic/core';
import {LCEIContextService} from '@lce/ionic/shared';
import {LCEMFOSettings, LCETerminalPartial} from '@lce/core';
import {LCEUserMunicipalEmployeeMainRole, LCEUserMunicipalEmployeePartial, LCEUserMunicipalEmployeePosition, LCEUserStatus, LCEUserType} from '@iro-xs/lce-core';
import {LCEIMFOAppConfig} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-app-config';
import {XSUtils} from '@xs/base';
import {XSAssert} from '@iro-xs/xs-base';

@Injectable({providedIn: 'root'})
export class LCEIMFOContextService extends LCEIContextService {

    private settings: LCEMFOSettings;

    private terminal: LCETerminalPartial;

    constructor(protected geolocationService: XSIGeolocationService, protected networkService: XSINetworkService) {
        super(geolocationService, networkService);
    }

    // ----------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------

    public getFacilityCode(): string {
        return this.getTerminal().facility.code;
    }

    public getFacilityFullName(): string {
        return this.getTerminal().facility.fullName;
    }

    public getTerminalName(): string {
        return this.getTerminal().name;
    }

    public getTerminal(): LCETerminalPartial {
        return this.terminal;
    }

    public setTerminal(terminal: LCETerminalPartial): void {
        XSAssert.notEmpty(terminal, 'terminal');
        this.terminal = terminal;
    }

    // ----------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------

    public setUser(user: LCEUserMunicipalEmployeePartial): void {
        super.setUser(user);
    }

    public getUser(): LCEUserMunicipalEmployeePartial {
        return {
            id: XSUtils.uuid(),
            code: 'LCE-USR-MUN-123456',
            type: LCEUserType.MUNICIPAL_EMPLOYEE,
            facilityCode: 'LCE-FAC-ABJ-YOP-2503',
            municipalityCode: 'LCE-MUN-ABJ-YOP',
            primaryPhoneNumber: '+2250707971234',
            status: LCEUserStatus.ONLINE,
            mainRole: LCEUserMunicipalEmployeeMainRole.ADMIN,
            position: LCEUserMunicipalEmployeePosition.DSI,
            name: {firstName: 'Vamoussa', lastName: 'Coulibaly'}
        };
    }

    public setSettings(settings: LCEMFOSettings): void {
        this.settings = settings;
    }

    public getSettings(): LCEMFOSettings {
        return this.settings;
    }

    // ----------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------

    public getAppConfig(): LCEIMFOAppConfig {
        return this.appConfig;
    }

    public initializeConfig(appConfig: LCEIMFOAppConfig): void {
        this.appConfig = appConfig;
    }
}
