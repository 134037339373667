import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';
import {XS_PAYMENT_TR_BASE_CHARGE_STATUS, XSPaymentChargePartial} from '@xs/payment/base';
import {XSIDataSectionHeader} from '@xs/ionic/core';

@Component({
    selector: 'xsi-payment-charge-detail',
    templateUrl: 'xsi-payment-charge-detail.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class XSIPaymentChargeDetailComponent implements OnInit {

    TR_BASE_CHARGE_STATUS = XS_PAYMENT_TR_BASE_CHARGE_STATUS;

    @Input() styleClass?: string;
    @Input() sectionHeaderStyleClass?: string;

    @Input() showSectionHeader?: boolean;
    @Input() sectionHeader?: XSIDataSectionHeader;

    @Input() data: XSPaymentChargePartial;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }
}


