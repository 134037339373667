<div [ngClass]="{ 'xs-justify-content-end': alignEnd }" class="xs-flex-row xs-align-items-center {{ styleClass }}">
    <ng-template *ngIf="isAvatarPosition('left')" [ngTemplateOutlet]="avatar"></ng-template>
    <div
            [ngClass]="{ 'xs-align-items-end': alignEnd }"
            [style.margin-left]="isAvatarPosition('left') ? '12px' : undefined"
            [style.margin-right]="isAvatarPosition('right') ? '12px' : undefined"
            class="xs-flex-column">
        <div
                [ngClass]="{ 'xs-justify-content-end': alignEnd }"
                class="xs-flex-row xs-align-items-baseline xs-justify-content-end">
            <xsi-text
                    *ngIf="!isCreditCardType()"
                    [fontWeight500]="true"
                    [value]="type | xsPaymentType"
                    size="intermediate">
            </xsi-text>
            <xsi-text
                    *ngIf="isCreditCardType()"
                    [fontWeight500]="true"
                    [value]="creditCardType! | xsPaymentCreditCardType: creditCardLast4Digits!"
                    size="intermediate">
            </xsi-text>
        </div>
        <xsi-text
                [value]="methodType | xsPaymentMethodType"
                color="secondary"
                size="small"
                styleClass="xs-mt-2">
        </xsi-text>
    </div>
    <ng-template *ngIf="isAvatarPosition('right')" [ngTemplateOutlet]="avatar"></ng-template>
</div>

<ng-template #avatar>
    <xs-payment-type-avatar
            *ngIf="!isCreditCardType()" [type]="type"
            styleClass="xs-width-40-imp xs-height-40-imp">
    </xs-payment-type-avatar>
    <xsi-icon
            *ngIf="isCreditCardType()"
            [value]="creditCardType! | xsPaymentCreditCardTypeIcon"
            size="48px">
    </xsi-icon>
</ng-template>