import {Injectable} from '@angular/core';
import {XSISchemeColor, XSIStorageService} from '@xs/ionic/base';
import {XSAssert, XSLanguage, XSUtils} from '@xs/base';
import {LCEI_STORAGE_KEY_LANGUAGE, LCEI_STORAGE_KEY_SCHEME} from '../constants/lcei.constant';

@Injectable()
export abstract class LCEIStorageService {

    protected constructor(protected storageService: XSIStorageService) {
    }

    public async getStoredScheme(): Promise<XSISchemeColor | undefined> {
        const schemeStr = await this.storageService.getObjectPropertyValue(this.getRootKey(), LCEI_STORAGE_KEY_SCHEME);
        return XSUtils.isEmpty(schemeStr) ? undefined : XSUtils.toEnum(schemeStr, XSISchemeColor);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Scheme ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeScheme(scheme: XSISchemeColor): void {
        XSAssert.notEmpty(scheme, 'scheme');
        this.storageService.setObjectPropertyValue(this.getRootKey(), LCEI_STORAGE_KEY_SCHEME, scheme.toString()).then();
    }

    public async getStoredLanguage(): Promise<XSLanguage | undefined> {
        const languageStr = await this.storageService.getObjectPropertyValue(this.getRootKey(), LCEI_STORAGE_KEY_LANGUAGE);
        return XSUtils.isEmpty(languageStr) ? undefined : XSUtils.toEnum(languageStr, XSLanguage);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Language ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeLanguage(language: XSLanguage): void {
        XSAssert.notEmpty(language, 'language');
        this.storageService.setObjectPropertyValue(this.getRootKey(), LCEI_STORAGE_KEY_LANGUAGE, language.toString()).then();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- *** ---
    // ---------------------------------------------------------------------------------------------------------------------------

    protected async storeRemoveData(propertyKey: string, data: any): Promise<void> {
        if (XSUtils.isEmpty(data)) {
            await this.storageService.removeObjectProperty(this.getRootKey(), propertyKey);
        } else {
            await this.storageService.setObjectPropertyValue(this.getRootKey(), propertyKey, data);
        }
    }

    protected abstract getRootKey(): string;
}
