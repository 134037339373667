<ion-header [translucent]="true" class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="onClose()"
            [cancelButton]="true"
            [disabled]="loading"
            [ellipsis]="true"
            [icon]="options.showTitleIcon ? ICON_CERTIFICATE_ORDER : NONE"
            [subtitle]="TR_BASE + 'subtitle'"
            [title]="TR_BASE + 'title'"
            cancelButtonLabel="xsi.core.label.close"
            titlePosition="top-left">
    </xsi-page-header>
    <xsi-button-text (clickEvent)="fillForm()" class="xs-absolute-center-v xs-right-100" label="Fill Form"></xsi-button-text>
</ion-header>
<ion-content #ionContent>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-certificate-order-create-page">
                <div class="xs-flex-row-center xs-width-full xs-height-30">
                    <xsi-error-inline
                            *ngIf="hasError() && !loading"
                            [error]="error"
                            [message]="errorMessage"
                            [showIcon]="true"
                            class="xs-width-fit-content"
                            id="inline-error"
                            styleClass="xs-text-align-center">
                    </xsi-error-inline>
                </div>
                <ion-grid *ngIf="!created && formGroup" class="xsi-grid-form xsi-grid-form-space-40">
                    <ion-row>
                        <ion-col size-sm="12">
                            <xsi-input-quantity
                                    [control]="formGroup.controls.numberOfCopies"
                                    [max]="MAX_NUMBER_OF_COPIES"
                                    [min]="MIN_NUMBER_OF_COPIES"
                                    label="{{TR_BASE}}numberOfCopies"
                                    labelPosition="bottom"
                                    labelStyleClass="xs-opacity-80">
                            </xsi-input-quantity>
                        </ion-col>
                    </ion-row>

                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- Customer Section -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="canShowCustomerSection()">
                        <ion-row class="xs-mt-25">
                            <ion-col size-sm="12">
                                <div class="xs-flex-column xsi-header-stripe-primary-5">
                                    <xsi-text [fontWeight500]="true" color="primary" size="medium" value="{{TR_BASE}}section.customer"></xsi-text>
                                    <xsi-text *ngIf="options?.showSectionDescription" color="secondary" size="small" value="{{TR_BASE}}section.customerDescription"></xsi-text>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row>
                            <ion-col size-sm="12">
                                <xsi-input-text
                                        [belowText]="options.description.customerPrimaryPhoneNumber ? (TR_BASE + 'description.customerPrimaryPhoneNumber') : NONE"
                                        [border]="options.inputBorder"
                                        [control]="formGroup.controls.customerPrimaryPhoneNumber"
                                        [maxLength]="25"
                                        label="{{TR_BASE}}customerPrimaryPhoneNumber"
                                        type="text">
                                </xsi-input-text>
                            </ion-col>
                        </ion-row>
                    </ng-container>

                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- Administration Section -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <ng-container *ngIf="canShowAdministrationSection()">
                        <ion-row class="xs-mt-25">
                            <ion-col size-sm="12">
                                <div class="xs-flex-column xsi-header-stripe-primary-5">
                                    <xsi-text [fontWeight500]="true" color="primary" size="medium" value="{{TR_BASE}}section.administration"></xsi-text>
                                    <xsi-text *ngIf="options?.showSectionDescription" color="secondary" size="small" value="{{TR_BASE}}section.administrationDescription"></xsi-text>
                                </div>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="options?.canUse?.stampTokenCode">
                            <ion-col size-sm="12">
                                <xsi-input-text
                                        [belowText]="options.description.stampTokenCode ? (TR_BASE + 'description.stampTokenCode') : NONE"
                                        [border]="options.inputBorder"
                                        [control]="formGroup.controls.stampTokenCode"
                                        [maxLength]="25"
                                        [upperCase]="true"
                                        label="{{TR_BASE}}stampTokenCode"
                                        nativeStyleClass="xsi-text-transform-uppercase"
                                        type="text">
                                </xsi-input-text>
                            </ion-col>
                        </ion-row>
                        <ion-row *ngIf="options?.canUse?.queueNumber">
                            <ion-col size-sm="12">
                                <xsi-input-text
                                        [belowText]="options.description.queueNumber ? (TR_BASE + 'description.queueNumber') : NONE"
                                        [border]="options.inputBorder"
                                        [control]="formGroup.controls.queueNumber"
                                        [maxLength]="25"
                                        [upperCase]="true"
                                        label="{{TR_BASE}}queueNumber"
                                        nativeStyleClass="xsi-text-transform-uppercase"
                                        type="text">
                                </xsi-input-text>
                            </ion-col>
                        </ion-row>
                    </ng-container>

                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <!-- Certificate Section -->
                    <!-- ----------------------------------------------------------------------------------------------------------------------------- -->
                    <ion-row class="xs-mt-25">
                        <ion-col size-sm="12">
                            <div class="xs-flex-column xsi-header-stripe-primary-5">
                                <xsi-text [fontWeight500]="true" color="primary" size="medium" value="{{TR_BASE}}section.certificate"></xsi-text>
                                <xsi-text *ngIf="options?.showSectionDescription" color="secondary" size="small" value="{{TR_BASE}}section.certificateDescription"></xsi-text>
                            </div>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size-sm="12">
                            <xsi-input-select
                                    [border]="options.inputBorder"
                                    [control]="formGroup.controls.certificateType"
                                    [enumOptions]="CERTIFICATE_TYPE_ENUM_OPTIONS"
                                    interfaceType="action-sheet"
                                    label="{{TR_BASE}}certificateType">
                            </xsi-input-select>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size-sm="12">
                            <xsi-input-text
                                    [belowText]="options.description.certificateReferenceNumber ? (TR_BASE + 'description.certificateReferenceNumber') : NONE"
                                    [border]="options.inputBorder"
                                    [control]="formGroup.controls.certificateReferenceNumber"
                                    [maxLength]="15"
                                    [upperCase]="true"
                                    label="{{TR_BASE}}certificateReferenceNumber"
                                    nativeStyleClass="xs-text-transform-uppercase">
                            </xsi-input-text>
                        </ion-col>
                    </ion-row>
                    <ion-row>
                        <ion-col size-sm="12">
                            <xsi-input-date
                                    [belowText]="options.description.certificateIssueDate ? (TR_BASE + 'description.certificateIssueDate') : NONE"
                                    [border]="options.inputBorder"
                                    [control]="formGroup.controls.certificateIssueDate"
                                    [useDay]="options.useCertificateIssueFullDate"
                                    [useMonth]="options.useCertificateIssueFullDate"
                                    [useYear]="true"
                                    label="{{TR_BASE + ( options.useCertificateIssueFullDate ? 'certificateIssueDate' : 'certificateIssueYear') }}">
                            </xsi-input-date>
                        </ion-col>
                    </ion-row>
                </ion-grid>
                <div *ngIf="created" class="xs-flex-column xs-width-full xs-flex-1 xs-position-relative xs-max-width-650">
                    <div class="xs-flex-column-center xs-width-full xs-absolute-center-vh">
                        <xsi-text
                                [fontWeight500]="true"
                                [uppercase]="true"
                                [value]="certificateOrder.orderNumber"
                                color="primary"
                                size="large"
                                styleClass="xs-mb-100">
                        </xsi-text>
                        <xsi-text-block
                                [actionButton]="BUTTON_COMPLETE"
                                [center]="true"
                                [icon]="ICON_OK"
                                [line1FontWeight600]="true"
                                line1="{{TR_BASE}}createdMessage.line1"
                                line2="{{TR_BASE}}createdMessage.line2"
                                line3="{{TR_BASE}}createdMessage.line3"
                                styleClass="xs-plr-15">
                        </xsi-text-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer *ngIf="!created" class="xsi-ion-footer ion-no-border xs-justify-content-center" mode="ios">
    <div class="xs-flex-row-center xs-width-full">
        <xsi-button-text
                (clickEvent)="resetForm()"
                *ngIf="!created"
                label="{{ TR_BASE }}reset"
                size="default"
                styleClass="xs-ml-15 xs-mr-50">
        </xsi-button-text>
        <ion-button
                (click)="create()"
                [disabled]="loading"
                [innerHTML]="TR_BASE + 'send' | translate"
                class="xsi-button xsi-button-full xs-font-weight-600"
                color="primary"
                mode="ios">
        </ion-button>
    </div>
</ion-footer>
