import {Injectable} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSIModalService} from '@xs/ionic/core';
import {LCECertificateOrder, LCECertificateType} from '@lce/core';
import {LCEICertificateOrderRecordComponent} from './record/lcei-certificate-order-record.component';

export interface LCEICertificateOrderModalArg {
    orderID?: string;
    orderNumber?: string;
    certificateType?: LCECertificateType;
    data?: LCECertificateOrder;

    onDismiss?: (data?: any) => void;
    onShow?: (data?: any) => void;
}

@Injectable()
export class LCEICertificateOrderModalService {

    constructor(private modalService: XSIModalService) {
    }

    public openRecord(arg: LCEICertificateOrderModalArg): string {
        XSAssert.notNull(arg, 'arg');
        LCEICertificateOrderRecordComponent.validate(arg.orderID, arg.orderNumber, arg.data, 'arg');
        const modalID = XSUtils.uuid();
        this.modalService.showModal({
            modalId: modalID,
            componentType: LCEICertificateOrderRecordComponent,
            canDismiss: true,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            componentInputs: {
                modalId: modalID,
                orderID: arg?.orderID,
                orderNumber: arg?.orderNumber,
                certificateType: arg?.certificateType,
                data: arg?.data
            },
            onDismiss: arg?.onDismiss,
            onShow: arg?.onDismiss
        }).then();
        return modalID;
    }
}
