<div class="xs-flex-column-center xs-width-full xs-mt-50 {{ styleClass }}">
    <ion-grid class="xs-width-full">
        <ion-row>
            <ion-col *ngIf="hasServiceItem(SERVICE_ID.CERTIFICATE_ORDER_CREATE)" size="8">
                <div
                        [ngClass]="{ 'xs-disabled': isServiceDisabled(SERVICE_ID.CERTIFICATE_ORDER_CREATE)  }"
                        class="lcei-mfo-service-item-card lcei-mfo-service-item-card-{{ backgroundColor }} xs-height-full">
                    <xsi-icon-renderer [data]="getServiceItemByID(SERVICE_ID.CERTIFICATE_ORDER_CREATE).icon">
                    </xsi-icon-renderer>
                    <xsi-text
                            *ngIf="showTitle"
                            [center]="true"
                            [fontWeight500]="true"
                            [value]="getServiceItemByID(SERVICE_ID.CERTIFICATE_ORDER_CREATE).title"
                            size="intermediate"
                            styleClass="lcei-mfo-service-item-card-title lcei-mfo-service-item-card-title-{{ backgroundColor }}">
                    </xsi-text>
                </div>
            </ion-col>
            <ion-col size="4">
                <div
                        *ngIf="hasServiceItem(SERVICE_ID.BIRTH_DECLARATION)"
                        [ngClass]="{ 'xs-disabled': isServiceDisabled(SERVICE_ID.BIRTH_DECLARATION) }"
                        class="lcei-mfo-service-item-card lcei-mfo-service-item-card-{{ backgroundColor }} xs-height-300">
                    <xsi-icon-renderer [data]="getServiceItemByID(SERVICE_ID.BIRTH_DECLARATION).icon">
                    </xsi-icon-renderer>
                    <xsi-text
                            *ngIf="showTitle"
                            [center]="true"
                            [fontWeight500]="true"
                            [value]="getServiceItemByID(SERVICE_ID.BIRTH_DECLARATION).title"
                            size="intermediate"
                            styleClass="lcei-mfo-service-item-card-title lcei-mfo-service-item-card-title-{{ backgroundColor }}">
                    </xsi-text>
                </div>
                <div
                        *ngIf="hasServiceItem(SERVICE_ID.WEDDING_BOOKING)"
                        [ngClass]="{
                        'xs-mt-10': hasServiceItem(SERVICE_ID.BIRTH_DECLARATION),
                        'xs-disabled': isServiceDisabled(SERVICE_ID.WEDDING_BOOKING)
                        }"
                        class="lcei-mfo-service-item-card lcei-mfo-service-item-card-{{ backgroundColor }} xs-height-300">
                    <xsi-icon-renderer [data]="getServiceItemByID(SERVICE_ID.WEDDING_BOOKING).icon">
                    </xsi-icon-renderer>
                    <xsi-text
                            *ngIf="showTitle"
                            [center]="true"
                            [fontWeight500]="true"
                            [value]="getServiceItemByID(SERVICE_ID.WEDDING_BOOKING).title"
                            size="intermediate"
                            styleClass="lcei-mfo-service-item-card-title lcei-mfo-service-item-card-title-{{ backgroundColor }}">
                    </xsi-text>
                </div>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="hasServiceItem(SERVICE_ID.INFORMATION)">
            <ion-col size="12">
                <div
                        [ngClass]="{ 'xs-disabled': isServiceDisabled(SERVICE_ID.INFORMATION) }"
                        class="lcei-mfo-service-item-card lcei-mfo-service-item-card-{{ backgroundColor }} xs-height-150">
                    <xsi-icon-renderer [data]="getServiceItemByID(SERVICE_ID.INFORMATION).icon">
                    </xsi-icon-renderer>
                    <xsi-text
                            *ngIf="showTitle"
                            [center]="true"
                            [fontWeight500]="true"
                            [value]="getServiceItemByID(SERVICE_ID.INFORMATION).title"
                            size="intermediate"
                            styleClass="lcei-mfo-service-item-card-title lcei-mfo-service-item-card-title-{{ backgroundColor }}">
                    </xsi-text>
                </div>
            </ion-col>
        </ion-row>
    </ion-grid>
</div>
