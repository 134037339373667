import {Component, OnInit} from '@angular/core';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIColor, XSIIcon} from '@xs/ionic/common';
import {LOG, XS_STR_EMPTY, XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';
import {XSCommonDomainUtils, XSLoaderService} from '@xs/common';
import {LCECertificateOrderPartial, LCECertificateType} from '@lce/core';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LCEIMFOCertificateOrderSearchHistoryItem, LCEIMFOCertificateOrderSearchTextType} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {LCEIMFOCertificateOrderModuleService} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order-module.service';
import {LCEI_SHARED_ICON} from '@lce/ionic/shared';
import {finalize} from 'rxjs/operators';
import {HttpStatusCode} from '@angular/common/http';

@Component({
    selector: 'lcei-mfo-certificate-order-search-page',
    templateUrl: 'lcei-mfo-certificate-order-search.page.html',
    styleUrls: ['lcei-mfo-certificate-order-search.page.scss'],
    host: {class: 'lcei-mfo-certificate-order-search'}
})
export class LCEIMFOCertificateOrderSearchPage implements OnInit {

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly ICON_SCAN: XSIIcon = {value: LCEI_MFO_ICON.scan, size: '150px', color: XSIColor.SECONDARY};
    readonly ICON_RECENT: string = LCEI_MFO_ICON.recent;
    readonly ICON_RECENT_OUTLINE: string = LCEI_MFO_ICON.recentOutline;

    readonly TR_BASE: string = 'lcei.mfo.features.certificateOrder.search.';

    readonly SEARCH_DEBOUNCE: number = 650;
    readonly SEARCH_MAX_LENGTH_ORDER_NUMBER: number = 14;

    searchText: string = XS_STR_EMPTY;
    searchTextMaxLength: number;
    searchTextMode: boolean = false;
    searchTextType: LCEIMFOCertificateOrderSearchTextType = 'byOrderNumber';
    searchTextResults: LCECertificateOrderPartial[];
    searchTextTotal: number;
    searchTextLimit: number;

    searchHistoryMode: boolean = false;
    searchHistoryMaxSize: number = 4;
    searchHistoryItems: LCEIMFOCertificateOrderSearchHistoryItem[] = [];

    loading: boolean = false;


    showListingSeparator: boolean = false;
    showBackgroundIcon: boolean = false;

    error: any;
    errorMessage: string;

    private readonly ERROR_MESSAGE_SEARCH_BY_ORDER_NUMBER = this.TR_BASE + 'error.searchByOrderNumber';
    private readonly ERROR_MESSAGE_SEARCH_BY_VERIFICATION_CODE = this.TR_BASE + 'error.searchByVerificationCode';

    private subscription: Subscription = new Subscription();

    constructor(
        private contextService: LCEIMFOContextService,
        private loaderService: XSLoaderService,
        private certificateOrderModuleService: LCEIMFOCertificateOrderModuleService) {
    }

    get backgroundIcon(): XSIIcon | undefined {
        if (this.searchHistoryMode) return {value: this.ICON_RECENT, size: '150px', color: XSIColor.SECONDARY};
        else if (this.searchTextMode) return {value: LCEI_SHARED_ICON.certificate, color: XSIColor.SECONDARY, styleClass: 'lcei-certificate-icon-size-150 xs-background-color-secondary'};
        return undefined;
    }

    get iconRecent(): string {
        return this.searchHistoryMode ? this.ICON_RECENT : this.ICON_RECENT_OUTLINE;
    }

    get searchTextNResults(): string {
        if (this.loading || XSUtils.isNull(this.searchTextTotal)) return '...';
        return this.searchTextTotal.toString();
    }

    ngOnInit(): void {
        this.searchTextLimit = this.certificateOrderModuleService.SEARCH_LIMIT;
        this.updateSearchTextMaxLength();
        this.certificateOrderModuleService
            .getSearchHistoryItems()
            .then(searchHistoryItems => this.searchHistoryItems = searchHistoryItems);

        //this.startLoader();
        //this.searchHistoryMode = true;

        //setTimeout(() => this.buildFakeHistoryItems(), 2000);
    }

    public onSearchTextResultClick(certificateOrder: LCECertificateOrderPartial): void {
        this.certificateOrderModuleService.openRecordByOrderID(certificateOrder.id, certificateOrder.certificate.type);
    }

    public onHistoryItemClick(historyItem: LCEIMFOCertificateOrderSearchHistoryItem): void {
        if (!XSUtils.isEmpty(historyItem.orderID) && !XSUtils.isEmpty(historyItem.orderCertificateType)) {
            this.certificateOrderModuleService.openRecordByOrderID(historyItem.orderID, historyItem.orderCertificateType);
        }
        this.searchTextType = historyItem.searchTextType;
        this.searchText = historyItem.value;
        this.search(false);
    }

    public onSearchHistoryIconClick(): void {
        this.searchHistoryMode = !this.searchHistoryMode;
        this.searchTextMode = this.searchHistoryMode ? false : !XSUtils.isEmpty(this.searchText);
    }

    public onSegmentChange(event: any) {
        this.searchTextType = event.detail.value;
        this.updateSearchTextMaxLength();
        this.searchText = XS_STR_EMPTY;
        this.searchTextResults = undefined;
        this.searchTextTotal = undefined;
        this.searchHistoryMode = false;
    }

    public onSearch(event: any): void {
        const inputValue: string = event.target.value.toLowerCase().trim();
        if (this.searchText === inputValue || inputValue.length < 4) return;
        this.searchText = inputValue;
        console.log('searchText : ', this.searchText);
        this.search();
    }

    public onSearchClear(): void {
        this.clearError();
        this.clearSearchTextMode();
    }

    public scan(): void {
    }

    public search(addToHistory: boolean = true): void {
        this.searchHistoryMode = false;
        if (XSUtils.isEmpty(this.searchText)) {
            this.clearSearchTextMode();
            return;
        }

        console.log('Searching ...');
        this.searchTextMode = true;
        this.clearError();
        this.startLoader();

        if (this.searchTextType === 'byOrderNumber') {
            this.subscription.add(
                this.certificateOrderModuleService.searchByOrderNumber(this.searchText)
                    .pipe(finalize(() => this.stopLoader()))
                    .subscribe({
                        next: response => {
                            this.searchTextTotal = response.total;
                            this.searchTextResults = response.data;
                            const orderID = this.searchTextTotal === 1 ? this.searchTextResults[0].id : undefined;
                            const certificateType = this.searchTextTotal === 1 ? this.searchTextResults[0].certificate.type : undefined;
                            if (addToHistory) this.addHistoryItem('text', this.searchText, this.searchTextTotal, orderID, certificateType);
                        },
                        error: error => {
                            this.error = error;
                            this.errorMessage = this.ERROR_MESSAGE_SEARCH_BY_ORDER_NUMBER;
                            if (addToHistory) this.addHistoryItem('text', this.searchText, 0);
                        }
                    })
            );
        } else {
            this.subscription.add(
                this.certificateOrderModuleService.findOneByVerificationCode(this.searchText)
                    .pipe(finalize(() => this.stopLoader()))
                    .subscribe({
                        next: certificateOrder => {
                            this.searchTextTotal = 1;
                            this.searchTextResults = [certificateOrder];
                            if (addToHistory) this.addHistoryItem('text', this.searchText, this.searchTextTotal, certificateOrder.id, certificateOrder.certificate.type);
                        },
                        error: error => {
                            if (error.error.status === HttpStatusCode.NotFound) {
                                this.searchTextTotal = 0;
                                this.searchTextResults = [];
                            } else {
                                this.error = error;
                                this.errorMessage = this.ERROR_MESSAGE_SEARCH_BY_VERIFICATION_CODE;
                            }
                            if (addToHistory) this.addHistoryItem('text', this.searchText, 0);
                        }
                    })
            );
        }
    }

    public hasSearchHistoryItems(): boolean {
        return !XSUtils.isEmpty(this.searchHistoryItems);
    }

    public hasSearchTextResults(): boolean {
        return !XSUtils.isEmpty(this.searchTextResults);
    }

    public clearSearchHistory(): void {
        this.searchHistoryItems = [];
        LOG().debug('---| Search history cleared.');
        this.certificateOrderModuleService.saveSearchHistoryItems(this.searchHistoryItems);
    }

    public canShowQRCodeMessage(): boolean {
        return !this.searchTextMode && !this.searchHistoryMode && !this.hasError();
    }

    public isSearchByVerificationCode(): boolean {
        return this.searchTextType === 'byVerificationCode';
    }

    public isSearchByOrderNumber(): boolean {
        return this.searchTextType === 'byOrderNumber';
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    private clearSearchTextMode(): void {
        this.searchTextMode = false;
        this.searchTextResults = undefined;
        this.searchTextTotal = undefined;
    }

    private addHistoryItem(type: 'text' | 'scan', value: string, nResults: number, orderID?: string, orderCertificateType?: LCECertificateType): void {
        const user = this.contextService.getUser();
        const searchTextType = type === 'text' ? this.searchTextType : undefined;
        const uValue = searchTextType === 'byVerificationCode' ? this.maskVerificationCode(value) : value;
        this.searchHistoryItems.unshift({
            id: XSUtils.uuid(),
            type: type,
            value: uValue,
            searchTextType: searchTextType,
            orderID: nResults === 1 ? orderID : undefined,
            orderCertificateType: nResults === 1 ? orderCertificateType : undefined,
            nResults: nResults,
            on: new Date().toISOString(),
            assistedBy: {
                id: user.id,
                code: user.code,
                fullName: XSCommonDomainUtils.getPersonFullName(user.name)
            }
        });
        if (this.searchHistoryItems.length > this.searchHistoryMaxSize) this.searchHistoryItems.pop();
        this.certificateOrderModuleService.saveSearchHistoryItems(this.searchHistoryItems);
    }

    private maskVerificationCode(code: string): string {
        return '***' + code[code.length - 1];
    }

    private updateSearchTextMaxLength(): void {
        this.searchTextMaxLength = this.searchTextType === 'byOrderNumber' ? this.SEARCH_MAX_LENGTH_ORDER_NUMBER : this.certificateOrderModuleService.VERIFICATION_CODE_LENGTH;
    }

    private clearError(): void {
        this.error = undefined;
        this.errorMessage = XS_STR_EMPTY;
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }

    private buildFakeHistoryItems(): void {
        this.searchTextType = 'byOrderNumber';
        this.addHistoryItem('text', 'LCE-BIC-123321', 1, 'xxxxxxx', LCECertificateType.BIRTH);
        this.addHistoryItem('text', '017856', 2);
        this.searchTextType = 'byVerificationCode';
        this.addHistoryItem('text', '2599', 1);
        this.searchTextType = 'byOrderNumber';
        this.addHistoryItem('scan', 'LCE-BIC-111111', 1);
    }
}
