import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {XSValidatorPhoneNumber} from '@xs/common';
import {Router} from '@angular/router';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {XS_STR_EMPTY} from '@xs/base';
import {XSIAlertService, XSIInputSelectEnumOptions, XSILoadingService} from '@xs/ionic/core';
import {LCE_TR_BASE_CERTIFICATE_TYPE, LCECertificateOrder, LCECertificateOrderCreate, LCECertificateType, LCEDeliveryDestinationFacility, LCEDeliveryDestinationType} from '@lce/core';
import {Subscription} from 'rxjs';
import {XSIButton, XSIIcon} from '@xs/ionic/common';
import {LCEIMFOCertificateOrderModuleService} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order-module.service';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LOG, XSUtils} from '@iro-xs/xs-base';
import {LCEIMFOCertificateOrderCreateOptions} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {XSFormUtils} from '@iro-xs/xs-common';
import {finalize} from 'rxjs/operators';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCertificateOrderCreateOptionsService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-certificate-order-create-options.service';
import {IonContent} from '@ionic/angular';

@Component({
    selector: 'lcei-mfo-certificate-order-create-page',
    templateUrl: 'lcei-mfo-certificate-order-create.page.html',
    styleUrls: ['lcei-mfo-certificate-order-create.page.scss']
})
export class LCEIMFOCertificateOrderCreatePage implements OnInit {

    readonly NONE: any = undefined;

    readonly TR_BASE: string = 'lcei.mfo.features.certificateOrder.create.';

    readonly ICON_CERTIFICATE_ORDER: XSIIcon = {value: LCEI_MFO_ICON.certificateOrder, box: true, boxSize: 'default', color: 'primary', styleClass: 'lcei-certificate-icon-size-35'};
    readonly ICON_OK: XSIIcon = {value: LCEI_MFO_ICON.ok, color: 'primary', size: '50px', boxSize: 'large', box: false};
    readonly BUTTON_COMPLETE: XSIButton = {
        type: 'button',
        label: this.TR_BASE + 'complete',
        styleClass: 'xs-max-width-300-imp',
        onClick: () => this.complete()
    };

    readonly MIN_NUMBER_OF_COPIES: number = 1;
    readonly MAX_NUMBER_OF_COPIES: number = 15;

    readonly CERTIFICATE_TYPE_ENUM_OPTIONS: XSIInputSelectEnumOptions = {type: LCECertificateType, baseTranslation: LCE_TR_BASE_CERTIFICATE_TYPE};

    options: LCEIMFOCertificateOrderCreateOptions = {};

    formGroup: FormGroup;

    certificateOrder: LCECertificateOrder;

    loading: boolean = false;

    error: any;
    errorMessage: string;

    @ViewChild('ionContent') ionContent: IonContent;

    private subscription: Subscription = new Subscription();
    private readonly ERROR_MESSAGE = this.TR_BASE + 'error.create';

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private elementRef: ElementRef,
        private loadingService: XSILoadingService,
        private alertService: XSIAlertService,
        private contextService: LCEIMFOContextService,
        private certificateOrderModuleService: LCEIMFOCertificateOrderModuleService,
        private certificateOrderCreateOptionsService: LCEIMFOCertificateOrderCreateOptionsService) {
    }

    get created(): boolean {
        return !XSUtils.isEmpty(this.certificateOrder);
    }

    ngOnInit(): void {
        this.options = this.certificateOrderCreateOptionsService.getCreateOptions();
        //this.options.showTitleIcon = false;
        //this.options.inputBorder = false;
        this.buildForm();
    }

    ionViewDidLeave(): void {
        this.certificateOrder = undefined;
    }

    public canShowCustomerSection(): boolean {
        return this.options.canUse.customerPrimaryPhoneNumber;
    }

    public canShowAdministrationSection(): boolean {
        return this.options.canUse.stampTokenCode || this.options.canUse.queueNumber;
    }

    public complete(): void {
        this.certificateOrder = undefined;
    }

    public create(): void {
        XSFormUtils.validate(this.formGroup);
        if (!this.formGroup.valid) {
            LOG().debug('Certificate Order Form validation failed', XSFormUtils.getFormErrors(this.formGroup));
            return;
        }
        if (this.options.confirm.beforeCreate) {
            this.alertService.showConfirmationAlert({
                header: this.TR_BASE + 'createConfirmation.title',
                message: this.TR_BASE + 'createConfirmation.message',
                onYesButtonClick: () => this.internalCreate()
            }).then();
        } else {
            this.internalCreate();
        }
    }

    public onClose(): void {
        const doClose = () => {
            this.internalResetForm();
            this.certificateOrder = undefined;
            this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
        };
        if (this.isFormEmpty() || this.options.confirm.beforeClose === false) {
            doClose();
            return;
        }
        this.alertService.showConfirmationAlert({
            header: this.TR_BASE + 'closeConfirmation.title',
            message: this.TR_BASE + 'closeConfirmation.message',
            onYesButtonClick: () => doClose()
        }).then();
    }

    public resetForm(): void {
        if (this.isFormEmpty() || this.options.confirm.beforeReset === false) {
            this.internalResetForm();
            return;
        }
        this.alertService.showConfirmationAlert({
            header: this.TR_BASE + 'resetConfirmation.title',
            message: this.TR_BASE + 'resetConfirmation.message',
            onYesButtonClick: () => this.internalResetForm()
        }).then();
    }

    public fillForm(): void {
        this.formGroup.controls.numberOfCopies.setValue(3);
        if (this.options.canUse.customerPrimaryPhoneNumber) this.formGroup.controls.customerPrimaryPhoneNumber.setValue('0707972350');
        //if (this.options.canUse.stampTokenCode) this.formGroup.controls.stampTokenCode.setValue('LCE-STN-987654');
        if (this.options.canUse.queueNumber) this.formGroup.controls.queueNumber.setValue('119');
        this.formGroup.controls.certificateType.setValue(LCECertificateType.BIRTH);
        this.formGroup.controls.certificateReferenceNumber.setValue('RTHF56129');
        this.formGroup.controls.certificateIssueDate.setValue('2000');
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    private internalResetForm(): void {
        this.formGroup.reset({numberOfCopies: this.MIN_NUMBER_OF_COPIES});
    }

    private internalCreate(): void {
        this.clearError();

        const formData = this.formGroup.getRawValue();
        const certificateOrderCreate: LCECertificateOrderCreate = this.buildCreate(formData);
        LOG().debug('Certificate Order Form successfully validated', certificateOrderCreate);

        this.startLoader();
        this.subscription.add(this.certificateOrderModuleService.create(certificateOrderCreate)
            .pipe(finalize(() => this.stopLoader()))
            .subscribe({
                next: certificateOrder => {
                    this.internalResetForm();
                    this.certificateOrder = certificateOrder;
                    this.certificateOrderModuleService.saveLastCertificateOrder(certificateOrder);
                    LOG().debug(`---| Certificate Order Successfully created [orderNumber: ${certificateOrder.orderNumber}...].`, this.certificateOrder);
                },
                error: error => {
                    this.error = error;
                    this.errorMessage = this.ERROR_MESSAGE;
                    setTimeout(() => this.ionContent.scrollToTop(1000), 500);
                }
            }));
    }

    private isFormEmpty(): boolean {
        const formData = this.formGroup.getRawValue();
        return parseInt(formData.numberOfCopies) === 1 &&
            XSUtils.isEmpty(formData.customerPrimaryPhoneNumber) &&
            XSUtils.isEmpty(formData.stampTokenCode) &&
            XSUtils.isEmpty(formData.queueNumber) &&
            XSUtils.isEmpty(formData.certificateType) &&
            XSUtils.isEmpty(formData.certificateReferenceNumber) &&
            XSUtils.isEmpty(formData.certificateIssueDate);
    }

    private buildCreate(formData: any): LCECertificateOrderCreate {
        const facilityCode = this.contextService.getFacilityCode();
        const issueDate = `${formData.certificateIssueDate}-01-01`;

        const certificateOrderCreate: LCECertificateOrderCreate = {
            facilityCode: facilityCode,
            numberOfCopies: parseInt(formData.numberOfCopies),
            certificateCreate: {
                type: formData.certificateType as LCECertificateType,
                referenceNumber: formData.certificateReferenceNumber.trim(),
                issueDate: issueDate
            },
            deliveryDestination: {
                type: LCEDeliveryDestinationType.FACILITY,
                facility: facilityCode
            } as LCEDeliveryDestinationFacility<string>,
            currentCoordinate: this.contextService.getCurrentPosition()
        };

        if (this.options?.canUse?.stampTokenCode) {
            certificateOrderCreate.stampTokenCode = formData.stampTokenCode?.trim().toUpperCase();
        }
        if (this.options?.canUse?.queueNumber) {
            certificateOrderCreate.queueNumber = formData.queueNumber?.trim().toUpperCase();
        }
        if (this.options?.canUse?.customerPrimaryPhoneNumber) {
            certificateOrderCreate.customerCreate = {primaryPhoneNumber: '+225' + formData.customerPrimaryPhoneNumber?.trim()};
        }

        XSUtils.removeNullAndUndefinedEntries(certificateOrderCreate);

        return certificateOrderCreate;
    }

    private buildForm(): void {
        const controlsConfig: { [key: string]: any } = {
            numberOfCopies: [this.MIN_NUMBER_OF_COPIES],
            certificateType: [XS_STR_EMPTY, [Validators.required]],
            certificateReferenceNumber: [XS_STR_EMPTY, [Validators.required]],
            certificateIssueDate: [XS_STR_EMPTY, [Validators.required]]
        };
        if (this.options?.canUse?.stampTokenCode) {
            const validators = [];
            if (this.options?.required?.queueNumber) {
                validators.push(Validators.required);
            }
            controlsConfig.stampTokenCode = [XS_STR_EMPTY, validators];
        }
        if (this.options?.canUse?.queueNumber) {
            const validators = [];
            if (this.options?.required?.queueNumber) {
                validators.push(Validators.required);
            }
            controlsConfig.queueNumber = [XS_STR_EMPTY, validators];
        }
        if (this.options?.canUse?.customerPrimaryPhoneNumber) {
            const validators = [XSValidatorPhoneNumber.isValid('ci', 10)];
            if (this.options?.required?.customerPrimaryPhoneNumber) {
                validators.push(Validators.required);
            }
            controlsConfig.customerPrimaryPhoneNumber = [XS_STR_EMPTY, validators];
        }
        this.formGroup = this.formBuilder.group(controlsConfig);
    }

    private clearError(): void {
        this.error = undefined;
        this.errorMessage = XS_STR_EMPTY;
    }

    private stopLoader(): void {
        setTimeout(() => this.loadingService.stopOverlayLoading(), 250);
    }

    private startLoader(): void {
        this.loadingService.startOverlayLoading({
            loadingText: this.TR_BASE + 'loadingMessage',
            onPresent: () => this.loading = true,
            onDismiss: () => this.loading = false
        });
    }
}
