import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {LOG, XS_STR_EMPTY, XSUtils} from '@iro-xs/xs-base';
import {XSIInputDigitComponent, XSIKeyboardService} from '@xs/ionic/core';
import {Subscription} from 'rxjs';
import {XSLoaderService} from '@xs/common';
import {finalize} from 'rxjs/operators';
import {HttpStatusCode} from '@angular/common/http';
import {LCEIMFOTerminalService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-terminal.service';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';

@Component({selector: 'lcei-mfo-starter-page', templateUrl: 'lcei-mfo-starter.page.html', styleUrls: ['lcei-mfo-starter.page.scss']})
export class LCEIMFOStarterPage implements OnInit, AfterViewInit {

    readonly ICON_SECURITY: string = LCEI_MFO_ICON.security;

    readonly TR_BASE: string = 'lcei.mfo.features.starter.';

    readonly LOADER_ID: string = XSUtils.uuid();

    @ViewChild('inputDigit') inputDigit: XSIInputDigitComponent;

    otpValue: string;
    otpValid: boolean;

    error: any;
    errorMessage: string;

    private subscription: Subscription = new Subscription();

    private readonly ERROR_MESSAGE_WRONG_SECURITY_CODE = this.TR_BASE + 'error.wrongSecurityCode';
    private readonly ERROR_MESSAGE_UNKNOWN = this.TR_BASE + 'error.unknownError';

    constructor(
        private router: Router,
        private loaderService: XSLoaderService,
        private keyboardService: XSIKeyboardService,
        private terminalService: LCEIMFOTerminalService) {
    }

    get loading(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.keyboardService.showKeyboard().then(() => {
            this.inputDigit.focus();
        });
    }

    public onOTPCompleted(): void {
        this.verifySecurityCode();
    }

    public onOTPChanged(value: string): void {
        this.otpValid = !XSUtils.isEmpty(value);
    }

    public onInputDigitClick(): void {
        if (this.otpValid === false && !XSUtils.isEmpty(this.otpValue) && this.otpValue.length === 4 && !this.loading) {
            this.clearOTP();
            this.clearError();
        }
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    private verifySecurityCode(): void {
        this.clearError();
        this.startLoader();
        this.subscription.add(
            this.terminalService.register({securityCode: this.otpValue})
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: response => {
                        LOG().debug(`---| Terminal ${response.terminal.name} successfully registered [tokenExpirationDate: ${response.tokenExpirationDate}].`);
                        this.otpValid = true;
                        this.otpValue = XS_STR_EMPTY;
                        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index);
                    },
                    error: error => {
                        if (error.status === HttpStatusCode.NotFound || error.error?.status === HttpStatusCode.NotFound) {
                            this.errorMessage = this.ERROR_MESSAGE_WRONG_SECURITY_CODE;
                        } else {
                            this.error = error;
                            this.errorMessage = this.ERROR_MESSAGE_UNKNOWN;
                        }
                        this.otpValid = false;
                        this.error = error;
                    }
                })
        );
        /*
        // TODO
        console.log('---| Security Code: ' + this.otpValue);
        this.startLoader();
        setTimeout(() => {
            this.storageService.storeTerminalRegistration();
            this.otpValue = XS_STR_EMPTY;
            this.stopLoader();
            this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index);
        }, 5000);
        */
    }

    private clearOTP(): void {
        this.otpValid = true;
        this.otpValue = XS_STR_EMPTY;
    }

    private clearError(): void {
        this.error = undefined;
        this.errorMessage = XS_STR_EMPTY;
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }
}
