<div class="xs-flex-column-center xs-width-full xs-mt-100 {{ styleClass }}">
    <div *ngFor="let serviceItem of data; let i = index" class="xs-flex-column-center xs-mb-100 {{ itemContainerStyleClass }} {{ serviceItem.itemContainerStyleClass }}">
        <xsi-text-block
                (line1ClickEvent)="serviceItem.onClick($event)"
                [center]="true"
                [disabled]="serviceItem.disabled || serviceItem.comingSoon"
                [icon]="serviceItem.icon"
                [line1FontWeight600]="true"
                [line1]="serviceItem.title"
                [line2FontWeight500]="false"
                [line2]="serviceItem.subtitle"
                class="{{ itemHostStyleClass }} {{ serviceItem.itemHostStyleClass }}"
                iconStyleClass="xs-mb-10-imp"
                line1Color="primary"
                line1Size="medium"
                line2Color="primary"
                line2Size="intermediate"
                line2StyleClass="xs-mt-0-imp"
                styleClass="xs-plr-20 {{ itemStyleClass }} {{ serviceItem.itemStyleClass }}">
        </xsi-text-block>
        <xsi-chip
                *ngIf="serviceItem.comingSoon"
                [data]="{label: 'lcei.mfo.features.home.comingSoon'}"
                labelStyleClass="xs-min-width-150 xs-font-weight-600"
                styleClass="xs-mt-10">
        </xsi-chip>
    </div>
</div>