import {Component, OnInit} from '@angular/core';
import {XSISelectOption} from '@xs/ionic/core';
import {Router} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {FormControl} from '@angular/forms';
import {LCEIMFOHomeServiceItem, LCEIMFOServiceArrangement, LCEIMFOServiceArrangementMode} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {LCEIMFOCommonService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-common.service';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {XSUtils} from '@iro-xs/xs-base';

@Component({
    selector: 'lcei-mfo-settings-main-screen-service-arrangement-page',
    templateUrl: 'lcei-mfo-settings-main-screen-service-arrangement.page.html',
    styleUrls: ['lcei-mfo-settings-main-screen-service-arrangement.page.scss'],
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsMainScreenServiceArrangementPage implements OnInit {

    readonly TR_BASE: string = 'lcei.mfo.features.settings.';

    readonly ICON_MAIN_SCREEN_SERVICE_ARRANGEMENT: XSIIcon = {
        value: LCEI_MFO_ICON.layout,
        size: '25px',
        box: true,
        boxSize: 'medium',
        color: 'primary'
    };

    serviceArrangement: LCEIMFOServiceArrangement;

    serviceArrangementModeControl: FormControl = new FormControl();
    serviceArrangementModeOptions: XSISelectOption[] = [
        {label: 'lcei.mfo.serviceArrangementMode.vertical', value: LCEIMFOServiceArrangementMode.VERTICAL},
        {label: 'lcei.mfo.serviceArrangementMode.horizontal', value: LCEIMFOServiceArrangementMode.HORIZONTAL},
        {label: 'lcei.mfo.serviceArrangementMode.card', value: LCEIMFOServiceArrangementMode.CARD}
    ];

    serviceArrangementCardBackgroundColorControl: FormControl = new FormControl();
    serviceArrangementCardBackgroundColorOptions: XSISelectOption[] = [
        {label: this.TR_BASE + 'serviceArrangement.card.primary', value: 'primary'},
        {label: this.TR_BASE + 'serviceArrangement.card.secondary', value: 'secondary'}
    ];

    constructor(
        private router: Router,
        private commonService: LCEIMFOCommonService) {
    }

    get serviceItems(): LCEIMFOHomeServiceItem[] {
        return this.commonService.getServiceItems();
    }

    ngOnInit(): void {
        this.serviceArrangement = this.commonService.getServiceArrangement();
        this.serviceArrangementModeControl.setValue(this.serviceArrangement.mode);
        this.serviceArrangementCardBackgroundColorControl.setValue(this.serviceArrangement.cardOptions?.backgroundColor || 'primary');
    }

    public hasServiceItems(): boolean {
        return !XSUtils.isEmpty(this.serviceItems);
    }

    public onArrangementCardBackgroundColorChange(backgroundColor: 'primary' | 'secondary'): void {
        this.commonService.saveServiceArrangement('cardOptions.backgroundColor', backgroundColor);
        this.serviceArrangement = this.commonService.getServiceArrangement();
    }

    public onArrangementCardShowTitleChange(event: any): void {
        const checked: boolean = event.detail.checked;
        this.commonService.saveServiceArrangement('cardOptions.showTitle', checked);
        this.serviceArrangement = this.commonService.getServiceArrangement();
    }

    public onArrangementModeChange(mode: LCEIMFOServiceArrangementMode): void {
        this.commonService.saveServiceArrangement('mode', mode);
        // Not really needed since it's the same memory address, but it feels good to have it here.
        this.serviceArrangement = this.commonService.getServiceArrangement();
    }

    public isArrangementModeCard(): boolean {
        return this.serviceArrangement.mode === LCEIMFOServiceArrangementMode.CARD;
    }

    public close(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
    }
}
