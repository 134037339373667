import {Directive, HostListener, Input} from '@angular/core';

@Directive({selector: '[xsInputMaxLength]'})
export class XSInputMaxlengthDirective {

    @Input('xsInputMaxLength') maxLength: number;

    constructor() {
    }

    @HostListener('keypress', ['$event'])
    onkeypress(event: any): void {
        if (event.target.value.length >= this.maxLength) {
            event.preventDefault();
        }
    }
}