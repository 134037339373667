import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LCEUserPartial, LCEUserPositionPipe} from '@lce/core';
import {XSCommonDomainUtils, XSPhoneNumberPipe, XSSize, XSSizeStr} from '@xs/common';
import {XSIColor, XSIColorStr, XSIText} from '@xs/ionic/common';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEIUserDetailModalService} from '../lcei-user-detail-modal.service';

@Component({
    selector: 'lcei-user-partial',
    template: `
        <div class="xs-flex-row xs-align-items-{{ alignItems }}">
            <lcei-user-avatar
                    *ngIf="isAvatarPosition('left')"
                    (clickEvent)="clickEvent.emit($event)"
                    class="xs-mr-12"
                    [avatarHostStyleClass]="avatarHostStyleClass"
                    [disabled]="disabled"
                    [clickable]="avatarClickable"
                    [type]="data.type"
                    [name]="data.name"
                    [profileImage]="data.profileImage"
                    [size]="avatarSize"
                    [boxSize]="avatarBoxSize"
                    [color]="avatarColor">
            </lcei-user-avatar>
            <xsi-ivar
                    [ngClass]="{ 'xs-text-align-right': isAvatarPosition('right') || alignEnd }"
                    (line1ClickEvent)="clickEvent.emit($event)"
                    [line1Clickable]="clickable"
                    [styleClass]="styleClass"
                    [ellipsis]="true"
                    [disabled]="disabled"
                    [line1]="getLine1()"
                    [line2]="getLine2()"
                    [line3]="getLine3()">
            </xsi-ivar>
            <lcei-user-avatar
                    *ngIf="isAvatarPosition('right')"
                    (clickEvent)="clickEvent.emit($event)"
                    class="xs-ml-12"
                    [avatarHostStyleClass]="avatarHostStyleClass"
                    [disabled]="disabled"
                    [clickable]="avatarClickable"
                    [type]="data.type"
                    [name]="data.name"
                    [profileImage]="data.profileImage"
                    [size]="avatarSize"
                    [boxSize]="avatarBoxSize"
                    [color]="avatarColor">
            </lcei-user-avatar>
        </div>
    `,
    providers: [XSPhoneNumberPipe]
})
export class LCEIUserPartialComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() avatarHostStyleClass?: string;

    @Input() disabled?: boolean;
    @Input() clickable?: boolean;

    @Input() data: LCEUserPartial;

    @Input() alignItems?: 'center' | 'start';
    @Input() alignEnd?: boolean;

    @Input() showCode?: boolean;

    @Input() showAvatar?: boolean;
    @Input() avatarPosition?: 'left' | 'right';
    @Input() avatarSize?: XSSize | XSSizeStr | string;
    @Input() avatarBoxSize?: XSSize | XSSizeStr | string;
    @Input() avatarColor?: XSIColor | XSIColorStr | string;
    @Input() avatarClickable?: boolean;

    @Input() openDetailOnClick?: boolean;

    @Output() clickEvent = new EventEmitter<Event>();

    constructor(
        private phoneNumberPipe: XSPhoneNumberPipe,
        private userPositionPipe: LCEUserPositionPipe,
        private userDetailModalService: LCEIUserDetailModalService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showAvatar)) this.showAvatar = false;
        if (XSUtils.isNull(this.showCode)) this.showCode = false;
        if (XSUtils.isEmpty(this.alignItems)) this.alignItems = 'center';
        if (this.showAvatar && XSUtils.isEmpty(this.avatarPosition)) this.avatarPosition = 'left';

        if (this.openDetailOnClick) {
            this.clickEvent.subscribe(() => {
                this.userDetailModalService.open(this.data);
            });
        }

        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
        if (XSUtils.isNull(this.avatarClickable)) this.avatarClickable = false;
    }

    public isAvatarPosition(position: 'left' | 'right'): boolean {
        return this.showAvatar === true && this.avatarPosition === position;
    }

    public getLine3(): XSIText | undefined {
        if (!this.showCode) return undefined;
        return {
            value: this.data.code,
            size: XSSize.EXTRA_SMALL,
            color: XSIColor.SECONDARY
        };
    }

    public getLine2(): XSIText {
        const userPosition = this.userPositionPipe.transform((this.data as any).position, this.data.type);
        return {
            value: XSUtils.isEmpty(userPosition) ? this.phoneNumberPipe.transform(this.data.primaryPhoneNumber) : userPosition,
            fontWeight500: true,
            size: XSSize.SMALL,
            color: XSIColor.SECONDARY
        };
    }

    public getLine1(): XSIText {
        return {
            value: XSUtils.isEmpty(this.data.name) ? 'lce.core.label.anonymous' : XSCommonDomainUtils.getPersonFullName(this.data.name!),
            fontWeight500: true,
            size: XSSize.INTERMEDIATE
        };
    }
}
