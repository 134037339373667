import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCertificateOrderCreatePage} from '@lce/ionic/mfo/app/features/certificate-order/create/lcei-mfo-certificate-order-create.page';
import {LCEIMFOCertificateOrderSearchPage} from '@lce/ionic/mfo/app/features/certificate-order/search/lcei-mfo-certificate-order-search.page';
import {LCEIMFOCertificateOrdersPage} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-orders.page';
import {LCEIMFOAuthenticationGuard} from '@lce/ionic/mfo/app/core/guards/lcei-mfo-authentication.guard';

const routes: Routes = [
    {path: LCEI_MFO_ROUTING.certificate.order.index, component: LCEIMFOCertificateOrdersPage, canActivate: [LCEIMFOAuthenticationGuard]},
    {path: LCEI_MFO_ROUTING.certificate.order.create, component: LCEIMFOCertificateOrderCreatePage, canActivate: [LCEIMFOAuthenticationGuard]},
    {path: LCEI_MFO_ROUTING.certificate.order.search, component: LCEIMFOCertificateOrderSearchPage, canActivate: [LCEIMFOAuthenticationGuard]}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCEIMFOCertificateRoutingModule {
}
