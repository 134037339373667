import {Component, Input, OnInit} from '@angular/core';
import {LCEIMFOHomeServiceItem, LCEIMFOServiceID} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {XSAssert, XSUtils} from '@iro-xs/xs-base';

@Component({
    selector: 'lcei-mfo-home-service-items-card',
    templateUrl: 'lcei-mfo-home-service-items-card.component.html',
    host: {class: 'xs-flex-row xs-width-full xs-max-width-750'}
})
export class LCEIMFOHomeServiceItemsCardComponent implements OnInit {

    readonly SERVICE_ID = LCEIMFOServiceID;

    @Input() styleClass?: string;
    @Input() itemContainerStyleClass?: string;
    @Input() itemHostStyleClass?: string;
    @Input() itemStyleClass?: string;

    @Input() data: LCEIMFOHomeServiceItem[];

    @Input() showTitle?: boolean;
    @Input() backgroundColor?: 'primary' | 'secondary';


    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.showTitle)) this.showTitle = false;
        if (XSUtils.isEmpty(this.backgroundColor)) this.backgroundColor = 'primary';
    }

    public isServiceDisabled(serviceItemID: LCEIMFOServiceID): boolean {
        const item = this.getServiceItemByID(serviceItemID);
        if (XSUtils.isEmpty(item)) return true;
        return item.disabled || item.comingSoon;
    }

    public hasServiceItem(serviceItemID: LCEIMFOServiceID): boolean {
        return !XSUtils.isEmpty(this.data.find(item => item.id === serviceItemID));
    }

    public getServiceItemByID(serviceItemID: LCEIMFOServiceID): LCEIMFOHomeServiceItem {
        return this.data.find(item => item.id === serviceItemID);
    }
}
