import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XS_STR_EMPTY, XSAssert, XSUtils} from '@xs/base';
import {LCE_TR_BASE_CERTIFICATE_TYPE_LETTER, LCECertificateType, LCERequestorType} from '@lce/core';
import {XSSize, XSSizeStr} from '@xs/common';
import {LCEI_SHARED_ICON} from '../../../api/constants/lcei-shared-icon.constant';

@Component({
    selector: 'lcei-certificate-avatar',
    template: `
        <xsi-icon-label
                (clickEvent)="onClick($event)"
                [disabled]="disabled"
                [fontWeight500]="true"
                [box]="true"
                [boxSize]="boxSize"
                [size]="labelSize"
                [color]="labelColorPrimary ? 'primary' : 'secondary'"
                [label]="label"
                [styleClass]="styleClass">
        </xsi-icon-label>
        <xsi-icon
                *ngIf="canShowRequestorIcon()"
                [disabled]="disabled"
                [value]="requestorData.iconValue"
                color="primary"
                size="default"
                class="xs-position-absolute xs-right-5 xs-{{ requestorIconPosition }}-5 {{ requestorData.iconHostStyleClass }} {{ requestorIconStyleClass }}">
        </xsi-icon>
    `,
    host: {class: 'xs-flex-row xs-width-fit-content xs-position-relative'}
})
export class LCEICertificateAvatarComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() requestorIconStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCECertificateType;
    @Input() requestor?: LCERequestorType;

    @Input() labelSize?: XSSize | XSSizeStr | string;
    @Input() boxSize?: XSSize | XSSizeStr | string;

    @Input() labelColorPrimary?: boolean;

    @Input() showRequestorIcon?: boolean;
    @Input() requestorIconPosition?: 'top' | 'bottom';

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<LCECertificateType>();

    get label(): string {
        return LCE_TR_BASE_CERTIFICATE_TYPE_LETTER + this.data;
    }

    get requestorData(): { iconValue: string; iconHostStyleClass?: string; } {
        return {
            iconValue: LCEI_SHARED_ICON.facility,
            iconHostStyleClass: XS_STR_EMPTY
        };
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.labelColorPrimary)) this.labelColorPrimary = true;
        if (this.canShowRequestorIcon() && !XSUtils.isEmpty(this.requestorIconPosition)) this.requestorIconPosition = 'bottom';

        if (XSUtils.isEmpty(this.labelSize)) this.labelSize = XSSize.LARGE;
        if (XSUtils.isEmpty(this.boxSize)) this.boxSize = XSSize.INTERMEDIATE;

        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }

    public onClick(event: Event): void {
        if (!this.clickable) {
            event.stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(this.data);
    }

    public canShowRequestorIcon(): boolean {
        return this.showRequestorIcon === true && !XSUtils.isEmpty(this.requestor);
    }
}
