import {Injectable} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderPartial, LCECertificateOrderService, LCECertificateType} from '@lce/core';
import {LCEIMFOHttpClientService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-http-client.service';
import {Observable, Subject} from 'rxjs';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEIMFOCertificateOrderLast, LCEIMFOCertificateOrderSearchHistoryItem, LCEIMFOCertificateOrderStorage} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {LOG, XSAssert, XSSearchResult} from '@xs/base';
import {LCEICertificateOrderModalService} from '@lce/ionic/shared';
import {LCECertificateOrderSearch} from '@iro-xs/lce-core';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';

@Injectable()
export class LCEIMFOCertificateOrderModuleService extends LCECertificateOrderService {

    readonly SEARCH_LIMIT: number = 3;
    readonly VERIFICATION_CODE_LENGTH: number = 4;

    private createdSubject = new Subject<LCEIMFOCertificateOrderLast>();
    onCreated = this.createdSubject.asObservable();

    constructor(
        protected httpClientService: LCEIMFOHttpClientService,
        private storageService: LCEIMFOStorageService,
        private contextService: LCEIMFOContextService,
        private certificateOrderModalService: LCEICertificateOrderModalService) {
        super(httpClientService);
    }

    public openRecordByData(data: LCECertificateOrder): string {
        return this.certificateOrderModalService.openRecord({data: data});
    }

    public openRecordByOrderID(orderID: string, orderCertificateType: LCECertificateType): string {
        return this.certificateOrderModalService.openRecord({orderID: orderID, certificateType: orderCertificateType});
    }

    public findOneByVerificationCode(verificationCode: string): Observable<LCECertificateOrder> {
        XSAssert.notEmpty(verificationCode, 'verificationCode');
        const vCode = verificationCode.trim();
        if (vCode.length !== this.VERIFICATION_CODE_LENGTH) {
            throw new Error(`verification code length must be ${this.VERIFICATION_CODE_LENGTH}.`);
        }
        return super.findOneByVerificationCode(vCode);
    }

    public findOneByOrderNumber(orderNumber: string): Observable<LCECertificateOrder> {
        XSAssert.notEmpty(orderNumber, 'orderNumber');
        return super.findOneByOrderNumber(orderNumber.trim());
    }

    public searchByOrderNumber(orderNumber: string): Observable<XSSearchResult<LCECertificateOrderPartial>> {
        XSAssert.notEmpty(orderNumber, 'orderNumber');
        const iSearch: LCECertificateOrderSearch = {
            orderNumber: orderNumber.trim(),
            facilityCodes: [this.contextService.getFacilityCode()],
            sort: ['createdOn|desc'],
            paginationPage: 0,
            paginationSize: this.SEARCH_LIMIT
        };
        return super.search(iSearch);
    }

    public saveSearchHistoryItems(searchHistoryItems: LCEIMFOCertificateOrderSearchHistoryItem[]): void {
        this.storageService.storeCertificateOrderSearchHistoryItems(searchHistoryItems);
        LOG().debug(`---| Search history saved (numberOfItems: ${searchHistoryItems.length}).`);
    }

    public async getSearchHistoryItems(): Promise<LCEIMFOCertificateOrderSearchHistoryItem[]> {
        const certificateOrderStorage = await this.storageService.getStoredCertificateOrder();
        return certificateOrderStorage.searchHistoryItems || [];
    }

    public saveLastCertificateOrder(certificateOrder: LCECertificateOrder): void {
        const last: LCEIMFOCertificateOrderLast = {
            createdOn: certificateOrder.createdOn,
            orderID: certificateOrder.id,
            orderNumber: certificateOrder.orderNumber,
            certificateType: certificateOrder.certificate.type
        };
        this.storageService.storeCertificateOrderLast(last);
        this.createdSubject.next(last);
    }

    public getCertificateOrderStorage(): Promise<LCEIMFOCertificateOrderStorage | undefined> {
        return this.storageService.getStoredCertificateOrder();
    }
}
