import {LCETerminalRegistrationResponse} from '@lce/core';
import {XSUtils} from '@xs/base';

export class LCEIMFOUtils {

    public static isTerminalRegistered(terminalRegistration: LCETerminalRegistrationResponse): boolean {
        return !XSUtils.isEmpty(terminalRegistration?.token) && !XSUtils.isEmpty(terminalRegistration?.tokenExpirationDate) && !XSUtils.isEmpty(terminalRegistration?.terminal);
    }

}
