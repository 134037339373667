<div class="xsi-data-record {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <xsi-data-section-header-renderer
                *ngIf="showSectionHeader"
                [data]="sectionHeader!"
                [style.width]="hasMenu() ? 'calc(100% - 20px)' : undefined"
                [styleClass]="sectionHeaderStyleClass">
        </xsi-data-section-header-renderer>
        <xsi-icon-renderer *ngIf="hasMenu()" [block]="true" [data]="ICON_MENU"></xsi-icon-renderer>
    </div>
    <div class="xsi-data-block xsi-data-block-border-light">
        <xsi-data-field-text
                [label]="{ value: 'lce.core.label.certificateType' }"
                [value]="{ value: data.type, trBase: TR_BASE_CERTIFICATE_TYPE }">
        </xsi-data-field-text>

        <xsi-data-field-text
                [label]="{ value: 'lce.core.label.referenceNumber' }"
                [value]="{ value: data.referenceNumber, uppercase: true }">
        </xsi-data-field-text>

        <xsi-data-field-calendar
                [label]="{ value: issueDateLabel }"
                [value]="{ data: data.issueDate, format: issueDateFormat, timeAgo: false, alignEnd: true }">
        </xsi-data-field-calendar>

        <xsi-data-field-ivar
                [label]="{ value: 'lce.core.label.center' }"
                [value]="{
                line1: { value: data.municipality.fullName, size: 'small', color: 'secondary' },
                line2: { value: facilityFullName, size: 'intermediate' },
                alignEnd: true
                }">
        </xsi-data-field-ivar>
    </div>
</div>