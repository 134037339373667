import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';
import {LCEICertificateOrderRecordBaseComponent} from '../lcei-certificate-order-record-base.component';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {XSPaymentChargePartial} from '@xs/payment/base';

@Component({
    selector: 'lcei-certificate-order-record-payment',
    template: `
        <xsi-payment-charge-detail
                [styleClass]="styleClass"
                [showSectionHeader]="showSectionHeader"
                [sectionHeader]="sectionHeader"
                [sectionHeaderStyleClass]="sectionHeaderStyleClass"
                [data]="data">
        </xsi-payment-charge-detail>
    `,
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateOrderRecordPaymentComponent extends LCEICertificateOrderRecordBaseComponent implements OnInit {

    readonly ICON_PAYMENT: XSIIcon = {value: LCEI_SHARED_ICON.payment};

    @Input() styleClass?: string;

    @Input() data: XSPaymentChargePartial;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildSectionHeader('payment', 'paymentDescription');
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.ICON_PAYMENT;
    }
}


