import {Directive, Input} from '@angular/core';
import {XSIIcon, XSIText} from '@xs/ionic/common';
import {XSIDataSectionHeader} from '@xs/ionic/core';
import {XSUtils} from '@xs/base';
import {LCEI_SHARED_ICON} from '../../../api/constants/lcei-shared-icon.constant';
import {XSSize} from '@xs/common';

@Directive()
export abstract class LCEICertificateOrderRecordBaseComponent {

    readonly TR_BASE: string = 'lcei.shared.certificateOrder.';

    readonly DEFAULT_HEADER_ICON: XSIIcon = {value: LCEI_SHARED_ICON.information};

    readonly ICON_MENU: XSIIcon = {
        value: LCEI_SHARED_ICON.menu,
        size: XSSize.MEDIUM,
        extendClickableArea: true,
        extendClickableAreaSize: XSSize.INTERMEDIATE,
        onClick: () => this.onMenuClick()
    };

    @Input() styleClass?: string;
    @Input() sectionHeaderStyleClass?: string;

    @Input() showSectionHeader?: boolean;
    @Input() sectionHeader?: XSIDataSectionHeader;

    section: { title: XSIText; subtitle?: XSIText; };

    public hasMenu(): boolean {
        return false;
    }

    protected buildSectionHeader(titleKey: string, subtitleKey: string): void {
        if (XSUtils.isEmpty(this.sectionHeader)) this.sectionHeader = {title: undefined!};

        if (XSUtils.isEmpty(this.sectionHeader!.icon)) this.sectionHeader!.icon = this.getSectionHeaderIcon();

        if (XSUtils.isEmpty(this.sectionHeader!.title)) {
            this.sectionHeader!.title = {
                size: 'default',
                color: 'primary',
                fontWeight600: true,
                value: this.TR_BASE + titleKey
            };
        }

        if (XSUtils.isEmpty(this.sectionHeader!.subtitle)) {
            this.sectionHeader!.subtitle = {
                size: 'small',
                color: 'secondary',
                value: this.TR_BASE + subtitleKey
            };
        }
    }

    protected onMenuClick(): void {
        console.log('---| General Menu clicked (not implemented yet !).');
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.DEFAULT_HEADER_ICON;
    }
}