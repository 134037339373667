import {Injectable} from '@angular/core';
import {LCE_TR_BASE_REQUESTOR_TYPE, LCE_TR_BASE_USER_STATUS, LCE_TR_BASE_USER_TYPE, LCEUserCustomerPartial, LCEUserPartial, LCEUserPositionPipe, LCEUserType, LCEUserUtils} from '@lce/core';
import {XSIAvatar, XSICoreUtils, XSIIvar, XSIModalService, XSIUserDetailModalOpenArg, XSIUserDetailModalService, XSIUserDetailOptions} from '@xs/ionic/core';
import {XSIColor, XSIIconLabel, XSIText} from '@xs/ionic/common';
import {XSIScreenService} from '@xs/ionic/base';
import {LCEISharedUtils} from '../../api/utils/lcei-shared-utils';
import {XSUtils} from '@xs/base';
import {XSDatePipe, XSSize, XSTranslationService} from '@xs/common';

@Injectable({providedIn: 'root'})
export class LCEIUserDetailModalService extends XSIUserDetailModalService {

    constructor(
        protected modalService: XSIModalService,
        private screenService: XSIScreenService,
        private datePipe: XSDatePipe,
        private userPositionPipe: LCEUserPositionPipe,
        private translationService: XSTranslationService) {
        super(modalService);
    }

    public open(
        user: LCEUserPartial,
        options?: XSIUserDetailOptions,
        arg?: XSIUserDetailModalOpenArg
    ): string {

        const uOptions: XSIUserDetailOptions = XSUtils.isNull(options) ? {} : options!;

        const userAvatar: XSIAvatar = LCEISharedUtils.buildUserAvatar({
            userID: user.id,
            userType: user.type,
            userName: user.name,
            userProfileImage: user.profileImage,
            color: XSIColor.PRIMARY,
            size: '65px',
            boxSize: '125px'
        });
        if (XSICoreUtils.isAvatarIconLabel(userAvatar)) (userAvatar as XSIIconLabel).size = '50px';

        const internalOptions: XSIUserDetailOptions = {
            ...uOptions,
            avatar: userAvatar,
            avatarStyleClass: 'xs-border-radius-25',
            type: this.buildUserType(user),
            position: this.userPositionPipe.transform((user as any).position, user.type),
            status: XSUtils.isEmpty(user.status) ? undefined : LCE_TR_BASE_USER_STATUS + user.status,
            statusColorStyleClass: XSUtils.isEmpty(user.status) ? undefined : 'lcei-user-status-color-' + user.status,
            lastActivityOn: user.lastActivity,
            additional: LCEUserUtils.isUserCustomer(user.type) ? this.buildCustomerAdditional(user as LCEUserCustomerPartial) : undefined
        };

        const mArg: XSIUserDetailModalOpenArg = XSUtils.isNull(arg) ? {} : {...arg};
        mArg.initialBreakpoint = this.getInitialBreakpoint(user, internalOptions);

        return super.open(user, internalOptions, mArg);
    }

    private getInitialBreakpoint(user: LCEUserPartial, internalOptions: XSIUserDetailOptions): number {
        const screenHeight = this.screenService.getScreenHeight();

        if (user.type === LCEUserType.CUSTOMER && !XSUtils.isEmpty(internalOptions.additional)) {
            let breakpoint: number = 0.3;

            if (screenHeight < 1000) breakpoint = 0.4;
            if (screenHeight < 900) breakpoint = 0.45;
            if (screenHeight < 800) breakpoint = 0.5;

            return breakpoint;
        } else {
            let breakpoint: number = 0.25;

            if (screenHeight < 1000) breakpoint = 0.3;
            if (screenHeight < 900) breakpoint = 0.35;
            if (screenHeight < 800) breakpoint = 0.4;

            return breakpoint;
        }
    }

    private buildUserType(user: LCEUserPartial): string | undefined {
        if (user.type !== LCEUserType.CUSTOMER) return undefined;
        return LCE_TR_BASE_USER_TYPE + user.type;
    }

    private buildCustomerAdditional(user: LCEUserCustomerPartial): XSIIvar | undefined {
        let createdOnVia: XSIText | undefined;
        let registeredOnVia: XSIText | undefined;

        if (!XSUtils.isEmpty(user.createdOn) && !XSUtils.isEmpty(user.createdFrom)) {
            const createdOn: string = this.datePipe.transform(user.createdOn!, 'xsMediumLongDate');
            const requestor: string = this.translationService.translateKey(LCE_TR_BASE_REQUESTOR_TYPE + user.createdFrom);
            createdOnVia = {
                value: this.translationService.translateKey('lcei.shared.user.customer.createdOnVia', {on: createdOn, from: requestor}),
                size: XSSize.INTERMEDIATE,
                color: XSIColor.SECONDARY
            };
        }

        if (!XSUtils.isEmpty(user.registeredOn) && !XSUtils.isEmpty(user.registeredFrom)) {
            const registeredOn: string = this.datePipe.transform(user.registeredOn!, 'xsMediumLongDate');
            const requestor = this.translationService.translateKey(LCE_TR_BASE_REQUESTOR_TYPE + user.registeredFrom);
            registeredOnVia = {
                value: this.translationService.translateKey('lcei.shared.user.customer.registeredOnVia', {on: registeredOn, from: requestor}),
                size: XSSize.INTERMEDIATE,
                color: XSIColor.SECONDARY,
                styleClass: 'xs-mt-5-imp'
            };
        }

        if (XSUtils.isEmpty(createdOnVia)) return undefined;
        return {line1: createdOnVia!, line2: registeredOnVia!};
    }
}
