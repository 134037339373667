import {Component, OnInit} from '@angular/core';
import {LCEI_SHARED_ICON} from '../../../api/constants/lcei-shared-icon.constant';
import {LCEIResourceCodablePartialComponent} from '../../resource/partial/lcei-resource-codable-partial.component';
import {LCEServicePointPartial} from '@lce/core';

@Component({
    selector: 'lcei-service-point-partial',
    template: `
        <xsi-ivar
                [styleClass]="styleClass"
                [avatar]="getAvatar()"
                [avatarPosition]="avatarPosition"
                [line1]="getLine1()"
                [line2]="getLine2()"
                [line3]="getLine3()"
                [disabled]="disabled"
                [ellipsis]="ellipsis">
        </xsi-ivar>
    `
})
export class LCEIServicePointPartialComponent extends LCEIResourceCodablePartialComponent<LCEServicePointPartial> implements OnInit {

    ngOnInit(): void {
        this.onInit();
    }

    protected getIconValue(): string {
        return LCEI_SHARED_ICON.servicePoint;
    }
}
