import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOSettingsPage} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings.page';
import {LCEIMFOSettingsDeveloperModePage} from '@lce/ionic/mfo/app/features/settings/developer-mode/lcei-mfo-settings-developer-mode.page';
import {LCEIMFOSettingsCertificateOrderCreatePage} from '@lce/ionic/mfo/app/features/settings/certificate-order-create/lcei-mfo-settings-certificate-order-create.page';
import {LCEIMFOSettingsAdvancedConfigurationPage} from '@lce/ionic/mfo/app/features/settings/advanced-configuration/lcei-mfo-settings-advanced-configuration.page';
import {LCEIMFOSettingsMainScreenServiceArrangementPage} from '@lce/ionic/mfo/app/features/settings/main-screen/service-arrangement/lcei-mfo-settings-main-screen-service-arrangement.page';
import {LCEIMFOSettingsMainScreenServiceActivationPage} from '@lce/ionic/mfo/app/features/settings/main-screen/service-activation/lcei-mfo-settings-main-screen-service-activation.page';

const routes: Routes = [
    {path: LCEI_MFO_ROUTING.settings.index, component: LCEIMFOSettingsPage},
    {path: LCEI_MFO_ROUTING.settings.developerMode, component: LCEIMFOSettingsDeveloperModePage},
    {path: LCEI_MFO_ROUTING.settings.mainScreen.serviceArrangement, component: LCEIMFOSettingsMainScreenServiceArrangementPage},
    {path: LCEI_MFO_ROUTING.settings.mainScreen.serviceActivation, component: LCEIMFOSettingsMainScreenServiceActivationPage},
    {path: LCEI_MFO_ROUTING.settings.certificate.order.create, component: LCEIMFOSettingsCertificateOrderCreatePage},
    {path: LCEI_MFO_ROUTING.settings.advancedConfiguration, component: LCEIMFOSettingsAdvancedConfigurationPage}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCEIMFOSettingsRoutingModule {
}
