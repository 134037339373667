import {Component, Input, OnInit} from '@angular/core';
import {XSAssert} from '@xs/base';

@Component({
    selector: 'lcei-certificate-order-number-of-copies',
    template: `
        <div class="xs-flex-column-center {{ styleClass }}">
            <xsi-icon-label
                    [box]="true"
                    [fontWeight600]="true"
                    [label]="data | xsToString"
                    boxSize="intermediate"
                    color="secondary"
                    size="medium"
                    styleClass="xs-background-color-transparent-imp xsi-border-discrete">
            </xsi-icon-label>
            <xsi-text
                    *ngIf="showLabel"
                    value="lce.core.label.numberOfCopiesShort"
                    size="extra-small"
                    color="secondary"
                    styleClass="xs-mt-2">
            </xsi-text>
        </div>
    `
})
export class LCEICertificateOrderNumberOfCopiesComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() data: number;

    @Input() showLabel?: boolean;

    ngOnInit(): void {
        XSAssert.notNull(this.data, 'data');
    }
}
