<ion-header class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="close()"
            [cancelButton]="true"
            [title]="TR_BASE + 'advancedConfiguration'"
            backButtonDefaultHref="/settings"
            cancelButtonLabel="xsi.core.label.close"
            titlePosition="bottom-center"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page xs-pt-30">

            <xsi-icon-renderer
                    [data]="ICON_ADVANCED_CONFIGURATION"
                    class="xs-flex-row-center xs-width-full xs-mb-50"
                    styleClass="xs-filter-brightness-98">
            </xsi-icon-renderer>

            <div class="xs-flex-column xs-width-full xs-plr-25">
                <xsi-button-text (clickEvent)="onReset()" label="Reset application" size="default"></xsi-button-text>
                <xsi-text
                        color="secondary"
                        size="small"
                        styleClass="xs-mt-2"
                        value="Allowing to reset the whole application. The storage will be cleared and you will be redirected to the Welcome screen.">
                </xsi-text>

                <xsi-button-text (clickEvent)="onClearCache()" label="Clear global cache" size="default" styleClass="xs-mt-40"></xsi-button-text>
                <xsi-text
                        color="secondary"
                        size="small"
                        styleClass="xs-mt-2"
                        value="Clear the global cache of the application. So all the following request will reach the server and will be cached then.">
                </xsi-text>

                <xsi-button-text (clickEvent)="onClearStorage()" label="Clear storage" size="default" styleClass="xs-mt-40"></xsi-button-text>
                <xsi-text
                        color="secondary"
                        size="small"
                        styleClass="xs-mt-2"
                        value="Clear the application storage putting all features in the initial state (default).">
                </xsi-text>
            </div>

        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>

