import {XSUtils} from '@xs/base';
import {Injectable} from '@angular/core';
import {LCEIStorageService} from './lcei-storage.service';

@Injectable()
export abstract class LCEIAuthenticationService {

    protected constructor(protected storageService: LCEIStorageService) {
    }

    public isTokenValid(token: string | undefined, tokenExpiration: string | undefined): boolean {
        if (XSUtils.isEmpty(token) || XSUtils.isEmpty(tokenExpiration)) return false;
        return new Date(tokenExpiration!) > new Date();
    }
}
