import {Component, Input, OnInit} from '@angular/core';
import {LCEIMFOHomeServiceItem, LCEIMFOServiceArrangement, LCEIMFOServiceArrangementMode} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {XSAssert} from '@iro-xs/xs-base';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';

@Component({
    selector: 'lcei-mfo-home-service-items',
    template: `
        <ng-container *ngIf="items | xsIsNotEmpty">
            <lcei-mfo-home-service-items-vertical
                    [styleClass]="styleClass"
                    [itemContainerStyleClass]="itemContainerStyleClass"
                    [itemHostStyleClass]="itemHostStyleClass"
                    [itemStyleClass]="itemStyleClass"
                    *ngIf="isArrangementModeVertical()"
                    [data]="items">
            </lcei-mfo-home-service-items-vertical>
            <lcei-mfo-home-service-items-horizontal
                    [styleClass]="styleClass"
                    [itemContainerStyleClass]="itemContainerStyleClass"
                    [itemHostStyleClass]="itemHostStyleClass"
                    [itemStyleClass]="itemStyleClass"
                    *ngIf="isArrangementModeHorizontal()"
                    [data]="items">
            </lcei-mfo-home-service-items-horizontal>
            <lcei-mfo-home-service-items-card
                    [styleClass]="styleClass"
                    [itemContainerStyleClass]="itemContainerStyleClass"
                    [itemHostStyleClass]="itemHostStyleClass"
                    [itemStyleClass]="itemStyleClass"
                    *ngIf="isArrangementModeCard()"
                    [data]="items"
                    [showTitle]="arrangement.cardOptions?.showTitle"
                    [backgroundColor]="arrangement.cardOptions?.backgroundColor">
            </lcei-mfo-home-service-items-card>
        </ng-container>
        <xsi-text-block
                *ngIf="items | xsIsEmpty"
                [center]="true"
                [icon]="ICON_SERVICE_ICON"
                [line1FontWeight500]="true"
                class="xs-absolute-center-vh xs-mt-minus-100"
                line1="lcei.mfo.features.home.noServiceActivated.line1"
                line1Color="medium"
                line1Size="medium"
                line2="lcei.mfo.features.home.noServiceActivated.line2"
                line2Color="medium"
                line2Size="intermediate"
                styleClass="xs-plr-15">
        </xsi-text-block>
    `,
    host: {class: 'xs-flex-row-center xs-width-full'}
})
export class LCEIMFOHomeServiceItemsComponent implements OnInit {

    readonly ICON_SERVICE_ICON: XSIIcon = {
        value: LCEI_MFO_ICON.service,
        size: '150px',
        color: 'discrete'
    };

    @Input() styleClass?: string;
    @Input() itemContainerStyleClass?: string;
    @Input() itemHostStyleClass?: string;
    @Input() itemStyleClass?: string;

    @Input() items: LCEIMFOHomeServiceItem[];

    @Input() arrangement: LCEIMFOServiceArrangement;

    ngOnInit(): void {
        XSAssert.notEmpty(this.arrangement, 'arrangement');
    }

    public isArrangementModeCard(): boolean {
        return this.arrangement.mode === LCEIMFOServiceArrangementMode.CARD;
    }

    public isArrangementModeHorizontal(): boolean {
        return this.arrangement.mode === LCEIMFOServiceArrangementMode.HORIZONTAL;
    }

    public isArrangementModeVertical(): boolean {
        return this.arrangement.mode === LCEIMFOServiceArrangementMode.VERTICAL;
    }
}
