import {Component, Input, OnInit} from '@angular/core';
import {LCE_TR_BASE_CERTIFICATE_ORDER_STATUS, LCE_TR_BASE_REQUESTOR_TYPE, LCECertificateOrder, LCECertificateOrderStatus} from '@lce/core';
import {XSAssert, XSAuditOnBy, XSUtils} from '@xs/base';
import {XSIIcon} from '@xs/ionic/common';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {LCEICertificateOrderRecordBaseComponent} from '../lcei-certificate-order-record-base.component';

@Component({
    selector: 'lcei-certificate-order-record-general',
    templateUrl: 'lcei-certificate-order-record-general.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateOrderRecordGeneralComponent extends LCEICertificateOrderRecordBaseComponent implements OnInit {

    readonly ICON_PHONE_HUMBER: string = LCEI_SHARED_ICON.phone;

    readonly TR_BASE_REQUESTOR_TYPE: string = LCE_TR_BASE_REQUESTOR_TYPE;
    readonly TR_BASE_STATUS: string = LCE_TR_BASE_CERTIFICATE_ORDER_STATUS;

    readonly ICON_ORDER: XSIIcon = {value: LCEI_SHARED_ICON.order};

    @Input() data: LCECertificateOrder;

    get statusAudit(): XSAuditOnBy {
        return this.data.statusAudit[this.data.status]!;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildSectionHeader('general', 'generalDescription');
    }

    public hasCustomer(): boolean {
        return !XSUtils.isEmpty(this.data.customer);
    }

    public getStatusUpdatedOnLabel(): string {
        if (this.isCancelled()) {
            return this.TR_BASE + 'cancelledOn';
        }
        if (this.data.status === LCECertificateOrderStatus.ERROR) {
            return this.TR_BASE + 'setAsErrorOn';
        }
        return this.TR_BASE + 'statusUpdatedOn';
    }

    public isCancelled(): boolean {
        return this.data.status === LCECertificateOrderStatus.CANCELLED;
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.ICON_ORDER;
    }
}


