<div class="xs-flex-column-center xs-width-full xs-mt-100 {{ styleClass }}">
    <div
            *ngFor="let serviceItem of data; let i = index"
            class="xs-flex-row xs-width-full xs-position-relative xs-mb-100 {{ itemContainerStyleClass }} {{ serviceItem.itemContainerStyleClass }}">
        <ion-item
                (click)="onClick(serviceItem, $event)"
                [ngClass]="{ 'xsi-disabled': isDisabled(serviceItem), 'xs-cursor-pointer xsi-ion-item-detail-icon-opacity-full': !isDisabled(serviceItem) }"
                class="xsi-ion-item-clickable xs-width-full xs-border-radius-default xsi-ion-item-box-shadow xsi-ion-item-detail-icon-color-primary {{ itemHostStyleClass }} {{ serviceItem.itemHostStyleClass }}"
                detail="true">
            <ion-label class="ion-text-wrap">
                <xsi-ivar
                        [avatar]="serviceItem.icon"
                        [disabled]="isDisabled(serviceItem)"
                        [line1]="{ value: serviceItem.title, color: 'primary', size: 'medium', fontWeight600: true }"
                        [line2]="{ value: serviceItem.subtitle, color: 'primary', size: 'intermediate' }"
                        styleClass="xs-line-height-1-20 {{ itemStyleClass }} {{ serviceItem.itemStyleClass }}">
                </xsi-ivar>
            </ion-label>
        </ion-item>
        <xsi-chip
                *ngIf="serviceItem.comingSoon"
                [data]="{label: 'lcei.mfo.features.home.comingSoon', selectable: false}"
                class="xs-position-absolute xs-bottom-minus-35 xs-right-5"
                labelStyleClass="xs-min-width-150 xs-font-weight-600">
        </xsi-chip>
    </div>
</div>