export const LCEI_MFO_STORAGE_KEY: string = 'lcei.mfo';

export const LCEI_MFO_STORAGE_KEY_TERMINAL_REGISTRATION: string = 'terminalRegistration';

export const LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER: string = 'certificateOrder';

export const LCEI_MFO_STORAGE_KEY_SERVICE_CONFIGURATION: string = 'serviceConfiguration';
export const LCEI_MFO_STORAGE_KEY_SERVICE_ACTIVATION: string = 'serviceConfiguration.activation';
export const LCEI_MFO_STORAGE_KEY_SERVICE_ARRANGEMENT: string = 'serviceConfiguration.arrangement';

export const LCEI_MFO_STORAGE_KEY_HOME: string = 'home';

export const LCEI_MFO_ROUTING = {
    home: {
        index: 'home'
    },
    certificate: {
        order: {
            index: 'certificate/order',
            create: 'certificate/order/create',
            search: 'certificate/order/search'
        }
    },
    settings: {
        index: 'settings',
        advancedConfiguration: 'settings/advanced-configuration',
        developerMode: 'settings/developer-mode',
        mainScreen: {
            serviceArrangement: 'settings/main-screen/service-arrangement',
            serviceActivation: 'settings/main-screen/service-activation'
        },
        certificate: {
            order: {
                create: 'settings/certificate/order/create'
            }
        }
    },
    starter: {
        index: 'starter'
    }
};
