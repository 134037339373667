import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_CERTIFICATE_ORDER_STATUS_COLOR_MAP, LCECertificateOrderStatus} from '@lce/core';

@Component({
    selector: 'lcei-certificate-order-status',
    template: `
        <div
                (click)="onClick($event)"
                class="xs-flex-row-center xs-width-fit-content xs-min-width-125 {{ styleClass }}"
                [ngClass]="{ 'xsi-chip-background-small-secondary-tint': chip, 'xs-disabled': disabled }">
            <span *ngIf="state" class="lcei-certificate-order-status-state {{ statusStateStyleClassColor }}"></span>
            <xsi-text
                    [value]="data | lceCertificateOrderStatus"
                    size="intermediate"
                    [clickable]="clickable"
                    (clickEvent)="onClick($event)"
                    [fontWeight500]="true"
                    [applyClickableStyle]="false"
                    styleClass="xsi-color-secondary-contrast">
            </xsi-text>
        </div>
    `
})
export class LCEICertificateOrderStatusComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCECertificateOrderStatus;

    @Input() chip?: boolean;

    @Input() state?: boolean;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<LCECertificateOrderStatus>();

    get statusStateStyleClassColor(): string {
        return LCE_CERTIFICATE_ORDER_STATUS_COLOR_MAP.get(this.data)?.backgroundColor || 'xsi-background-discrete';
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.state)) this.state = false;
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }

    public onClick(event: Event): void {
        if (!this.clickable) {
            event.stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(this.data);
    }
}
