import {Component, OnDestroy, OnInit} from '@angular/core';
import {XSISchemeColor} from '@xs/ionic/base';
import {XSTranslationService} from '@xs/common';
import {XSISchemeService} from '@xs/ionic/core';
import {XSLanguage} from '@xs/base';
import {Platform} from '@ionic/angular';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {LCEIMFOAppInitializerService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-app-initializer.service';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {LCEIMFOCertificateOrderModuleService} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order-module.service';
import {LCE_TR_BASE_CERTIFICATE_TYPE, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {XS_STR_EMPTY, XS_STR_SPACE, XSUtils} from '@iro-xs/xs-base';
import {XSIIcon, XSIText} from '@xs/ionic/common';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCertificateOrderLast} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {LCEICertificateOrderModalService} from '@lce/ionic/shared';

@Component({
    selector: 'lcei-mfo-settings-page',
    templateUrl: 'lcei-mfo-settings.page.html',
    styleUrls: ['lcei-mfo-settings.page.scss'],
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsPage implements OnInit, OnDestroy {

    readonly ROUTING = LCEI_MFO_ROUTING.settings;

    readonly ICON_USER: string = LCEI_MFO_ICON.user.municipalEmployee;
    readonly ICON_LANGUAGE: string = LCEI_MFO_ICON.language;
    readonly ICON_DARK_MODE: string = LCEI_MFO_ICON.darkMode;
    readonly ICON_DEVELOPER_MODE: string = LCEI_MFO_ICON.developerMode;

    readonly ICON_MAIN_SCREEN_SERVICE_ARRANGEMENT: string = LCEI_MFO_ICON.layout;
    readonly ICON_MAIN_SCREEN_SERVICE_ACTIVATION: string = LCEI_MFO_ICON.service;

    readonly ICON_CERTIFICATE_ORDER: string = LCEI_MFO_ICON.certificateOrder;

    readonly ICON_ADVANCED_CONFIGURATION: string = LCEI_MFO_ICON.settings;

    readonly ICON_CERTIFICATE_ORDER_LAST: XSIIcon = {
        value: LCEI_MFO_ICON.recent,
        box: true,
        size: '16px',
        boxSize: 'small',
        color: 'primary'
    };

    readonly TR_BASE: string = 'lcei.mfo.features.settings.';

    terminalName: string;

    developerMode: boolean = true;

    languageState: boolean;
    darkModeState: boolean;

    user: LCEUserMunicipalEmployeePartial;

    lastCertificateOrder: LCEIMFOCertificateOrderLast;

    showUserCode: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        protected router: Router,
        protected platform: Platform,
        private schemeService: XSISchemeService,
        private storageService: LCEIMFOStorageService,
        private translationService: XSTranslationService,
        private contextService: LCEIMFOContextService,
        private certificateOrderModalService: LCEICertificateOrderModalService,
        private appInitializerService: LCEIMFOAppInitializerService,
        // ---
        private certificateOrderModuleService: LCEIMFOCertificateOrderModuleService) {
    }

    ngOnInit(): void {
        this.user = this.contextService.getUser();
        this.terminalName = this.contextService.getTerminalName();

        this.languageState = this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? true : false;
        this.darkModeState = this.schemeService.getCurrentSchemeColor() === XSISchemeColor.DARK ? true : false;

        this.updateCertificateOrderData();

        // --- Listening ---
        this.certificateOrderModuleService.onCreated.subscribe(() => setTimeout(() => this.updateCertificateOrderData(), 100));
        this.appInitializerService.onPreloadCompleted.subscribe(() => setTimeout(() => this.updateCertificateOrderData(), 100));

        this.subscription.add(this.translationService.onLanguageChanged.subscribe(newLanguage => {
            this.languageState = newLanguage === XSLanguage.ENGLISH ? true : false;
            this.saveLanguage();
        }));
        this.subscription.add(this.schemeService.onColorChanged.subscribe(event => {
            this.darkModeState = event.schemeColor === XSISchemeColor.DARK ? true : false;
            this.saveScheme();
        }));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public getLastCertificateOrderLine2(): XSIText {
        if (!this.hasLastCertificateOrder()) {
            return {value: this.TR_BASE + 'lastCertificateOrderNotFound', size: 'small', color: 'secondary'};
        }
        const trCertificateType: string = this.translationService.translateKey(LCE_TR_BASE_CERTIFICATE_TYPE + this.lastCertificateOrder.certificateType);
        return {
            value: trCertificateType + XS_STR_SPACE + `${this.lastCertificateOrder.orderNumber.toUpperCase()}`,
            size: 'intermediate',
            color: 'primary'
        };
    }

    public getLastCertificateOrderLine1(): XSIText {
        return {value: this.TR_BASE + 'lastCertificateOrder', size: 'small'};
    }

    public hasLastCertificateOrder(): boolean {
        return !XSUtils.isEmpty(this.lastCertificateOrder);
    }

    public openLastCertificateOrderRecordPage(event: Event): void {
        if (XSUtils.isEmpty(this.lastCertificateOrder)) {
            event.stopImmediatePropagation();
            return;
        }
        this.certificateOrderModalService.openRecord({
            orderID: this.lastCertificateOrder.orderID,
            orderNumber: this.lastCertificateOrder.orderNumber,
            certificateType: this.lastCertificateOrder.certificateType
        });
    }

    public logout(): void {
        console.log('Logout ...');
    }

    public onLanguageChange(event: any) {
        const checked: boolean = event.detail.checked;
        this.translationService.changeCurrentLanguage(checked ? XSLanguage.ENGLISH : XSLanguage.FRENCH).subscribe();
    }

    public onSchemeChange(event: any) {
        const checked: boolean = event.detail.checked;
        this.schemeService.changeSchemeColor(checked ? XSISchemeColor.DARK : XSISchemeColor.LIGHT);
    }

    public route(url: string): string {
        return url.replace(`${LCEI_MFO_ROUTING.settings.index}/`, XS_STR_EMPTY);
    }

    private updateCertificateOrderData(): void {
        this.certificateOrderModuleService.getCertificateOrderStorage().then(certificateOrderStorage => {
            this.lastCertificateOrder = certificateOrderStorage?.last;
        });
    }

    private saveScheme(): void {
        this.storageService.storeScheme(this.schemeService.getCurrentSchemeColor());
    }

    private saveLanguage(): void {
        this.storageService.storeLanguage(this.translationService.getCurrentLanguage());
    }
}
