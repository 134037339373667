import {Component, Input, OnInit} from '@angular/core';
import {LCEDelivery} from '@lce/core';
import {XSAssert, XSLanguage} from '@xs/base';
import {LCEICertificateOrderRecordBaseComponent} from '../lcei-certificate-order-record-base.component';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {XSTranslationService} from '@xs/common';

@Component({
    selector: 'lcei-certificate-order-record-delivery',
    templateUrl: 'lcei-certificate-order-record-delivery.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateOrderRecordDeliveryComponent extends LCEICertificateOrderRecordBaseComponent implements OnInit {

    readonly ICON_DELIVERY: XSIIcon = {value: LCEI_SHARED_ICON.delivery};

    @Input() styleClass?: string;

    @Input() data: LCEDelivery;

    constructor(private translationService: XSTranslationService) {
        super();
    }

    get dateFormat(): string {
        const language: XSLanguage = this.translationService.getCurrentLanguage();
        return language === XSLanguage.FRENCH ? 'XS_DATE_FORMAT_DD_MM_YYYY' : 'XS_DATE_FORMAT_YYYY_MM_DD';
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildSectionHeader('delivery', 'deliveryDescription');
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.ICON_DELIVERY;
    }
}


