import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {XSTranslationService} from '@xs/common';
import {LCEIMFOEnvironmentService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-environment.service';
import {LCEIHttpClientService} from '@lce/ionic/shared';

@Injectable({providedIn: 'root'})
export class LCEIMFOHttpClientService extends LCEIHttpClientService {

    constructor(
        protected httpClient: HttpClient,
        protected environmentService: LCEIMFOEnvironmentService,
        protected translationService: XSTranslationService) {
        super(httpClient, environmentService, translationService);
    }
}
