import {Component, Input, OnInit} from '@angular/core';
import {LCECertificateOrder, LCECertificateOrderService, LCECertificateType, LCEIdentityDocumentType, LCERequestorType} from '@lce/core';
import {LOG, XS_LOREM_IPSUM, XS_STR_EMPTY, XSCurrency, XSUtils} from '@xs/base';
import {Subscription} from 'rxjs';
import {XSLoaderService} from '@xs/common';
import {XSIModalService} from '@xs/ionic/core';
import {finalize} from 'rxjs/operators';
import {LCEICertificateOrderRecordOptions} from './lcei-certificate-order-record';
import {XSPaymentChargePartial, XSPaymentChargeStatus, XSPaymentMethodType, XSPaymentType} from '@xs/payment/base';

@Component({
    selector: 'lcei-certificate-order-record',
    templateUrl: 'lcei-certificate-order-record.component.html'
})
export class LCEICertificateOrderRecordComponent implements OnInit {

    readonly LOADER_ID: string = XSUtils.uuid();

    readonly TR_BASE: string = 'lcei.shared.certificateOrder.';

    @Input() headerStyleClass?: string;
    @Input() contentStyleClass?: string;
    @Input() footerStyleClass?: string;

    @Input() modalId?: string;

    @Input() orderID?: string;
    @Input() orderNumber?: string;
    @Input() certificateType?: LCECertificateType;

    @Input() data: LCECertificateOrder;

    @Input() options?: LCEICertificateOrderRecordOptions;

    error: any;
    errorMessage: string;

    private subscription: Subscription = new Subscription();

    private readonly ERROR_MESSAGE = this.TR_BASE + 'error.retrieve';

    constructor(
        private loaderService: XSLoaderService,
        private modalService: XSIModalService,
        private certificateOrderService: LCECertificateOrderService) {
    }

    get loading(): boolean {
        return this.loaderService.isLoaderRunning(this.LOADER_ID);
    }

    public static validate(orderID: string | undefined, orderNumber: string | undefined, data: LCECertificateOrder | undefined, prefix: string = XS_STR_EMPTY): void {
        const str = XSUtils.isEmpty(prefix) ? XS_STR_EMPTY : prefix.trim() + '.';
        if (XSUtils.isEmpty(orderID) && XSUtils.isEmpty(orderNumber) && XSUtils.isEmpty(data)) {
            throw new Error(`${str}orderID, ${str}orderNumber and ${str}data cannot all be empty at the same time.`);
        }
    }

    ngOnInit(): void {
        LCEICertificateOrderRecordComponent.validate(this.orderID, this.orderNumber, this.data);
        this.handleDefaults();
        if (!XSUtils.isEmpty(this.data)) {
            this.orderID = this.data.id;
            this.orderNumber = this.data.orderNumber;
            this.certificateType = this.data.certificate.type;
        } else {
            this.retrieve();
        }
    }

    public hasAdministration(): boolean {
        return !XSUtils.isEmpty(this.data.stampTokenCode) || !XSUtils.isEmpty(this.data.queueNumber);
    }

    public hasProcuration(): boolean {
        return !XSUtils.isEmpty(this.data.procuration);
    }

    public hasDelivery(): boolean {
        return !XSUtils.isEmpty(this.data.delivery) && this.data.source !== LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP;
    }

    public hasPayment(): boolean {
        return !XSUtils.isEmpty(this.data.charge);
    }

    public retrieve(): void {
        this.clearError();
        this.startLoader();

        const findOne = XSUtils.isEmpty(this.orderID) ?
            this.certificateOrderService.findOneByOrderNumber(this.orderNumber!) :
            this.certificateOrderService.findOneByID(this.orderID!);

        this.subscription.add(
            findOne
                .pipe(finalize(() => this.stopLoader()))
                .subscribe({
                    next: certificateOrder => {
                        this.data = certificateOrder;
                        //this.hack();
                        LOG().debug(`---| Certificate Order successfully retrieved [orderNumber: ${this.data.orderNumber}].`);
                    },
                    error: error => {
                        this.error = error;
                        this.errorMessage = this.ERROR_MESSAGE;
                    }
                }));
    }

    public hasError(): boolean {
        return !XSUtils.isEmpty(this.error);
    }

    public dismissModal(): void {
        this.modalService.dismissModal(this.modalId);
    }

    private hack(): void {
        if (this.data.customer) {
            this.data.customer!.createdOn = new Date().toISOString();
            this.data.customer!.createdFrom = LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP;
            this.data.customer!.registeredOn = new Date().toISOString();
            this.data.customer!.registeredFrom = LCERequestorType.PUBLIC_MOBILE_APP;
            // this.data.customer!.registered = true;
            this.data.customer!.email = 'laverne.clark@rightwaytodoit.com';
        }

        this.data.procuration = {
            representativeFullName: 'Ryane Alla',
            representativePhoneNumber: '+2250707972350',
            representativeEmail: 'ryane.alla@iro-xs.com',
            identityDocumentType: LCEIdentityDocumentType.NATIONAL_IDENTITY_CARD,
            identityDocumentNumber: 'THDGFJH-2332-12'
        };

        this.data.delivery.deliveredOn = this.data.createdOn;
        this.data.delivery.deliveredBy = this.data.createdBy;
        this.data.delivery.note = XS_LOREM_IPSUM.long;
        this.data.delivery.estimatedDeliveryDate = '2024-07-18';
        this.data.delivery.estimatedDeliveryTimeRange = {start: '12:00', end: '15:35'};
        this.data.delivery.desiredDeliveryDate = '2024-07-18';
        this.data.delivery.desiredDeliveryTimeRange = {start: '12:00', end: '15:35'};

        this.data.charge = {
            id: XSUtils.uuid(),
            createdBy: this.data.createdBy,
            createdOn: this.data.createdOn,
            type: XSPaymentType.MTN_MONEY,
            methodType: XSPaymentMethodType.MOBILE_MONEY,
            nativeID: 'cos-' + XSUtils.uuid(),
            nativeTransactionID: 'cos-' + XSUtils.uuid(),
            transactionID: 'XS-PAY-CHA-WAV-987654',
            transactionDate: new Date().toISOString(),
            chargedAmount: {value: 5000, currency: XSCurrency.XOF},
            perceivedAmount: {value: 5000, currency: XSCurrency.XOF},
            status: XSPaymentChargeStatus.SUCCESS,
            statusAudit: undefined!,
            test: true
        } as XSPaymentChargePartial;
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.options)) this.options = {};
        if (XSUtils.isNull(this.options!.showHeader)) {
            this.options!.showHeader = true;
            if (XSUtils.isNull(this.options!.closeButton)) this.options!.closeButton = true;
        }
        if (XSUtils.isNull(this.options!.showCopyright)) this.options!.showCopyright = true;
        if (this.options!.showHeader && this.options!.closeButton && XSUtils.isEmpty(this.options!.closeButtonLabel)) this.options!.closeButtonLabel = 'xsi.core.label.close';
    }

    private clearError(): void {
        this.error = undefined;
        this.errorMessage = XS_STR_EMPTY;
    }

    private stopLoader(): void {
        this.loaderService.stopLoader(this.LOADER_ID);
    }

    private startLoader(): void {
        this.loaderService.startLoader(this.LOADER_ID);
    }
}


