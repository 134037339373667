import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEISharedModule} from '@lce/ionic/shared';
import {LCEIMFOStarterModule} from '@lce/ionic/mfo/app/features/starter/lcei-mfo-starter.module';
import {LCEIMFOHomeModule} from '@lce/ionic/mfo/app/features/home/lcei-mfo-home.module';
import {LCEIMFOCertificateOrderModule} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order.module';
import {LCEIMFOSettingsModule} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings.module';
import {LCEIMFOSettingsPage} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings.page';
import {LCEIMFOHomePage} from '@lce/ionic/mfo/app/features/home/lcei-mfo-home.page';
import {LCEIMFOStarterPage} from '@lce/ionic/mfo/app/features/starter/lcei-mfo-starter.page';
import {LCEIMFOCertificateOrderCreatePage} from '@lce/ionic/mfo/app/features/certificate-order/create/lcei-mfo-certificate-order-create.page';
import {XS_STR_EMPTY} from '@iro-xs/xs-base';
import {LCEIMFOAuthenticationGuard} from '@lce/ionic/mfo/app/core/guards/lcei-mfo-authentication.guard';
import {LCEIMFOTerminalRegistrationGuard} from '@lce/ionic/mfo/app/core/guards/lcei-mfo-terminal-registration.guard';

const routes: Routes = [
    {
        path: LCEI_MFO_ROUTING.starter.index, component: LCEIMFOStarterPage, canActivate: [LCEIMFOTerminalRegistrationGuard, LCEIMFOAuthenticationGuard]
    },
    {
        path: LCEI_MFO_ROUTING.home.index, component: LCEIMFOHomePage, canActivate: [LCEIMFOTerminalRegistrationGuard, LCEIMFOAuthenticationGuard]
    },
    {
        path: LCEI_MFO_ROUTING.certificate.order.index, component: LCEIMFOCertificateOrderCreatePage, canActivate: [LCEIMFOTerminalRegistrationGuard, LCEIMFOAuthenticationGuard]
    },
    {
        path: LCEI_MFO_ROUTING.settings.index, component: LCEIMFOSettingsPage, canActivate: [LCEIMFOTerminalRegistrationGuard, LCEIMFOAuthenticationGuard]
    },
    {
        path: XS_STR_EMPTY, redirectTo: LCEI_MFO_ROUTING.home.index
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
        LCEISharedModule,
        LCEIMFOStarterModule,
        LCEIMFOHomeModule,
        LCEIMFOCertificateOrderModule,
        LCEIMFOSettingsModule
    ],
    exports: [RouterModule]
})
export class LCEIMFORoutingModule {
}
