<ion-header *ngIf="options!.showHeader" [translucent]="options!.headerTranslucent" class="xsi-ion-header {{ headerStyleClass }} {{ options!.headerStyleClass}}">
    <xsi-page-header
            (cancelButtonClickEvent)="dismissModal()"
            [backButtonDefaultHref]="options!.backButtonDefaultHref"
            [backButton]="options!.backButton"
            [cancelButtonLabel]="options!.closeButtonLabel"
            [cancelButton]="options!.closeButton"
            [subtitle]="orderNumber"
            subtitleSize="small"
            title="lce.core.certificateTypeMedium.{{ certificateType }}"
            titlePosition="top-left"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center {{ contentStyleClass}} {{ options!.contentStyleClass }}">
        <div class="lcei-certificate-order-record">
            <div class="lcei-certificate-order-record-inner">
                <!-- === Loader === -->
                <xs-loader [loaderId]="LOADER_ID" [loaderSize]="20" class="xs-absolute-center-vh"></xs-loader>
                <!-- === Error === -->
                <xsi-error-block
                        (buttonClickEvent)="retrieve()"
                        *ngIf="hasError() && !loading"
                        [absolute]="true"
                        [error]="error"
                        [line1]="errorMessage"
                        [showButton]="true">
                </xsi-error-block>

                <ng-container *ngIf="data">
                    <xsi-qrcode [data]="data.qrCode" styleClass="xs-max-width-200"></xsi-qrcode>

                    <lcei-certificate-order-record-general
                            [data]="data"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-order-record-general>

                    <lcei-certificate-order-record-administration
                            *ngIf="hasAdministration()"
                            [data]="data"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-order-record-administration>

                    <lcei-certificate-record
                            [data]="data.certificate"
                            [facilityFullName]="data.facility.fullName"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-record>

                    <lcei-certificate-order-record-procuration
                            *ngIf="hasProcuration()"
                            [data]="data.procuration!"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-order-record-procuration>

                    <lcei-certificate-order-record-delivery
                            *ngIf="hasDelivery()"
                            [data]="data.delivery"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-order-record-delivery>

                    <lcei-certificate-order-record-payment
                            *ngIf="hasPayment()"
                            [data]="data.charge!"
                            [sectionHeaderStyleClass]="options!.sectionHeaderStyleClass"
                            [sectionHeader]=" options!.sectionHeader"
                            [showSectionHeader]=" true"
                            styleClass="xs-mt-50">
                    </lcei-certificate-order-record-payment>
                </ng-container>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer *ngIf="options!.showCopyright" class="xsi-ion-footer xsi-ion-footer-copyright {{ footerStyleClass }} {{ options!.footerStyleClass }}" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>
