<ion-header [translucent]="true" class="xsi-ion-header">
    <!--    <xsi-page-header-->
    <!--            [title]="TR_BASE + 'title'"-->
    <!--            backButtonDefaultHref="/home"-->
    <!--            titlePosition="top-center"-->
    <!--            titleSize="medium">-->
    <!--    </xsi-page-header>-->
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <xsi-button-back
                [extendClickableArea]="true"
                class="xs-position-relative"
                defaultHref="/home"
                extendClickableAreaSize="default">
        </xsi-button-back>
        <ion-segment
                (ionChange)="onSegmentChange($event)"
                [value]="searchTextType"
                class="xs-max-width-300 xs-mlr-15"
                mode="ios">
            <ion-segment-button value="byOrderNumber">
                <ion-label [innerHTML]="TR_BASE + 'textType.byOrderNumber' | translate"></ion-label>
            </ion-segment-button>
            <ion-segment-button value="byVerificationCode">
                <ion-label [innerHTML]="TR_BASE + 'textType.byVerificationCode' | translate"></ion-label>
            </ion-segment-button>
        </ion-segment>
        <div class="xs-flex-row xs-align-items-center xs-mr-7">
            <xsi-icon
                    (clickEvent)="onSearchHistoryIconClick()"
                    [extendClickableArea]="true"
                    [value]="iconRecent"
                    color="primary"
                    extendClickableAreaSize="small"
                    size="medium">
            </xsi-icon>
        </div>
    </div>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-certificate-order-search-page">

                <div class="xs-flex-column xs-width-full">
                    <xsi-text
                            [fontWeight500]="true"
                            color="primary"
                            size="large"
                            styleClass="xs-ml-10"
                            value="{{ TR_BASE }}title">
                    </xsi-text>
                    <ion-searchbar
                            (ionChange)="onSearch($event)"
                            (ionClear)="onSearchClear()"
                            [debounce]="SEARCH_DEBOUNCE"
                            [value]="searchText"
                            [xsInputMaxLength]="searchTextMaxLength"
                            class="xsi-ion-searchbar xs-p-0-imp"
                            mode="ios"
                            placeholder=""
                            type="text">
                    </ion-searchbar>
                </div>
                <!-- === Loader === -->
                <div class="xs-flex-row-center xs-position-relative xs-mtb-15">
                    <xs-loader [loaderId]="LOADER_ID" [loaderSize]="20"></xs-loader>
                </div>
                <!-- === Main Part === -->
                <div
                        [ngClass]="{
                        'xs-flex-column-center': canShowQRCodeMessage(),
                        'xs-flex-column': !canShowQRCodeMessage()
                        }"
                        class="xs-width-full xs-height-full xs-flex-1 xs-mt-15">
                    <!-- === Error === -->
                    <xsi-error-block
                            (buttonClickEvent)="search()"
                            *ngIf="hasError() && !loading"
                            [absolute]="true"
                            [error]="error"
                            [line1]="errorMessage"
                            [showButton]="true">
                    </xsi-error-block>
                    <!-- === QRCode Message === -->
                    <xsi-text-block
                            *ngIf="canShowQRCodeMessage()"
                            [center]="true"
                            [icon]="ICON_SCAN"
                            [line1FontWeight500]="true"
                            class="xs-absolute-center-vh"
                            iconStyleClass="xs-opacity-75"
                            line1="{{TR_BASE}}scanMessage.line1"
                            line1Color="secondary"
                            line2="{{TR_BASE}}scanMessage.line2"
                            line2Color="secondary"
                            styleClass="xs-mt-100 xs-plr-15">
                    </xsi-text-block>

                    <div *ngIf="searchHistoryMode" class="xs-flex-column xs-width-full">
                        <div *ngIf="!hasError()" class="xs-flex-row xs-align-items-end xs-justify-content-space-between xs-width-full">
                            <xsi-ivar
                                    [ellipsis]="true"
                                    [line1]="{ value: TR_BASE + 'history.title', size: 'medium', color: 'secondary', fontWeight500: true }"
                                    [line2]="{ value: TR_BASE + 'history.subtitle', trParams: {historySize: searchHistoryMaxSize}, size: 'small', color: 'secondary' }"
                                    class="xs-overflow-hidden">
                            </xsi-ivar>
                            <xsi-button-text
                                    (clickEvent)="clearSearchHistory()"
                                    [disabled]="!hasSearchHistoryItems()"
                                    label="{{ TR_BASE }}history.clear"
                                    styleClass="xs-ml-10">
                            </xsi-button-text>
                        </div>
                        <div *ngIf="!hasError()" [ngClass]="{ 'xsi-border-top-discrete-darker': showListingSeparator}" class="xs-flex-column xs-width-full xs-mt-15 xs-pt-40">
                            <xsi-text
                                    *ngIf="!hasSearchHistoryItems()"
                                    class="xs-flex-row-center xs-width-full xs-text-align-center"
                                    color="secondary"
                                    size="intermediate"
                                    styleClass="xs-width-fit-content xsi-no-result-found-background-discrete"
                                    value="{{ TR_BASE }}history.noItem">
                            </xsi-text>
                            <!-- === Current Result Section === -->
                            <div
                                    *ngIf="hasSearchHistoryItems() && !hasError()"
                                    class="xs-flex-column xs-width-full">
                                <lcei-mfo-certificate-order-search-history-item
                                        (clickEvent)="onHistoryItemClick($event)"
                                        *ngFor="let historyItem of searchHistoryItems"
                                        [data]="historyItem"
                                        styleClass="xs-mb-30">
                                </lcei-mfo-certificate-order-search-history-item>
                            </div>

                        </div>
                    </div>
                    <div *ngIf="searchTextMode" class="xs-flex-column xs-width-full">
                        <div *ngIf="!hasError() && (searchTextResults | xsIsNotNull)" class="xs-flex-row xs-align-items-end xs-justify-content-space-between xs-width-full">
                            <xsi-ivar
                                    *ngIf="isSearchByOrderNumber()"
                                    [ellipsis]="true"
                                    [line1]="{
                                    value: TR_BASE + 'resultTitle.byOrderNumber',
                                    trParams: {nResults: searchTextNResults},
                                    size: 'medium',
                                    color: 'secondary',
                                    fontWeight500: true
                                    }"
                                    [line2]="{ value: TR_BASE + 'resultSubtitle.byOrderNumber', trParams: {limit: searchTextLimit}, size: 'small', color: 'secondary' }"
                                    class="xs-overflow-hidden">
                            </xsi-ivar>
                            <xsi-ivar
                                    *ngIf="isSearchByVerificationCode()"
                                    [ellipsis]="true"
                                    [line1]="{
                                    value: TR_BASE + 'resultTitle.byVerificationCode',
                                    trParams: {nResults: searchTextNResults},
                                    size: 'medium',
                                    color: 'secondary',
                                    fontWeight500: true
                                    }"
                                    [line2]="{ value: TR_BASE + 'resultSubtitle.byVerificationCode', size: 'small', color: 'secondary' }"
                                    class="xs-overflow-hidden">
                            </xsi-ivar>
                        </div>
                        <div *ngIf="!hasError() && (searchTextResults | xsIsNotNull)" [ngClass]="{ 'xsi-border-top-discrete-darker': showListingSeparator}" class="xs-flex-column xs-width-full xs-mt-15 xs-pt-40">
                            <xsi-text
                                    *ngIf="!hasSearchTextResults()"
                                    class="xs-flex-row-center xs-width-full xs-text-align-center"
                                    color="secondary"
                                    size="intermediate"
                                    styleClass="xs-width-fit-content xsi-no-result-found-background-discrete"
                                    value="{{ TR_BASE }}noResult">
                            </xsi-text>
                            <ng-container *ngIf="hasSearchTextResults() && !loading">
                                <lcei-certificate-order-partial
                                        (clickEvent)="onSearchTextResultClick($event)"
                                        *ngFor="let order of searchTextResults"
                                        [data]="order"
                                        [disabled]="loading"
                                        [full]="true"
                                        [showRequestorIcon]="true"
                                        [stripe]="true"
                                        styleClass="xs-mb-30">
                                </lcei-certificate-order-partial>
                            </ng-container>

                        </div>
                    </div>
                </div>
                <!-- === Background Icon === -->
                <xsi-background-icon
                        *ngIf="showBackgroundIcon && (backgroundIcon | xsIsNotEmpty)"
                        [absoluteCenterH]="true"
                        [data]="backgroundIcon"
                        [opacity]="0.5"
                        bottom="50px">
                </xsi-background-icon>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer ion-no-border xs-justify-content-center" mode="ios">
    <ion-button
            (click)="scan()"
            [disabled]="loading"
            class="xsi-button xsi-button-full xs-font-weight-600"
            color="primary"
            mode="ios">
        <ion-icon name="scan-outline" slot="start"></ion-icon>
        {{ TR_BASE + 'scan' | translate }}
    </ion-button>
</ion-footer>
