import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';

@Component({
    selector: 'lcei-app-name',
    template: `
        <div class="lcei-app-name {{ styleClass }}">
            <div
                    class="lcei-app-name-image"
                    *ngIf="isType('image')">
                <img
                        *ngIf="isType('image')"
                        src="assets/images/lce-header.png"
                        alt="LCE"
                        xsiGesture
                        [gestures]="[{name: 'doubleTap'}]"
                        (doubleTap)="onDoubleTap($event)"/>
                <span
                        *ngIf="showFacilityName && (facilityName | xsIsNotEmpty)"
                        class="lcei-app-name-facility-name"
                        [innerHTML]="facilityName">
                </span>
            </div>
            <div
                    class="lcei-app-name-html"
                    *ngIf="isType('html')">
                <span>LCE</span>
            </div>
        </div>
    `,
    host: {class: 'xs-display-block xs-width-fit-content'}
})
export class LCEIAppNameComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() type?: 'html' | 'image';

    @Input() showFacilityName?: boolean;
    @Input() facilityName?: string;

    @Output() doubleTapEvent = new EventEmitter<Event>();

    ngOnInit(): void {
        if (XSUtils.isEmpty(this.type)) this.type = 'image';
        if (XSUtils.isNull(this.showFacilityName)) this.showFacilityName = false;
        if (this.showFacilityName) {
            XSAssert.notEmpty(this.facilityName, 'facilityName');
        }
    }

    public isType(type: 'html' | 'image'): boolean {
        return this.type === type;
    }

    public onDoubleTap(event: Event): void {
        this.doubleTapEvent.emit(event);
    }
}
