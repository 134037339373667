import {XSIIcon} from '@xs/ionic/common';

// --------------------------------------------------------------------------------------------------------
// --- Service ---
// --------------------------------------------------------------------------------------------------------

export interface LCEIMFOServiceConfiguration {
    activation?: LCEIMFOServiceActivation;
    arrangement?: LCEIMFOServiceArrangement;
}

// --------------------------------------------------------------------------------------------------------
// --- Service Arrangement ---
// --------------------------------------------------------------------------------------------------------

export interface LCEIMFOServiceArrangement {
    mode: LCEIMFOServiceArrangementMode;
    cardOptions?: LCEIMFOServiceArrangementCardOptions;
}

export enum LCEIMFOServiceArrangementMode {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
    CARD = 'card'
}

export interface LCEIMFOServiceArrangementCardOptions {
    showTitle?: boolean;
    backgroundColor?: 'primary' | 'secondary';
}

// --------------------------------------------------------------------------------------------------------
// --- Service Activation ---
// --------------------------------------------------------------------------------------------------------

export interface LCEIMFOServiceActivation {
    certificateOrderCreate?: boolean;
    birthDeclaration?: boolean;
    weddingBooking?: boolean;
    information?: boolean;
}

// --------------------------------------------------------------------------------------------------------
// --- Service Item ---
// --------------------------------------------------------------------------------------------------------

export interface LCEIMFOHomeServiceItem {
    id: LCEIMFOServiceID | LCEIMFOServiceIDStr;
    itemContainerStyleClass?: string;
    itemHostStyleClass?: string;
    itemStyleClass?: string;

    icon: XSIIcon;
    title: string;
    subtitle?: string;

    disabled?: boolean;
    comingSoon?: boolean;

    onClick: (event: Event) => void;
}

// --------------------------------------------------------------------------------------------------------
// --- *** ---
// --------------------------------------------------------------------------------------------------------

export enum LCEIMFOServiceID {
    CERTIFICATE_ORDER_CREATE = 'certificateOrderCreate',
    BIRTH_DECLARATION = 'birthDeclaration',
    WEDDING_BOOKING = 'weddingBooking',
    INFORMATION = 'information'
}

export type LCEIMFOServiceIDStr = 'certificateOrderCreate' | 'birthDeclaration' | 'weddingBooking' | 'information';