import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {XS_STR_SPACE, XSAssert, XSCoordinateObject, XSUtils} from '@xs/base';
import {
    LCE_TR_BASE_DELIVERY_DESTINATION_TYPE,
    LCEDeliveryDestination,
    LCEDeliveryDestinationCustomLocation,
    LCEDeliveryDestinationFacility,
    LCEDeliveryDestinationInternational,
    LCEDeliveryDestinationServicePoint,
    LCEDeliveryDestinationType,
    LCEFacilityPartial,
    LCEServicePointPartial
} from '@lce/core';
import {XSIColor, XSIColorStr, XSIIcon, XSIText} from '@xs/ionic/common';
import {XSSize, XSTranslationService} from '@xs/common';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {XSIAvatar} from '@xs/ionic/core';

@Component({
    selector: 'lcei-certificate-order-delivery-destination',
    templateUrl: 'lcei-certificate-order-delivery-destination.component.html'
})
export class LCEICertificateOrderDeliveryDestinationComponent implements OnInit, OnChanges {

    DESTINATION_TYPE = LCEDeliveryDestinationType;

    @Input() styleClass?: string;
    @Input() dataStyleClass?: string;
    @Input() labelStyleClass?: string;

    @Input() data: LCEDeliveryDestination;

    @Input() disabled?: boolean;
    @Input() ellipsis?: boolean;

    @Input() full?: boolean;

    @Input() showIcon?: boolean;
    @Input() avatarPosition?: 'left' | 'right';
    @Input() avatarColor?: XSIColor | XSIColorStr | string;

    label: XSIText;

    constructor(private translationService: XSTranslationService) {
    }

    get destinationInternationalLine3(): string | undefined {
        if (!this.full) return undefined;
        const destination = this.data as LCEDeliveryDestinationInternational;
        if (!XSUtils.isEmpty(destination.shippingProvider)) {
            return 'lce.core.shippingProvider.' + destination.shippingProvider.toString();
        }
        if (!XSUtils.isEmpty(destination.address.province?.name)) {
            return destination.address.province!.name;
        }
        if (!XSUtils.isEmpty(destination.address.postalCode)) {
            return destination.address.postalCode;
        }
        return undefined;
    }

    get destinationInternationalLine2(): string {
        const destination = this.data as LCEDeliveryDestinationInternational;
        const countryName = this.translationService.translateKey('xs.country.' + destination.address.countryISO);
        console.log('destinationInternationalLine2 === countryName ==== (check if translationService.translateKey is call every refresh ???? )', countryName);
        return destination.address.city + ',' + XS_STR_SPACE + countryName;
    }

    get destinationCustomLocationLine3(): string | undefined {
        if (!this.full) return undefined;
        const destination = this.data as LCEDeliveryDestinationCustomLocation;
        if (destination.home === true) {
            return 'lcei.shared.certificateOrder.home';
        }
        if (destination.office === true) {
            return 'lcei.shared.certificateOrder.office';
        }
        return new XSCoordinateObject(destination.address.coordinate!).toString(false);
    }

    get destinationCustomLocationLine2(): string {
        const destination = this.data as LCEDeliveryDestinationCustomLocation;
        const countryName = this.translationService.translateKey('xs.country.' + destination.address.countryISO);
        console.log('destinationCustomLocationLine2 === countryName ==== (check if translationService.translateKey is call every refresh ???? )', countryName);
        return destination.address.city + ',' + XS_STR_SPACE + countryName;
    }

    get destinationServicePoint(): LCEDeliveryDestinationServicePoint<LCEServicePointPartial> {
        return this.data as LCEDeliveryDestinationServicePoint<LCEServicePointPartial>;
    }

    get destinationFacility(): LCEDeliveryDestinationFacility<LCEFacilityPartial> {
        return this.data as LCEDeliveryDestinationFacility<LCEFacilityPartial>;
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.full)) this.full = false;
        this.buildLabel();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.label) && !changes.label!.isFirstChange()) {
            this.buildLabel();
        }
    }

    public toLine3Text(str: string | undefined): XSIText | undefined {
        if (XSUtils.isEmpty(str)) return undefined;
        return {value: str!, size: XSSize.SMALL, color: XSIColor.SECONDARY};
    }

    public getAvatar(type: LCEDeliveryDestinationType.CUSTOM_LOCATION | LCEDeliveryDestinationType.INTERNATIONAL): XSIAvatar | undefined {
        if (!this.showIcon) return undefined;
        return {
            value: type === LCEDeliveryDestinationType.CUSTOM_LOCATION ? LCEI_SHARED_ICON.location : LCEI_SHARED_ICON.international,
            size: XSSize.MEDIUM,
            box: true,
            boxSize: XSSize.INTERMEDIATE,
            color: XSUtils.isEmpty(this.avatarColor) ? XSIColor.SECONDARY : this.avatarColor
        } as XSIIcon;
    }

    public isDestination(type: LCEDeliveryDestinationType): boolean {
        return this.data.type === type;
    }

    private buildLabel(): void {
        this.label = {
            styleClass: this.labelStyleClass,
            value: LCE_TR_BASE_DELIVERY_DESTINATION_TYPE + this.data.type,
            size: XSSize.EXTRA_SMALL
        };
    }
}