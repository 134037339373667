import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSIIcon} from '@xs/ionic/common';

@Component({
    selector: 'xsi-background-icon',
    template: `
        <div
                [ngClass]="{
                'xs-absolute-center-v': absoluteCenterV,
                'xs-absolute-center-h': absoluteCenterH,
                'xs-absolute-center-vh': absoluteCenterVH
                }"
                class="xs-flex-column-center xs-position-absolute xs-z-index-minus-10 {{ styleClass }}"
                [style.top]="top"
                [style.bottom]="bottom"
                [style.left]="left"
                [style.right]="right"
                [style.opacity]="opacity">
            <xsi-icon-renderer [styleClass]="iconStyleClass" [data]="data"></xsi-icon-renderer>
        </div>
    `
})
export class XSIBackgroundIconComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() iconStyleClass?: string;

    @Input() data: XSIIcon;

    @Input() top?: string;
    @Input() bottom?: string;
    @Input() left?: string;
    @Input() right?: string;

    @Input() opacity?: number;

    @Input() absoluteCenterV?: boolean;
    @Input() absoluteCenterH?: boolean;
    @Input() absoluteCenterVH?: boolean;

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.opacity)) this.opacity = 0.25;
    }
}
