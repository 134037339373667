import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCE_TR_BASE_CERTIFICATE_TYPE_MEDIUM, LCE_TR_BASE_DELIVERY_DESTINATION_TYPE, LCECertificateOrderPartial} from '@lce/core';

@Component({
    selector: 'lcei-certificate-order-partial',
    templateUrl: 'lcei-certificate-order-partial.component.html'
})
export class LCEICertificateOrderPartialComponent implements OnInit {

    readonly TR_BASE_CERTIFICATE_TYPE_MEDIUM: string = LCE_TR_BASE_CERTIFICATE_TYPE_MEDIUM;
    readonly TR_BASE_DELIVERY_DESTINATION_TYPE: string = LCE_TR_BASE_DELIVERY_DESTINATION_TYPE;

    @Input() styleClass?: string;
    @Input() ionItemStyleClass?: string;

    @Input() disabled?: boolean;

    @Input() data: LCECertificateOrderPartial;

    @Input() showRequestorIcon?: boolean;

    @Input() full?: boolean;
    @Input() stripe?: boolean;

    @Input() clickable?: boolean;

    @Output() clickEvent = new EventEmitter<LCECertificateOrderPartial>();

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
    }

    public onClick(event: Event): void {
        if (!this.clickable) {
            event.stopImmediatePropagation();
            return;
        }
        this.clickEvent.emit(this.data);
    }
}
