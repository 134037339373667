<div class="xsi-data-record {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <xsi-data-section-header-renderer
                *ngIf="showSectionHeader"
                [data]="sectionHeader!"
                [style.width]="hasMenu() ? 'calc(100% - 20px)' : undefined"
                [styleClass]="sectionHeaderStyleClass">
        </xsi-data-section-header-renderer>
        <xsi-icon-renderer *ngIf="hasMenu()" [block]="true" [data]="ICON_MENU"></xsi-icon-renderer>
    </div>
    <div class="xsi-data-block xsi-data-block-border-light">
        <ion-item lines="full">
            <ion-label class="xs-max-width-70" slot="start">
                <xsi-text color="secondary" size="intermediate" value="lce.core.label.destination"></xsi-text>
            </ion-label>
            <ion-label slot="end">
                <lcei-certificate-order-delivery-destination
                        [data]="data.destination"
                        [showIcon]="true"
                        avatarPosition="right">
                </lcei-certificate-order-delivery-destination>
            </ion-label>
        </ion-item>

        <xsi-data-field-audit-on-by
                [alignEnd]="true"
                [label]="{ value: 'lce.core.label.deliveredOn', timeAgo: data.deliveredOn }"
                [onTimeAgo]="false"
                [required]="false"
                [value]="(data.deliveredOn | xsIsNotEmpty) ? { on: data.deliveredOn!, by: data.deliveredBy! } : undefined">
        </xsi-data-field-audit-on-by>

        <xsi-data-field-calendar
                [label]="{ value: 'lce.core.label.estimatedDeliveryDate', maxWidth: '175px' }"
                [required]="false"
                [value]="(data.estimatedDeliveryDate | xsIsNotEmpty) ? $any({ data: data.estimatedDeliveryDate!, format: 'xsMediumLongDate', timeAgo: false, alignEnd: true }) : undefined"
                belowContainerStyleClass="xs-flex-row xs-justify-content-end xs-text-align-right xs-mt-2">
            <ng-template *ngIf="data.estimatedDeliveryTimeRange | xsIsNotEmpty" xsTemplate="below">
                <xs-time-range [data]="data.estimatedDeliveryTimeRange!" [displayBlock]="true" size="small"></xs-time-range>
            </ng-template>
        </xsi-data-field-calendar>

        <xsi-data-field-calendar
                [label]="{ value: 'lce.core.label.desiredDeliveryDate', maxWidth: '175px' }"
                [required]="false"
                [value]="(data.desiredDeliveryDate | xsIsNotEmpty) ? $any({ data: data.desiredDeliveryDate!, format: 'xsMediumLongDate', timeAgo: false, alignEnd: true }) : undefined"
                belowContainerStyleClass="xs-flex-row xs-justify-content-end xs-text-align-right xs-mt-2">
            <ng-template *ngIf="data.desiredDeliveryTimeRange | xsIsNotEmpty" xsTemplate="below">
                <xs-time-range [data]="data.desiredDeliveryTimeRange!" [displayBlock]="true" size="small"></xs-time-range>
            </ng-template>
        </xsi-data-field-calendar>

        <xsi-data-field-textarea
                [label]="{ value: 'lce.core.label.note' }"
                [required]="false"
                [value]="(data.note | xsIsNotEmpty) ? $any({ value: data.note }) : undefined">
        </xsi-data-field-textarea>
    </div>
</div>