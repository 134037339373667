import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {IonicModule} from '@ionic/angular';
import {XSCommonModule, XSDatePipe} from '@xs/common';
import {XSBaseModule} from '@xs/base';
import {XSIBaseModule} from '@xs/ionic/base';
import {XSICommonModule} from '@xs/ionic/common';
import {XSIAbstractUserDetailModalService, XSICoreModule, XSIUserDetailModalService} from '@xs/ionic/core';
import {LCEICopyrightComponent} from './components/copyright/lcei-copyright.component';
import {LCEIAppNameComponent} from './components/app-name/lcei-app-name.component';
import {LCEICertificateOrderRecordComponent} from './components/certificate-order/record/lcei-certificate-order-record.component';
import {LCEICertificateOrderRecordGeneralComponent} from './components/certificate-order/record/general/lcei-certificate-order-record-general.component';
import {LCEICertificateOrderRecordAdministrationComponent} from './components/certificate-order/record/administration/lcei-certificate-order-record-administration.component';
import {LCEICertificateRecordComponent} from './components/certificate/record/lcei-certificate-record.component';
import {LCECoreModule, LCEUserPositionPipe} from '@lce/core';
import {LCEICertificateOrderModalService} from './components/certificate-order/lcei-certificate-order-modal.service';
import {LCEIFacilityPartialComponent} from './components/facility/partial/lcei-facility-partial.component';
import {LCEIServicePointPartialComponent} from './components/service-point/partial/lcei-service-point-partial.component';
import {LCEIMunicipalityPartialComponent} from './components/municipality/partial/lcei-municipality-partial.component';
import {LCEIDistrictPartialComponent} from './components/district/partial/lcei-district-partial.component';
import {LCEICertificateOrderDeliveryDestinationComponent} from './components/certificate-order/delivery/destination/lcei-certificate-order-delivery-destination.component';
import {LCEIUserDetailModalService} from './components/user/lcei-user-detail-modal.service';
import {LCEIUserAvatarComponent} from './components/user/avatar/lcei-user-avatar.component';
import {LCEIUserPartialComponent} from './components/user/partial/lcei-user-partial.component';
import {LCEICertificateOrderRecordDeliveryComponent} from './components/certificate-order/record/delivery/lcei-certificate-order-record-delivery.component';
import {LCEICertificateOrderRecordProcurationComponent} from './components/certificate-order/record/procuration/lcei-certificate-order-record-procuration.component';
import {LCEICertificateOrderRecordPaymentComponent} from './components/certificate-order/record/payment/lcei-certificate-order-record-payment.component';
import {XSTimeRangeComponent} from './components/xs/common/xs-time-range.component';
import {XSInputMaxlengthDirective} from './components/xs/common/xs-input-maxlength.directive';
import {XSIPaymentChargeDetailComponent} from './components/xsi/payment/charge/detail/xsi-payment-charge-detail.component';
import {XSIPaymentChargePartialComponent} from './components/xsi/payment/charge/partial/xsi-payment-charge-partial.component';
import {XSIBackgroundIconComponent} from './components/xsi/core/xsi-background-icon.component';
import {LCEICertificateOrderPartialComponent} from './components/certificate-order/partial/lcei-certificate-order-partial.component';
import {LCEICertificateOrderStatusComponent} from './components/certificate-order/status/lcei-certificate-order-status.component';
import {LCEICertificateAvatarComponent} from './components/certificate/avatar/lcei-certificate-avatar.component';
import {LCEIAuthenticationPINCodeComponent} from './components/authentification/pin-code/lcei-authentication-pin-code.component';
import {LCEICertificateOrderNumberOfCopiesComponent} from './components/certificate-order/number-of-copies/lcei-certificate-order-number-of-copies.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        XSBaseModule,
        XSCommonModule,
        XSIBaseModule,
        XSICommonModule,
        XSICoreModule,
        LCECoreModule,
        CommonModule
    ],
    declarations: [
        // --- XS --- // TODO: move them as soon as possible.
        XSTimeRangeComponent,
        XSInputMaxlengthDirective,
        // --- XSI --- // TODO: move them as soon as possible.
        XSIBackgroundIconComponent,
        XSIPaymentChargeDetailComponent,
        XSIPaymentChargePartialComponent,
        // ---------------------------------------------------------------------
        // ---------------------------------------------------------------------
        // ---------------------------------------------------------------------
        // --- Authentication ---
        LCEIAuthenticationPINCodeComponent,
        // --- Certificate Order Number of copies ---
        LCEICertificateOrderNumberOfCopiesComponent,
        // --- Certificate Order Status ---
        LCEICertificateOrderStatusComponent,
        // --- Certificate Order Partial ---
        LCEICertificateOrderPartialComponent,
        // --- Certificate Order Record ---
        LCEICertificateOrderRecordPaymentComponent,
        LCEICertificateOrderRecordProcurationComponent,
        LCEICertificateOrderRecordDeliveryComponent,
        LCEICertificateOrderDeliveryDestinationComponent,
        LCEICertificateOrderRecordAdministrationComponent,
        LCEICertificateOrderRecordGeneralComponent,
        LCEICertificateOrderRecordComponent,
        // --- Certificate ---
        LCEICertificateAvatarComponent,
        LCEICertificateRecordComponent,
        // --- Service Point ---
        LCEIServicePointPartialComponent,
        // --- Facility ---
        LCEIFacilityPartialComponent,
        // --- Municipality ---
        LCEIMunicipalityPartialComponent,
        // --- District ---
        LCEIDistrictPartialComponent,
        // --- User ---
        LCEIUserPartialComponent,
        LCEIUserAvatarComponent,
        // --- *** ---
        LCEIAppNameComponent,
        LCEICopyrightComponent
    ],
    exports: [
        XSBaseModule,
        XSCommonModule,
        XSIBaseModule,
        XSICommonModule,
        XSICoreModule,
        LCECoreModule,
        // --- XS --- // TODO: move them as soon as possible.
        XSInputMaxlengthDirective,
        // ---------------------------------------------------------------------
        // ---------------------------------------------------------------------
        // ---------------------------------------------------------------------
        // --- Authentication ---
        LCEIAuthenticationPINCodeComponent,
        // --- Certificate Order Number of copies ---
        LCEICertificateOrderNumberOfCopiesComponent,
        // --- Certificate Order Status ---
        LCEICertificateOrderStatusComponent,
        // --- Certificate Order Partial ---
        LCEICertificateOrderPartialComponent,
        // --- Certificate Order Record ---
        LCEICertificateOrderRecordComponent,
        LCEICertificateOrderDeliveryDestinationComponent,
        // --- Certificate ---
        LCEICertificateAvatarComponent,
        // --- Service Point ---
        LCEIServicePointPartialComponent,
        // --- Facility ---
        LCEIFacilityPartialComponent,
        // --- Municipality ---
        LCEIMunicipalityPartialComponent,
        // --- District ---
        LCEIDistrictPartialComponent,
        // --- User ---
        LCEIUserPartialComponent,
        LCEIUserAvatarComponent,
        // --- * ---
        LCEIAppNameComponent,
        LCEICopyrightComponent,
        XSIBackgroundIconComponent
    ],
    providers: [
        LCEICertificateOrderModalService,
        LCEUserPositionPipe,
        XSDatePipe,
        {provide: XSIAbstractUserDetailModalService, useExisting: LCEIUserDetailModalService},
        {provide: XSIUserDetailModalService, useExisting: LCEIUserDetailModalService}
    ]
})
export class LCEISharedModule {
}
