import {XSIAvatar, XSIThumbnail} from '@xs/ionic/core';
import {XSIColor, XSIColorStr, XSIIcon, XSIIconLabel} from '@xs/ionic/common';
import {LCEUserType, LCEUserUtils} from '@lce/core';
import {XSAssert, XSPersonName, XSUtils} from '@xs/base';
import {XSCommonDomainUtils, XSSize, XSSizeStr} from '@xs/common';
import {LCEI_SHARED_ICON} from '../constants/lcei-shared-icon.constant';

export class LCEISharedUtils {

    public static buildUserAvatar(arg: {
        userID?: string;
        userType: LCEUserType;
        userName?: XSPersonName;
        userProfileImage?: string;
        size?: XSSize | XSSizeStr | string;
        boxSize?: XSSize | XSSizeStr | string;
        color?: XSIColor | XSIColorStr | string;
    }): XSIAvatar {

        XSAssert.notEmpty(arg, 'arg');
        XSAssert.notEmpty(arg.userType, 'arg.userType');

        const iconSize = XSUtils.isEmpty(arg.size) ? XSSize.MEDIUM : arg.size;
        const labelSize = XSUtils.isEmpty(arg.size) ? XSSize.LARGE : arg.size;
        const boxSize = XSUtils.isEmpty(arg.boxSize) ? XSSize.INTERMEDIATE : arg.boxSize;
        const color = XSUtils.isEmpty(arg.color) ? XSIColor.SECONDARY : arg.color;

        if (!XSUtils.isEmpty(arg.userProfileImage)) {
            return {src: arg.userProfileImage, size: boxSize} as XSIThumbnail;
        }

        const userIcon = LCEISharedUtils.getUserIcon(arg.userType, arg.userID);

        if (LCEUserUtils.isUserSystem(arg.userType, arg.userID)) {
            return {
                value: userIcon,
                size: iconSize,
                box: true,
                boxSize: boxSize,
                color: color
            } as XSIIcon;
        }

        switch (arg.userType) {
            case LCEUserType.MUNICIPAL_EMPLOYEE:
            case LCEUserType.DELIVERY_MAN:
            case LCEUserType.EMPLOYEE:
                if (XSUtils.isEmpty(arg.userName)) {
                    return {
                        value: userIcon,
                        size: iconSize,
                        box: true,
                        boxSize: boxSize,
                        color: color
                    } as XSIIcon;
                }
                return {
                    label: XSCommonDomainUtils.getPersonInitials(arg.userName!),
                    size: labelSize,
                    box: true,
                    boxSize: boxSize,
                    color: color
                } as XSIIconLabel;
            case LCEUserType.CUSTOMER:
                return {
                    value: userIcon,
                    size: iconSize,
                    box: true,
                    boxSize: boxSize,
                    color: color
                } as XSIIcon;
            default:
                throw new Error(`failed to build user avatar, unknown user type ${arg.userType}.`);
        }
    }

    public static getUserIcon(userType: LCEUserType, userID?: string): string {
        XSAssert.notEmpty(userType, 'userType');
        if (LCEUserUtils.isUserSystem(userType, userID)) return LCEI_SHARED_ICON.user.system;
        switch (userType) {
            case LCEUserType.MUNICIPAL_EMPLOYEE:
                return LCEI_SHARED_ICON.user.municipalEmployee;
            case LCEUserType.DELIVERY_MAN:
                return LCEI_SHARED_ICON.user.deliveryMan;
            case LCEUserType.EMPLOYEE:
                return LCEI_SHARED_ICON.user.employee;
            case LCEUserType.CUSTOMER:
                return LCEI_SHARED_ICON.user.customer;
            case LCEUserType.SYSTEM:
                return LCEI_SHARED_ICON.user.system;
            default:
                throw new Error(`failed to get user icon, unknown user type ${userType}.`);
        }
    }
}