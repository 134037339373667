import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {LCEUserType} from '@lce/core';
import {XSSize, XSSizeStr} from '@xs/common';
import {XSIColor, XSIColorStr} from '@xs/ionic/common';
import {XSAssert, XSPersonName, XSUtils} from '@xs/base';
import {XSIAvatar} from '@xs/ionic/core';
import {LCEISharedUtils} from '../../../api/utils/lcei-shared-utils';

@Component({
    selector: 'lcei-user-avatar',
    template: `
        <xsi-avatar
                (clickEvent)="clickEvent.emit($event)"
                [hostStyleClass]="avatarHostStyleClass"
                [disabled]="disabled"
                [clickable]="clickable"
                [data]="avatar"
                [styleClass]="styleClass">
        </xsi-avatar>
    `
})
export class LCEIUserAvatarComponent implements OnInit, OnChanges {

    @Input() styleClass?: string;
    @Input() avatarHostStyleClass?: string;

    @Input() disabled?: boolean;
    @Input() clickable?: boolean;

    @Input() type: LCEUserType;
    @Input() name?: XSPersonName;
    @Input() profileImage?: string;

    @Input() size?: XSSize | XSSizeStr | string;
    @Input() boxSize?: XSSize | XSSizeStr | string;

    @Input() color?: XSIColor | XSIColorStr | string;

    @Output() clickEvent = new EventEmitter<Event>();

    avatar: XSIAvatar;

    ngOnInit(): void {
        XSAssert.notEmpty(this.type, 'type');
        if (XSUtils.isNull(this.clickable)) this.clickable = this.clickEvent.observers.length > 0;
        this.buildAvatar();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!XSUtils.isEmpty(changes.type) && !changes.type.isFirstChange()) this.update();
        if (!XSUtils.isEmpty(changes.name) && !changes.name.isFirstChange()) this.update();
        if (!XSUtils.isEmpty(changes.profileImage) && !changes.profileImage.isFirstChange()) this.update();
        if (!XSUtils.isEmpty(changes.size) && !changes.size.isFirstChange()) this.update();
        if (!XSUtils.isEmpty(changes.boxSize) && !changes.boxSize.isFirstChange()) this.update();
        if (!XSUtils.isEmpty(changes.color) && !changes.color.isFirstChange()) this.update();
    }

    public update(): void {
        this.buildAvatar();
    }

    private buildAvatar(): void {
        this.avatar = LCEISharedUtils.buildUserAvatar({
            userType: this.type,
            userName: this.name,
            userProfileImage: this.profileImage,
            size: this.size,
            boxSize: this.boxSize,
            color: this.color
        });
    }
}
