<ion-header [translucent]="true" class="xsi-ion-header">
    <lcei-app-name type="html"></lcei-app-name>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-certificate-orders-page">
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>
