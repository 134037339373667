import {Component, Input, OnInit} from '@angular/core';
import {LCEIMFOHomeServiceItem} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {XSAssert} from '@iro-xs/xs-base';

@Component({
    selector: 'lcei-mfo-home-service-items-vertical',
    templateUrl: 'lcei-mfo-home-service-items-vertical.component.html',
    host: {class: 'xs-flex-row xs-width-full xs-max-width-750'}
})
export class LCEIMFOHomeServiceItemsVerticalComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() itemContainerStyleClass?: string;
    @Input() itemHostStyleClass?: string;
    @Input() itemStyleClass?: string;

    @Input() data: LCEIMFOHomeServiceItem[];

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }
}
