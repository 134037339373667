import {Component, OnInit} from '@angular/core';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {Router} from '@angular/router';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {XSIAlertService, XSIToastService} from '@xs/ionic/core';
import {LCEIMFOAppInitializerService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-app-initializer.service';
import {LCEIMFOCacheService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-cache.service';

@Component({
    selector: 'lcei-mfo-settings-advanced-configuration-page',
    templateUrl: 'lcei-mfo-settings-advanced-configuration.page.html',
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsAdvancedConfigurationPage implements OnInit {

    readonly TR_BASE: string = 'lcei.mfo.features.settings.';

    readonly ICON_ADVANCED_CONFIGURATION: XSIIcon = {
        value: LCEI_MFO_ICON.settings,
        size: '25px',
        box: true,
        boxSize: 'medium',
        color: 'primary'
    };

    constructor(
        private router: Router,
        private cacheService: LCEIMFOCacheService,
        private storageService: LCEIMFOStorageService,
        private alertService: XSIAlertService,
        private toastService: XSIToastService,
        private appInitializerService: LCEIMFOAppInitializerService) {
    }

    ngOnInit(): void {
    }
    
    public onClearStorage(): void {
        this.alertService.showConfirmationAlert({
            header: 'Clear storage',
            message: 'Are you sure you want to clear the application storage ?',
            onYesButtonClick: () => {
                this.storageService.clear(false);
                this.appInitializerService.preload();
                this.toastService.showToastSuccess('Storage successfully cleared.', 'bottom', 3000);
            }
        }).then();
    }

    public onClearCache(): void {
        this.alertService.showConfirmationAlert({
            header: 'Clear global cache',
            message: 'Are you sure you want to clear the global cache of the application ?',
            onYesButtonClick: () => {
                this.cacheService.clearGlobalCache();
                this.appInitializerService.preload();
                this.toastService.showToastSuccess('Global cache successfully cleared.', 'bottom', 3000);
            }
        }).then();
    }

    public onReset(): void {
        this.alertService.showConfirmationAlert({
            header: 'Reset Application',
            message: 'Are you sure you want to reset of the application ?',
            onYesButtonClick: () => {
                this.storageService.clear(true);
                this.router.navigateByUrl(LCEI_MFO_ROUTING.starter.index).then();
            }
        }).then();
    }

    public close(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
    }
}
