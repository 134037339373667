import {NgModule} from '@angular/core';
import {LCEIMFOSettingsPage} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings.page';
import {LCEIMFOSettingsRoutingModule} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings-routing.module';
import {LCEIMFOSettingsDeveloperModePage} from '@lce/ionic/mfo/app/features/settings/developer-mode/lcei-mfo-settings-developer-mode.page';
import {LCEIMFOSharedModule} from '@lce/ionic/mfo/app/shared/lcei-mfo-shared.module';
import {LCEIMFOCertificateOrderModule} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order.module';
import {LCEIMFOSettingsCertificateOrderCreatePage} from '@lce/ionic/mfo/app/features/settings/certificate-order-create/lcei-mfo-settings-certificate-order-create.page';
import {LCEIMFOSettingsModuleService} from '@lce/ionic/mfo/app/features/settings/lcei-mfo-settings-module.service';
import {LCEIMFOSettingsAdvancedConfigurationPage} from '@lce/ionic/mfo/app/features/settings/advanced-configuration/lcei-mfo-settings-advanced-configuration.page';
import {LCEIMFOSettingsMainScreenServiceArrangementPage} from '@lce/ionic/mfo/app/features/settings/main-screen/service-arrangement/lcei-mfo-settings-main-screen-service-arrangement.page';
import {LCEIMFOSettingsMainScreenServiceActivationPage} from '@lce/ionic/mfo/app/features/settings/main-screen/service-activation/lcei-mfo-settings-main-screen-service-activation.page';

@NgModule({
    imports: [
        LCEIMFOSharedModule,
        LCEIMFOSettingsRoutingModule,
        LCEIMFOCertificateOrderModule
    ],
    declarations: [
        LCEIMFOSettingsPage,
        LCEIMFOSettingsDeveloperModePage,
        LCEIMFOSettingsMainScreenServiceArrangementPage,
        LCEIMFOSettingsCertificateOrderCreatePage,
        LCEIMFOSettingsAdvancedConfigurationPage,
        LCEIMFOSettingsMainScreenServiceActivationPage
    ],
    providers: [LCEIMFOSettingsModuleService]
})
export class LCEIMFOSettingsModule {
}
