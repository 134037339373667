import {Component, Input, OnInit} from '@angular/core';
import {XS_STR_EMPTY, XSAssert, XSTimeRange, XSUtils} from '@xs/base';
import {XSCommonUtils, XSSize, XSSizeStr, XSTranslationService} from '@xs/common';

@Component({
    selector: 'xs-time-range',
    template: `
        <span
                [style.font-size]="internalSize.fontSizeStyleValue"
                class="{{ internalSize.fontSizeStyleClass }} {{ styleClass }}"
                [ngClass]="{ 'xs-display-block': displayBlock, 'xs-ellipsis': ellipsis, 'xs-color-secondary': colorSecondary }"
                [innerHTML]="value | xsTrim">
        </span>
    `
})
export class XSTimeRangeComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() timeStyleClass?: string;

    @Input() ellipsis?: boolean;
    @Input() displayBlock?: boolean;

    @Input() data: XSTimeRange;

    @Input() size?: XSSize | XSSizeStr | string;

    @Input() colorSecondary?: boolean;

    value: string;

    internalSize = {
        fontSizeStyleClass: XS_STR_EMPTY,
        fontSizeStyleValue: XS_STR_EMPTY
    };

    constructor(private translationService: XSTranslationService) {
    }

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.update();
    }

    public update(): void {
        this.build();
        this.internalSize = XSCommonUtils.determineFontSize(this.size) as any;
    }

    private build(): void {
        let timeStart = XS_STR_EMPTY;
        let timeEnd = XS_STR_EMPTY;
        if (this.hasStart()) timeStart = `<span class="xs-font-weight-500">${this.data.start!.trim()}</span>`;
        if (this.hasEnd()) timeEnd = `<span class="xs-font-weight-500">${this.data.end!.trim()}</span>`;

        if (this.hasStartEnd()) {
            this.value = this.translationService.translateKey('xs.common.label.betweenStartEnd', {start: timeStart, end: timeEnd});
        } else if (this.hasStart()) {
            this.value = this.translationService.translateKey('xs.common.label.fromStart', {start: timeStart});
        } else if (this.hasEnd()) {
            this.value = this.translationService.translateKey('xs.common.label.noLaterThanEnd', {end: timeEnd});
        }
    }

    private hasStartEnd(): boolean {
        return this.hasStart() && this.hasEnd();
    }

    private hasEnd(): boolean {
        return !XSUtils.isEmpty(this.data.end);
    }

    private hasStart(): boolean {
        return !XSUtils.isEmpty(this.data.start);
    }
}
