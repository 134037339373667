import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOStarterPage} from '@lce/ionic/mfo/app/features/starter/lcei-mfo-starter.page';
import {LCEIMFOAuthenticationGuard} from '@lce/ionic/mfo/app/core/guards/lcei-mfo-authentication.guard';
import {LCEIMFOTerminalRegistrationGuard} from '@lce/ionic/mfo/app/core/guards/lcei-mfo-terminal-registration.guard';

const routes: Routes = [
    {
        path: LCEI_MFO_ROUTING.starter.index, component: LCEIMFOStarterPage, canActivate: [LCEIMFOTerminalRegistrationGuard, LCEIMFOAuthenticationGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LCEIMFOStarterRoutingModule {
}
