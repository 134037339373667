<div class="xsi-data-record {{ styleClass }}">
    <xsi-data-section-header-renderer
            *ngIf="showSectionHeader"
            [data]="sectionHeader!"
            [styleClass]="sectionHeaderStyleClass">
    </xsi-data-section-header-renderer>
    <div class="xsi-data-block xsi-data-block-border-light">
        <xsi-data-field-text
                [codeTest]="data.test"
                [code]="true"
                [label]="{ value: 'lce.core.label.stampTokenCode' }"
                [required]="false"
                [value]="(data.stampTokenCode | xsIsNotEmpty) ? { value: data.stampTokenCode! } : undefined">
        </xsi-data-field-text>
        <xsi-data-field-text
                [label]="{ value: 'lce.core.label.queueNumber' }"
                [required]="false"
                [value]="(data.queueNumber | xsIsNotEmpty) ? { value: '# ' + data.queueNumber! } : undefined">
        </xsi-data-field-text>
        <xsi-data-field-audit-by
                [alignEnd]="true"
                [label]="{ value: 'lce.core.label.assistedBy' }"
                [openUserDetailModalOnClick]="true"
                [required]="false"
                [value]="data.assistedBy">
        </xsi-data-field-audit-by>
    </div>
</div>