<div class="xsi-data-record {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <xsi-data-section-header-renderer
                *ngIf="showSectionHeader"
                [data]="sectionHeader!"
                [style.width]="hasMenu() ? 'calc(100% - 20px)' : undefined"
                [styleClass]="sectionHeaderStyleClass">
        </xsi-data-section-header-renderer>
        <xsi-icon-renderer *ngIf="hasMenu()" [block]="true" [data]="ICON_MENU"></xsi-icon-renderer>
    </div>
    <div class="xsi-data-block xsi-data-block-border-light">
        <ng-container *ngIf="!isRepresentativeCustomer()">
            <xsi-data-field-text
                    [label]="{ value: TR_BASE + 'representativeFullName' }"
                    [value]="{ value: data.representativeFullName }">
            </xsi-data-field-text>

            <xsi-data-field-ivar
                    [label]="{ value: TR_BASE + 'identityDocument' }"
                    [value]="hasIdentityDocument() ? {
                    line1: { value: data.identityDocumentNumber!, size: 'small', fontWeight500: true },
                    line2: {  value: TR_BASE_IDENTITY_DOCUMENT_TYPE + data.identityDocumentType!, size: 'small' },
                    alignEnd: true
                    } : undefined">
            </xsi-data-field-ivar>

            <xsi-data-field-phone-number
                    [label]="{ value: TR_BASE + 'representativePhoneNumber', maxWidth: '150px' }"
                    [value]="{ data: data.representativePhoneNumber, alignEnd: true }">
            </xsi-data-field-phone-number>

            <xsi-data-field-email
                    [label]="{ value: TR_BASE + 'representativeEmail' }"
                    [required]="false"
                    [value]="(data.representativeEmail | xsIsNotEmpty) ? $any({ data: data.representativeEmail, alignEnd: true }) : undefined">
            </xsi-data-field-email>
        </ng-container>

        <ng-container *ngIf="isRepresentativeCustomer()">
            <ion-item lines="full">
                <ion-label class="xs-max-width-100" slot="start">
                    <xsi-text color="secondary" size="intermediate" value="{{ TR_BASE }}representative"></xsi-text>
                </ion-label>
                <ion-label slot="end">
                    <lcei-user-partial
                            [data]="data.representativeCustomer!"
                            [showAvatar]="true"
                            [showCode]="false"
                            avatarPosition="right">
                    </lcei-user-partial>
                </ion-label>
            </ion-item>
        </ng-container>

    </div>
</div>