import {Injectable} from '@angular/core';
import {LCE_CORE_ENDPOINTS, LCETerminalRegistrationRequest, LCETerminalRegistrationResponse, LCETerminalService} from '@lce/core';
import {LCEIHttpClientService} from '@lce/ionic/shared';
import {Observable, of, throwError} from 'rxjs';
import {XS_HTTP_STATUS_TEXT_MAP, XSAssert, XSDeviceIDType, XSDeviceOS, XSUtils} from '@xs/base';
import {tap} from 'rxjs/operators';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {LCEIMFOAppInitializerService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-app-initializer.service';
import {LCEIMFOUtils} from '@lce/ionic/mfo/app/core/utils/lcei-mfo-utils';
import {Router} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';

@Injectable({providedIn: 'root'})
export class LCEIMFOTerminalService extends LCETerminalService {

    constructor(
        protected httpClientService: LCEIHttpClientService,
        private router: Router,
        private storageService: LCEIMFOStorageService,
        private appInitializerService: LCEIMFOAppInitializerService) {
        super(httpClientService);
    }

    public async isTerminalRegistered(): Promise<boolean> {
        const terminalRegistrationResponse = await this.storageService.getStoredTerminalRegistration();
        return LCEIMFOUtils.isTerminalRegistered(terminalRegistrationResponse);
    }

    public register(request: LCETerminalRegistrationRequest): Observable<LCETerminalRegistrationResponse> {
        XSAssert.notEmpty(request, 'request');
        XSAssert.notEmpty(request.securityCode, 'request.securityCode');
        //return super.register(request) // TODO : uncomment when Moh will be done.
        return this.fakeRegister(request)
            .pipe(tap(async response => {
                console.log('HUMMM TAP', response);
                this.storageService.storeTerminalRegistration(response);
                await this.appInitializerService.setUp(response);
                setTimeout(() => this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index), 500);
            }));
    }

    private fakeRegister(request: LCETerminalRegistrationRequest): Observable<LCETerminalRegistrationResponse> {
        console.log('fakeRegister', request);
        if (request.securityCode === '0000') {
            return throwError(this.buildHttpErrorResponse(HttpStatusCode.NotFound));
        }
        if (request.securityCode === '1111') {
            return throwError(this.buildHttpErrorResponse(HttpStatusCode.InternalServerError));
        }
        return of({
            token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJsY2Utc3lzdGVtLXdlYnNpdGUiLCJpc3MiOiJpcm8teHMiLCJleHAiOjIwMzQ5MzkyNTgsInhzUGF5bG9hZCI6eyJ1c2VyUm9sZXMiOiIiLCJ1c2VyTWFpblJvbGUiOiJ3ZWJzaXRlIiwidXNlclBvc2l0aW9uIjoiYWdlbnQiLCJ1c2VyVHlwZSI6ImVtcGxveWVlIiwidXNlclBlcm1pc3Npb25zIjoiIiwidXNlcklEIjoibGNlLXN5c3RlbS13ZWJzaXRlIiwidXNlckNvZGUiOiJMQ0UtU1lTVEVNLVdFQlNJVEUifSwiaWF0IjoxNzE5NTc5MjU4fQ.ef-_cJuAQ_wozvLzvpjEPpRy6dvSp72ms0GuVjh4G7ADaPSDJbhJEH8b_hgbR3mL4X8pK8b4qHL0cDyQLAN6PQ',
            tokenExpirationDate: '2034-06-26T12:54:18.399457Z',
            terminal: {
                name: 'Genesis',
                facility: {id: XSUtils.uuid(), code: 'LCE-FAC-ABJ-YOP-2503', fullName: 'Mairie de Yopougon Selmer'},
                id: XSUtils.uuid(),
                code: 'LCE-TEM-YOP-987654',
                createdOn: new Date().toISOString(),
                deviceID: XSUtils.uuid(),
                deviceIDType: XSDeviceIDType.MAC_ADDRESS,
                deviceOS: XSDeviceOS.WINDOWS
            }
        } as LCETerminalRegistrationResponse);
    }

    private buildHttpErrorResponse(statusCode: HttpStatusCode): HttpErrorResponse {
        return new HttpErrorResponse({
            error: {
                error: {
                    traceID: XSUtils.uuid(),
                    exceptionName: statusCode === HttpStatusCode.NotFound ? 'NoSuchElementException' : 'IllegalProcessException',
                    message: `Something went wrong, the backend doesn't feel well ! :-(`
                }
            },
            status: statusCode,
            statusText: XS_HTTP_STATUS_TEXT_MAP.get(statusCode),
            url: 'http://localhost:8100/' + LCE_CORE_ENDPOINTS.terminals.register
        });
    }
}
