import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LOG, XSHttpClientService, XSLanguage} from '@xs/base';
import {XSCommonEnvironmentService, XSTranslationService} from '@xs/common';
import {LCEHttpClientService} from '@lce/core';

@Injectable()
export abstract class LCEIHttpClientService extends LCEHttpClientService {

    protected constructor(
        protected httpClient: HttpClient,
        protected environmentService: XSCommonEnvironmentService,
        protected translationService: XSTranslationService) {
        super(httpClient, environmentService);
    }
    
    public initialize(apiBaseURL: string, language: XSLanguage): void {
        this.superInitialize(apiBaseURL, language, this.environmentService.getRequestor());

        const hRequestor = this.getHeaders().get(XSHttpClientService.HEADER_REQUESTOR);
        const hLanguage = this.getHeaders().get(XSHttpClientService.HEADER_LANGUAGE);
        LOG().debug(`---| Http Client Service Initialized [requestor: ${hRequestor}, language: ${hLanguage}, apiBaseURL : ${this.apiBaseURL}].`);
        this.emitInitialized();
    }
}
