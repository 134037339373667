import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCommonService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-common.service';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {LCEIMFOServiceActivation} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';

@Component({
    selector: 'lcei-mfo-settings-main-screen-service-activation-page',
    templateUrl: 'lcei-mfo-settings-main-screen-service-activation.page.html',
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsMainScreenServiceActivationPage implements OnInit {

    readonly TR_BASE: string = 'lcei.mfo.features.settings.';

    readonly ICON_MAIN_SCREEN_SERVICE_ACTIVATION: XSIIcon = {
        value: LCEI_MFO_ICON.service,
        size: '25px',
        box: true,
        boxSize: 'medium',
        color: 'primary'
    };

    serviceActivation: LCEIMFOServiceActivation;

    constructor(
        private router: Router,
        private commonService: LCEIMFOCommonService) {
    }

    ngOnInit(): void {
        this.serviceActivation = this.commonService.getServiceActivation();
    }

    public onOptionSwitch(event: any, serviceKey: string): void {
        const checked: boolean = event.detail.checked;
        this.commonService.saveServiceActivation(serviceKey, checked);
        // Not really needed since it's the same memory address, but it feels good to have it here.
        this.serviceActivation = this.commonService.getServiceActivation();
    }

    public close(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
    }
}
