import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {LOG, XSUtils} from '@xs/base';
import {Injectable} from '@angular/core';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOTerminalService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-terminal.service';

@Injectable({providedIn: 'root'})
export class LCEIMFOTerminalRegistrationGuard implements CanActivate {

    private returnUrl: string | undefined;

    constructor(private router: Router, private terminalService: LCEIMFOTerminalService) {
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const currentRoute: string = XSUtils.removeFirstAndLastCharIfSlash(state.url);
        const isTerminalRegistered: boolean = await this.terminalService.isTerminalRegistered();

        if (isTerminalRegistered) {
            if (currentRoute.startsWith(LCEI_MFO_ROUTING.starter.index)) {
                this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
                return false;
            } else if (!XSUtils.isEmpty(this.returnUrl)) {
                LOG().debug('Redirecting toward return URL : ' + this.returnUrl);
                this.router.navigateByUrl(this.returnUrl).then();
                this.returnUrl = undefined;
                return false;
            }
        } else {
            if (!currentRoute.startsWith(LCEI_MFO_ROUTING.starter.index)) {
                this.returnUrl = currentRoute;
                this.router.navigateByUrl(LCEI_MFO_ROUTING.starter.index).then();
                return false;
            }
        }
        return true;
    }
}
