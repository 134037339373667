export const LCEI_DEFAULT_COUNTRY_ISO: string = 'ci';

export const LCEI_STORAGE_KEY_SCHEME: string = 'scheme';

export const LCEI_STORAGE_KEY_LANGUAGE: string = 'language';

export const LCEI_STORAGE_KEY_TOKEN: string = 'token';
export const LCEI_STORAGE_KEY_TOKEN_EXPIRATION: string = 'tokenExpiration';

export const LCEI_STORAGE_KEY_USER: string = 'user';
export const LCEI_STORAGE_KEY_SETTINGS: string = 'settings';

export const LCEI_STORAGE_KEY_INITIAL_DATA: string = 'initialData';
