import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {XSICoreModule} from '@xs/ionic/core';
import {XSIMapModule} from '@xs/ionic/map';
import {XSCountryService} from '@xs/common';
import {XSPaymentBaseModule} from '@iro-xs/xs-payment-base';
import {LCEISharedModule} from '@lce/ionic/shared';
import {LCEIMFOHomeServiceItemsVerticalComponent} from '@lce/ionic/mfo/app/shared/service-items/vertical/lcei-mfo-home-service-items-vertical.component';
import {LCEIMFOHomeServiceItemsHorizontalComponent} from '@lce/ionic/mfo/app/shared/service-items/horizontal/lcei-mfo-home-service-items-horizontal.component';
import {LCEIMFOHomeServiceItemsCardComponent} from '@lce/ionic/mfo/app/shared/service-items/card/lcei-mfo-home-service-items-card.component';
import {LCEIMFOHomeServiceItemsComponent} from '@lce/ionic/mfo/app/shared/service-items/lcei-mfo-home-service-items.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        XSICoreModule,
        XSIMapModule,
        XSPaymentBaseModule,
        LCEISharedModule
    ],
    declarations: [
        LCEIMFOHomeServiceItemsVerticalComponent,
        LCEIMFOHomeServiceItemsHorizontalComponent,
        LCEIMFOHomeServiceItemsCardComponent,
        LCEIMFOHomeServiceItemsComponent
    ],
    exports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        XSICoreModule,
        XSIMapModule,
        XSPaymentBaseModule,
        LCEISharedModule,
        // --- *** ---
        LCEIMFOHomeServiceItemsVerticalComponent,
        LCEIMFOHomeServiceItemsHorizontalComponent,
        LCEIMFOHomeServiceItemsCardComponent,
        LCEIMFOHomeServiceItemsComponent
    ],
    providers: [XSCountryService]
})
export class LCEIMFOSharedModule {
}
