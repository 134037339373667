import {Component, Input, OnInit} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSPaymentCreditCardType, XSPaymentMethodType, XSPaymentType} from '@xs/payment/base';

@Component({selector: 'xsi-payment-charge-partial', templateUrl: 'xsi-payment-charge-partial.component.html'})
export class XSIPaymentChargePartialComponent implements OnInit {

    @Input() styleClass?: string;

    @Input() type: XSPaymentType;
    @Input() methodType: XSPaymentMethodType;
    @Input() creditCardType?: XSPaymentCreditCardType;
    @Input() creditCardLast4Digits?: string;

    @Input() showAvatar?: boolean;
    @Input() avatarPosition?: 'left' | 'right';

    @Input() alignEnd?: boolean;

    ngOnInit(): void {
        XSAssert.notEmpty(this.type, 'type');
        XSAssert.notEmpty(this.methodType, 'methodType');

        if (XSUtils.isNull(this.showAvatar)) this.showAvatar = true;
        if (this.isAvatarPosition('right') && XSUtils.isNull(this.alignEnd)) this.alignEnd = true;
    }

    public isAvatarPosition(position: 'left' | 'right'): boolean {
        return this.showAvatar === true && this.avatarPosition === position;
    }

    public isCreditCardType(): boolean {
        return this.type === 'creditCard';
    }
}