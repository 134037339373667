<div class="xsi-data-record {{ styleClass }}">
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <xsi-data-section-header-renderer
                *ngIf="showSectionHeader"
                [data]="sectionHeader!"
                [style.width]="hasMenu() ? 'calc(100% - 20px)' : undefined"
                [styleClass]="sectionHeaderStyleClass">
        </xsi-data-section-header-renderer>
        <xsi-icon-renderer *ngIf="hasMenu()" [block]="true" [data]="ICON_MENU"></xsi-icon-renderer>
    </div>
    <div class="xsi-data-block xsi-data-block-border-light">
        <xsi-data-field-text
                [codeTest]="data.test"
                [code]="true"
                [label]="{ value: 'lce.core.label.orderNumber' }"
                [value]="{ value: data.orderNumber }">
        </xsi-data-field-text>
        <xsi-data-field-text
                [label]="{ value: 'lce.core.label.orderOrigin', maxWidth: '150px' }"
                [value]="{ value: data.source, trBase: TR_BASE_REQUESTOR_TYPE }">
        </xsi-data-field-text>
        <xsi-data-field-audit-on-by
                [alignEnd]="true"
                [label]="{ value: 'xsi.core.audit.createdOn', timeAgo: data.createdOn }"
                [onTimeAgo]="false"
                [value]="{ on: data.createdOn, by: data.createdBy }">
        </xsi-data-field-audit-on-by>

        <xsi-data-field-text
                [label]="{ value: 'lce.core.label.numberOfCopies' }"
                [value]="{ value: data.numberOfCopies | xsToString }"
                valueStyleClass="lcei-certificate-order-number-of-copies">
        </xsi-data-field-text>

        <ion-item lines="full">
            <ion-label class="xs-max-width-50" slot="start">
                <xsi-text color="secondary" size="intermediate" value="lce.core.label.status"></xsi-text>
            </ion-label>
            <ion-label slot="end">
                <lcei-certificate-order-status [chip]="true" [data]="data.status"></lcei-certificate-order-status>
            </ion-label>
        </ion-item>

        <xsi-data-field-audit-on-by
                [alignEnd]="true"
                [label]="{ value: getStatusUpdatedOnLabel(), timeAgo: statusAudit.on }"
                [onTimeAgo]="false"
                [value]="statusAudit">
        </xsi-data-field-audit-on-by>

        <ion-item lines="full">
            <ion-label class="xs-max-width-100" slot="start">
                <xsi-text color="secondary" size="intermediate" value="lce.core.label.customer"></xsi-text>
            </ion-label>
            <ion-label slot="end">
                <xsi-text *ngIf="!hasCustomer()" [fontWeight500]="true" [value]="TR_BASE + 'customerNotProvided'" size="intermediate"></xsi-text>
                <lcei-user-partial
                        *ngIf="hasCustomer()"
                        [data]="data.customer!"
                        [openDetailOnClick]="true"
                        [showAvatar]="true"
                        avatarPosition="right">
                </lcei-user-partial>
            </ion-label>
        </ion-item>

        <ion-item lines="full">
            <ion-label class="xs-max-width-70" slot="start">
                <xsi-text color="secondary" size="intermediate" value="lce.core.label.destination"></xsi-text>
            </ion-label>
            <ion-label slot="end">
                <lcei-certificate-order-delivery-destination
                        [data]="data.delivery.destination"
                        [showIcon]="true"
                        avatarPosition="right">
                </lcei-certificate-order-delivery-destination>
            </ion-label>
        </ion-item>
    </div>
</div>