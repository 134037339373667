import {Injectable} from '@angular/core';
import {XSINetworkService} from '@xs/ionic/base';
import {XSIContextService, XSIGeolocationService} from '@xs/ionic/core';
import {LCEBaseSettings} from '@iro-xs/lce-core/domain/lce-base-settings';

@Injectable()
export abstract class LCEIContextService extends XSIContextService {

    protected constructor(protected geolocationService: XSIGeolocationService, protected networkService: XSINetworkService) {
        super(geolocationService, networkService);
    }

    public abstract getSettings(): LCEBaseSettings ;

    // ----------------------------------------------------------------------------------------------------------
    // === *** ===
    // ----------------------------------------------------------------------------------------------------------
}
