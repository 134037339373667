import {NgModule} from '@angular/core';
import {LCEIMFOSharedModule} from '@lce/ionic/mfo/app/shared/lcei-mfo-shared.module';
import {LCEIMFOCertificateOrderModuleService} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order-module.service';
import {LCEIMFOCertificateOrderCreatePage} from '@lce/ionic/mfo/app/features/certificate-order/create/lcei-mfo-certificate-order-create.page';
import {LCEIMFOCertificateOrderSearchPage} from '@lce/ionic/mfo/app/features/certificate-order/search/lcei-mfo-certificate-order-search.page';
import {LCEIMFOCertificateOrdersPage} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-orders.page';
import {LCEIMFOCertificateRoutingModule} from '@lce/ionic/mfo/app/features/certificate-order/lcei-mfo-certificate-order-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LCEIMFOCertificateOrderSearchHistoryItemComponent} from '@lce/ionic/mfo/app/features/certificate-order/search/lcei-mfo-certificate-order-search-history-item.component';

@NgModule({
    imports: [
        LCEIMFOSharedModule,
        ReactiveFormsModule,
        LCEIMFOCertificateRoutingModule
    ],
    declarations: [
        LCEIMFOCertificateOrderCreatePage,
        LCEIMFOCertificateOrderSearchPage,
        LCEIMFOCertificateOrdersPage,
        LCEIMFOCertificateOrderSearchHistoryItemComponent
    ],
    exports: [
        LCEIMFOCertificateOrderCreatePage
    ],
    providers: [LCEIMFOCertificateOrderModuleService]
})
export class LCEIMFOCertificateOrderModule {
}
