import {NgModule} from '@angular/core';
import {LCEIMFOSharedModule} from '@lce/ionic/mfo/app/shared/lcei-mfo-shared.module';
import {LCEIMFOHomePage} from '@lce/ionic/mfo/app/features/home/lcei-mfo-home.page';

@NgModule({
    imports: [
        LCEIMFOSharedModule
    ],
    declarations: [
        LCEIMFOHomePage
    ],
    exports: []
})
export class LCEIMFOHomeModule {
}
