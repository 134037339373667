import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {XSTranslationService} from '@xs/common';
import {Router} from '@angular/router';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {LCEIMFOCommonService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-common.service';

@Component({
    selector: 'lcei-mfo-certificate-orders-page',
    templateUrl: 'lcei-mfo-certificate-orders.page.html',
    styleUrls: ['lcei-mfo-certificate-orders.page.scss']
})
export class LCEIMFOCertificateOrdersPage implements OnInit, AfterViewInit {

    readonly ICON_SETTINGS: string = LCEI_MFO_ICON.settings;
    readonly ICON_SCAN: string = LCEI_MFO_ICON.settings;

    readonly TR_BASE: string = 'lcei.mfo.features.certificate.order.';

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private translationService: XSTranslationService,
        private commonService: LCEIMFOCommonService) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
    }
}
