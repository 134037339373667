<div class="xsi-data-record xsi-payment-charge-detail {{ styleClass }}">
    <xsi-data-section-header-renderer
            *ngIf="showSectionHeader"
            [data]="sectionHeader!"
            [styleClass]="sectionHeaderStyleClass">
    </xsi-data-section-header-renderer>

    <div class="xsi-data-block xsi-data-block-border-light">

        <ion-item lines="full">
            <ion-label class="xs-max-width-100" slot="start">
                <xsi-text color="secondary" size="intermediate" value="xs.payment.label.paymentType"></xsi-text>
            </ion-label>
            <ion-label slot="end">
                <xsi-payment-charge-partial
                        [creditCardLast4Digits]="data.last4Digits"
                        [creditCardType]="data.cardType"
                        [methodType]="data.methodType"
                        [showAvatar]="true"
                        [type]="data.type"
                        avatarPosition="right">
                </xsi-payment-charge-partial>
            </ion-label>
        </ion-item>

        <xsi-data-field-text
                [codeTest]="data.test"
                [code]="true"
                [label]="{ value: 'xs.payment.label.transactionID' }"
                [value]="{ value: data.transactionID }">
        </xsi-data-field-text>

        <xsi-data-field-calendar
                [label]="{ value: 'xs.payment.label.transactionDate' }"
                [value]="{ data: data.transactionDate!, timeAgo: true, alignEnd: true }">
        </xsi-data-field-calendar>

        <xsi-data-field-text
                [label]="{ value: 'xs.payment.label.status' }"
                [value]="{ value: data.status, trBase: TR_BASE_CHARGE_STATUS, color: 'primary' }">
        </xsi-data-field-text>

        <xsi-data-field-text
                [label]="{ value: 'xs.payment.label.chargedAmount' }"
                [value]="{ value: data.chargedAmount | xsAmount, size: 'default' }">
        </xsi-data-field-text>
    </div>
</div>