<ion-header class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="close()"
            [cancelButton]="true"
            [title]="TR_BASE + 'mainScreenServiceActivation'"
            backButtonDefaultHref="/settings"
            cancelButtonLabel="xsi.core.label.close"
            titlePosition="bottom-center"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center xs-scrollbar-hide">
        <div class="lcei-mfo-page xs-flex-1 xs-pt-30">

            <xsi-icon-renderer
                    [data]="ICON_MAIN_SCREEN_SERVICE_ACTIVATION"
                    class="xs-flex-row-center xs-width-full xs-mb-50"
                    styleClass="xs-filter-brightness-98">
            </xsi-icon-renderer>

            <ion-card mode="ios">
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar [line1]="{ value:'lcei.mfo.features.home.service.certificateOrderCreate.line1' }"></xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'certificateOrderCreate')"
                            [checked]="serviceActivation?.certificateOrderCreate"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar [line1]="{ value:'lcei.mfo.features.home.service.birthDeclaration.line1' }"></xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'birthDeclaration')"
                            [checked]="serviceActivation?.birthDeclaration"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar [line1]="{ value:'lcei.mfo.features.home.service.weddingBooking.line1' }"></xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'weddingBooking')"
                            [checked]="serviceActivation?.weddingBooking"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <xsi-ivar [line1]="{ value:'lcei.mfo.features.home.service.information.line1' }"></xsi-ivar>
                    </ion-label>
                    <ion-toggle
                            (ionChange)="onOptionSwitch($event, 'information')"
                            [checked]="serviceActivation?.information"
                            class="xs-pt-14-imp xs-align-self-start"
                            mode="md">
                    </ion-toggle>
                </ion-item>
            </ion-card>


        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>

