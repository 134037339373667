import {Injectable} from '@angular/core';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEIMFOCertificateOrderCreateOptions} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {XSAssert, XSUtils} from '@xs/base';

@Injectable({providedIn: 'root'})
export class LCEIMFOCertificateOrderCreateOptionsService {

    private createOptions: LCEIMFOCertificateOrderCreateOptions;

    constructor(private storageService: LCEIMFOStorageService) {
    }

    public reset(): LCEIMFOCertificateOrderCreateOptions {
        this.createOptions = {};
        this.handleDefaults();
        this.storageService.storeCertificateOrderCreateOptions(this.createOptions);
        return this.createOptions;
    }

    public saveCreateOptions(key: string, value: any): void {
        XSAssert.notEmpty(key, 'key');
        XSAssert.notEmpty(value, 'value');
        XSUtils.setNestedPropertyValue(this.createOptions, key, value);
        this.storageService.storeCertificateOrderCreateOptions(this.createOptions);
    }

    public getCreateOptions(): LCEIMFOCertificateOrderCreateOptions {
        return this.createOptions;
    }

    public async initialize(): Promise<void> {
        const storedCertificateOrder = await this.storageService.getStoredCertificateOrder();
        this.createOptions = storedCertificateOrder?.createOptions;
        this.handleDefaults();
        this.storageService.storeCertificateOrderCreateOptions(this.createOptions);
    }

    private handleDefaults(): void {
        if (XSUtils.isNull(this.createOptions)) this.createOptions = {};
        if (XSUtils.isNull(this.createOptions.showTitleIcon)) this.createOptions.showTitleIcon = false;
        if (XSUtils.isNull(this.createOptions.inputBorder)) this.createOptions.inputBorder = true;
        if (XSUtils.isNull(this.createOptions.showSectionDescription)) this.createOptions.showSectionDescription = true;
        if (XSUtils.isNull(this.createOptions.useCertificateIssueFullDate)) this.createOptions.useCertificateIssueFullDate = false;

        if (XSUtils.isNull(this.createOptions.confirm)) this.createOptions.confirm = {};
        if (XSUtils.isNull(this.createOptions.confirm.beforeCreate)) this.createOptions.confirm.beforeCreate = true;
        if (XSUtils.isNull(this.createOptions.confirm.beforeReset)) this.createOptions.confirm.beforeReset = true;
        if (XSUtils.isNull(this.createOptions.confirm.beforeClose)) this.createOptions.confirm.beforeClose = true;

        if (XSUtils.isNull(this.createOptions.canUse)) this.createOptions.canUse = {};
        if (XSUtils.isNull(this.createOptions.canUse.customerPrimaryPhoneNumber)) this.createOptions.canUse.customerPrimaryPhoneNumber = true;
        if (XSUtils.isNull(this.createOptions.canUse.stampTokenCode)) this.createOptions.canUse.stampTokenCode = true;
        if (XSUtils.isNull(this.createOptions.canUse.queueNumber)) this.createOptions.canUse.queueNumber = true;

        if (XSUtils.isNull(this.createOptions.required)) this.createOptions.required = {};
        if (XSUtils.isNull(this.createOptions.required.customerPrimaryPhoneNumber)) this.createOptions.required.customerPrimaryPhoneNumber = false;
        if (XSUtils.isNull(this.createOptions.required.stampTokenCode)) this.createOptions.required.stampTokenCode = false;
        if (XSUtils.isNull(this.createOptions.required.queueNumber)) this.createOptions.required.queueNumber = false;

        if (XSUtils.isNull(this.createOptions.description)) this.createOptions.description = {};
        if (XSUtils.isNull(this.createOptions.description.customerPrimaryPhoneNumber)) this.createOptions.description.customerPrimaryPhoneNumber = true;
        if (XSUtils.isNull(this.createOptions.description.stampTokenCode)) this.createOptions.description.stampTokenCode = true;
        if (XSUtils.isNull(this.createOptions.description.queueNumber)) this.createOptions.description.queueNumber = true;
        if (XSUtils.isNull(this.createOptions.description.certificateReferenceNumber)) this.createOptions.description.certificateReferenceNumber = true;
        if (XSUtils.isNull(this.createOptions.description.certificateIssueDate)) this.createOptions.description.certificateIssueDate = true;
    }
}
