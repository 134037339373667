<ion-header class="xsi-ion-header">
    <xsi-page-header
            [title]="TR_BASE + 'title'"
            backButtonDefaultHref="/home"
            titleSize="extra-large">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-settings-page">

                <!-- === Terminal Name === -->
                <div class="xs-flex-row xs-align-items-center xs-justify-content-end xs-width-full xs-p-15 xs-mb-35">
                    <xsi-text
                            *ngIf="terminalName | xsIsNotEmpty"
                            [fontWeight500]="true"
                            [trParams]="{name: terminalName}"
                            color="secondary"
                            size="intermediate"
                            value="{{ TR_BASE }}terminalName">
                    </xsi-text>
                </div>

                <!-- === User === -->
                <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between xs-width-full xs-mtb-10">
                    <div class="xs-flex-row xs-align-items-center xs-overflow-hidden">
                        <xsi-icon [box]="true" [value]="ICON_USER" boxSize="60px" color="primary" size="40px" styleClass="xs-mr-10 xs-filter-brightness-97"></xsi-icon>
                        <div class="xs-flex-column xs-width-full xs-overflow-hidden">
                            <xsi-text
                                    [ellipsis]="true"
                                    [fontWeight600]="true"
                                    [value]="user.name | xsPersonName"
                                    color="primary"
                                    size="medium">
                            </xsi-text>
                            <xsi-text
                                    *ngIf="user.position | xsIsNotEmpty"
                                    [ellipsis]="true"
                                    [fontWeight500]="false"
                                    [value]="user.position | lceUserPosition: user.type"
                                    color="secondary"
                                    size="intermediate"
                                    styleClass="xs-mt-1">
                            </xsi-text>
                            <xsi-text
                                    *ngIf="showUserCode"
                                    [ellipsis]="true"
                                    [fontWeight500]="false"
                                    [uppercase]="true"
                                    [value]="user.code"
                                    color="secondary"
                                    size="small"
                                    styleClass="xs-mt-2">
                            </xsi-text>
                        </div>
                    </div>
                    <ion-icon
                            (click)="logout()"
                            class="xs-clickable"
                            color="primary"
                            name="log-out-outline"
                            size="large">
                    </ion-icon>
                </div>

                <!-- === Language & Dark Mode === -->
                <ion-card class="xs-mt-50" mode="ios">
                    <ion-item lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_LANGUAGE, box: true, boxSize: 'small', size: '16px', color: 'primary'}"
                                    [line1]="{ value:TR_BASE + 'english' }"
                                    alignItems="center"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                        <ion-toggle (ionChange)="onLanguageChange($event)" [checked]="languageState" mode="md"></ion-toggle>
                    </ion-item>
                    <ion-item lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_DARK_MODE, box: true, boxSize: 'small', color: 'primary', size: '16px', styleClass: 'xs-mt-2'}"
                                    [line1]="{ value:TR_BASE + 'darkMode' }"
                                    [line2]="{ value:TR_BASE + 'darkModeDescription', size: 'small', color: 'secondary', styleClass: 'xs-mt-0-imp' }"
                                    alignItems="start"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                        <ion-toggle
                                (ionChange)="onSchemeChange($event)"
                                [checked]="darkModeState"
                                class="xs-pt-14-imp xs-align-self-start"
                                mode="md">
                        </ion-toggle>
                    </ion-item>
                </ion-card>

                <!-- === Last Certificate Order === -->
                <div class="xs-flex-column xs-position-relative xs-width-full">
                    <ion-card mode="ios">
                        <ion-item (click)="openLastCertificateOrderRecordPage($event)" [detail]="hasLastCertificateOrder()" class="xsi-ion-item-clickable">
                            <ion-label>
                                <div class="xs-flex-row xs-align-items-baseline xs-justify-content-space-between xs-line-height-1-25">
                                    <xsi-ivar
                                            [avatar]="ICON_CERTIFICATE_ORDER_LAST"
                                            [ellipsis]="true"
                                            [line1]="getLastCertificateOrderLine1()"
                                            [line2]="getLastCertificateOrderLine2()"
                                            class="xs-overflow-hidden"
                                            gap="15px">
                                    </xsi-ivar>
                                    <xs-time-ago
                                            *ngIf="hasLastCertificateOrder()"
                                            [givenDate]="lastCertificateOrder.createdOn"
                                            [live]="true"
                                            [shortMode]="true"
                                            styleClass="xs-text-align-right xs-text-block-small xsi-color-secondary xs-min-width-75">
                                    </xs-time-ago>
                                </div>
                            </ion-label>
                        </ion-item>
                    </ion-card>
                    <xsi-text
                            *ngIf="hasLastCertificateOrder()"
                            [ellipsis]="true"
                            [fontWeight500]="true"
                            [trParams]="{on: lastCertificateOrder.createdOn | xsDate: 'xsMediumLongDateTime'}"
                            [value]="TR_BASE + 'lastCertificateOrderReceivedOn'"
                            class="xs-flex-row xs-justify-content-end xs-text-align-right xs-plr-10 xs-width-full xs-overflow-hidden xs-position-absolute xs-left-0 xs-bottom-6"
                            color="secondary"
                            size="small">
                    </xsi-text>
                </div>

                <!-- === Custom UI & Forms === -->
                <ion-card mode="ios">
                    <!--                    <ion-item lines="inset">-->
                    <!--                        <ion-label class="ion-text-wrap">-->
                    <!--                            <xsi-ivar-->
                    <!--                                    [line1]="{ value:TR_BASE + 'showSectionDescription.label' }"-->
                    <!--                                    [line2]="{ value:TR_BASE + 'showSectionDescription.description', size: 'small', color: 'secondary' }">-->
                    <!--                            </xsi-ivar>-->
                    <!--                        </ion-label>-->
                    <!--                        <ion-toggle-->
                    <!--                                (ionChange)="onOptionSwitch($event, 'showSectionDescription')"-->
                    <!--                                [checked]="createOptions?.showSectionDescription"-->
                    <!--                                class="xs-pt-14-imp xs-align-self-start"-->
                    <!--                                mode="md">-->
                    <!--                        </ion-toggle>-->
                    <!--                    </ion-item>-->
                    <ion-item [routerLink]="route(ROUTING.mainScreen.serviceArrangement)" class="xsi-ion-item-clickable" detail="true" lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_MAIN_SCREEN_SERVICE_ARRANGEMENT, box: true, boxSize: 'small', color: 'primary', size: '16px', styleClass: 'xs-mt-2'}"
                                    [line1]="{ value:TR_BASE + 'mainScreenServiceArrangement' }"
                                    [line2]="{ value:TR_BASE + 'mainScreenServiceArrangementDescription', size: 'small', color: 'secondary', styleClass: 'xs-mt-0-imp' }"
                                    alignItems="start"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                    </ion-item>
                    <ion-item [routerLink]="route(ROUTING.mainScreen.serviceActivation)" class="xsi-ion-item-clickable" detail="true" lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_MAIN_SCREEN_SERVICE_ACTIVATION, box: true, boxSize: 'small', color: 'primary', size: '16px', styleClass: 'xs-mt-2'}"
                                    [line1]="{ value:TR_BASE + 'mainScreenServiceActivation' }"
                                    [line2]="{ value:TR_BASE + 'mainScreenServiceActivationDescription', size: 'small', color: 'secondary', styleClass: 'xs-mt-0-imp' }"
                                    alignItems="start"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                    </ion-item>
                </ion-card>

                <!-- === Certificate Order Create === -->
                <ion-card mode="ios">
                    <ion-item [routerLink]="route(ROUTING.certificate.order.create)" class="xsi-ion-item-clickable" detail="true" lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_CERTIFICATE_ORDER, box: true, boxSize: 'small', color: 'primary', styleClass: 'lcei-certificate-icon-size-18'}"
                                    [line1]="{ value:TR_BASE + 'certificateOrderForm.title' }"
                                    alignItems="center"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                    </ion-item>
                </ion-card>

                <!-- === Advanced Configuration === -->
                <ion-card mode="ios">
                    <ion-item [routerLink]="route(ROUTING.advancedConfiguration)" class="xsi-ion-item-clickable" detail="true" lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_ADVANCED_CONFIGURATION, box: true, boxSize: 'small', size: '15px', color: 'primary'}"
                                    [line1]="{ value:TR_BASE + 'advancedConfiguration' }"
                                    alignItems="center"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                    </ion-item>
                </ion-card>

                <!-- === Developer Mode === -->
                <ion-card *ngIf="developerMode" mode="ios">
                    <ion-item [routerLink]="route(ROUTING.developerMode)" class="xsi-ion-item-clickable" detail="true">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar
                                    [avatar]="{value: ICON_DEVELOPER_MODE, box: true, boxSize: 'small', color: 'primary', size: '16px', styleClass: 'xs-mt-2'}"
                                    [line1]="{ value:TR_BASE + 'developerMode' }"
                                    [line2]="{ value:TR_BASE + 'developerModeDescription', size: 'small', color: 'secondary', styleClass: 'xs-mt-0-imp' }"
                                    alignItems="start"
                                    gap="15px">
                            </xsi-ivar>
                        </ion-label>
                    </ion-item>
                </ion-card>
            </div>

            <div class="xs-flex-column-center xs-mt-25">
                <div class="xs-text-block-extra-small xsi-color-secondary xs-mb-1">DEV Environment</div>
                <span class="xs-text-block-extra-small xsi-color-primary xs-font-weight-600">
				{{ 'lcei.mfo.main.appName' | translate }} {{ 'lcei.mfo.main.appSubName' | translate }}
			</span>
                <div class="xs-text-block-extra-small xsi-color-primary">version 0.0.1</div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>
