<ion-header class="xsi-ion-header">
    <lcei-app-name type="image"></lcei-app-name>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-starter-page">
                <xsi-text-block
                        [center]="true"
                        [icon]="{ value: ICON_SECURITY, size: '40px', box: true, boxSize: 'large', color: 'primary' }"
                        [line1FontWeight600]="true"
                        [line2FontWeight500]="true"
                        [line3FontWeight500]="true"
                        line1="{{TR_BASE}}terminalSecurityCodeMessage.line1"
                        line1Size="large"
                        line2="{{TR_BASE}}terminalSecurityCodeMessage.line2"
                        line2Size="medium"
                        line3="{{TR_BASE}}terminalSecurityCodeMessage.line3"
                        line3Color="secondary"
                        line3Size="default"
                        styleClass="xs-plr-20">
                </xsi-text-block>
                <xsi-input-digit
                    #inputDigit
                    (clickEvent)="onInputDigitClick()"
                    (onChanged)="onOTPChanged($event)"
                    (onCompleted)="onOTPCompleted()"
                    [(value)]="otpValue"
                    [autoFocus]="true"
                    [disabled]="loading"
                    [valid]="otpValid"
                    class="xs-width-full xs-max-width-300 xs-mt-25 xs-display-block xs-height-65">
                </xsi-input-digit>
                <div class="xs-display-block xs-position-relative xs-width-full xs-height-50 xs-plr-15">
                    <xs-loader [loaderId]="LOADER_ID" [loaderSize]="20" class="xs-absolute-center-vh"></xs-loader>
                    <xsi-error-inline
                            *ngIf="hasError() && !loading"
                            [error]="error"
                            [message]="errorMessage"
                            [showIcon]="true"
                            class="xs-absolute-center-vh xs-plr-inherit"
                            styleClass="xs-justify-content-center xs-text-align-center">
                    </xsi-error-inline>
                </div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>
