<div [ngClass]="{ 'xs-disabled': disabled }" class="xs-flex-column-center xs-width-full {{ styleClass }}">
    <ion-item class="xsi-ion-item-no-spacing xs-width-full xs-border-radius-default xsi-ion-item-box-shadow {{ ionItemStyleClass }}">
        <ion-label [ngClass]="{ 'lcei-certificate-order-partial-stripe': stripe }" class="ion-text-wrap xs-p-15 xs-m-0-imp">
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <div class="xs-flex-row xs-align-items-center xs-width-full">
                <lcei-certificate-avatar
                        [data]="data.certificate.type"
                        [showRequestorIcon]="showRequestorIcon && !full"
                        styleClass="xs-mr-12">
                </lcei-certificate-avatar>
                <div class="xs-flex-row xs-flex-1 xs-align-items-center xs-justify-content-space-between xs-overflow-hidden">
                    <div class="xs-flex-column xs-overflow-hidden">
                        <xsi-text
                                (clickEvent)="onClick($event)"
                                [applyClickableStyle]="false"
                                [ellipsis]="true"
                                [fontWeight500]="true"
                                [value]="TR_BASE_CERTIFICATE_TYPE_MEDIUM + data.certificate.type"
                                color="primary"
                                size="default">
                        </xsi-text>
                        <xsi-text
                                (clickEvent)="onClick($event)"
                                [ellipsis]="true"
                                [fontWeight500]="true"
                                [uppercase]="true"
                                [value]="data.orderNumber"
                                color="primary"
                                size="small">
                        </xsi-text>
                    </div>
                    <div class="xs-flex-column xs-align-items-end xs-ml-10">
                        <lcei-certificate-order-status [chip]="true" [data]="data.status"></lcei-certificate-order-status>
                        <xs-time-ago
                                [givenDate]="data.createdOn"
                                [live]="true"
                                [shortMode]="false"
                                prefix="lcei.shared.label.certificateOrderSentOn"
                                styleClass="xs-text-block-small xsi-color-secondary xs-text-align-right xs-min-width-75 xs-mt-1 xs-mr-10">
                        </xs-time-ago>
                    </div>
                </div>
            </div>
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <!-- -------------------------------------------------------------------------------------------------------------------------------------------- -->
            <div *ngIf="full" class="xs-flex-row xs-align-items-start xs-width-full xs-mt-20">
                <lcei-certificate-order-number-of-copies [data]="data.numberOfCopies" [showLabel]="true" styleClass="xs-mr-12">
                </lcei-certificate-order-number-of-copies>
                <div class="xs-flex-row xs-flex-1 xs-align-items-center xs-justify-content-space-between xs-overflow-hidden xs-mt-2">
                    <div class="xs-flex-column xs-overflow-hidden">
                        <xsi-text
                                [ellipsis]="true"
                                [fontWeight500]="true"
                                [value]="data.facility.fullName"
                                color="secondary"
                                size="intermediate">
                        </xsi-text>
                        <xsi-text
                                [ellipsis]="true"
                                [value]="data.facility.municipality.district.fullName"
                                color="secondary"
                                size="small">
                        </xsi-text>
                    </div>
                    <div class="xs-flex-column xs-align-items-end xs-text-align-right xs-ml-10 xs-pr-10">
                        <xsi-text
                                [ellipsis]="true"
                                [fontWeight500]="true"
                                [value]="TR_BASE_DELIVERY_DESTINATION_TYPE + data.deliveryDestinationType"
                                color="secondary"
                                size="intermediate">
                        </xsi-text>
                        <xsi-text
                                [ellipsis]="true"
                                color="secondary"
                                size="small"
                                value="lce.core.label.delivery">
                        </xsi-text>
                    </div>
                </div>
            </div>
        </ion-label>
    </ion-item>
</div>