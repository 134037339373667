import {NgModule} from '@angular/core';
import {LCEIMFOStarterRoutingModule} from '@lce/ionic/mfo/app/features/starter/lcei-mfo-starter-routing.module';
import {LCEIMFOStarterPage} from '@lce/ionic/mfo/app/features/starter/lcei-mfo-starter.page';
import {LCEIMFOSharedModule} from '@lce/ionic/mfo/app/shared/lcei-mfo-shared.module';

@NgModule({
    imports: [
        LCEIMFOSharedModule,
        LCEIMFOStarterRoutingModule
    ],
    declarations: [
        LCEIMFOStarterPage
    ],
    exports: [
        LCEIMFOStarterPage
    ],
    providers: []
})
export class LCEIMFOStarterModule {
}
