import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {LCEIMFOAuthenticationService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-authentication.service';

@Injectable({providedIn: 'root'})
export class LCEIMFOAuthenticationGuard implements CanActivate {

    constructor(private router: Router, private authenticateService: LCEIMFOAuthenticationService) {
    }

    public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isAuthenticated: boolean = await this.authenticateService.isAuthenticated();
        if (!isAuthenticated) {
            this.authenticateService.openPINCodeAuthenticationModal();
            return false;
        }
        return true;
    }
}
