<ion-header class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="close()"
            [cancelButton]="true"
            [title]="TR_BASE + 'developerMode'"
            backButtonDefaultHref="/settings"
            cancelButtonLabel="xsi.core.label.close"
            titlePosition="bottom-center"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page xs-pt-30">

            <xsi-icon-renderer
                    [data]="ICON_DEVELOPER_MODE"
                    class="xs-flex-row-center xs-width-full xs-mb-50"
                    styleClass="xs-filter-brightness-98">
            </xsi-icon-renderer>

            <div class="xs-flex-column xs-width-full xs-plr-25">
                <xsi-button-text (clickEvent)="disableCaching()" label="Disable caching" size="default"></xsi-button-text>
                <xsi-text
                        color="secondary"
                        size="small"
                        styleClass="xs-mt-2"
                        value="Disable the caching for the whole application. All the request won't be cached anymore and will reach the backend API.">
                </xsi-text>
            </div>

            <div class="xs-flex-column xs-width-full xs-mt-50 xs-plr-25 xs-mb-15">
                <xsi-text [fontWeight600]="true" size="default" value="Configuration"></xsi-text>
            </div>
            <div class="xsi-data-block xsi-data-block-border-light">
                <ion-item class="xsi-padding-start-0" lines="full">
                    <ion-label class="xs-ml-0 xs-max-width-125" slot="start">
                        <xsi-text color="secondary" size="intermediate" value="Environment"></xsi-text>
                    </ion-label>
                    <ion-label class="xs-mr-0" slot="end">
                        <xsi-input-select
                                [control]="environmentControl"
                                [multiple]="false"
                                [options]="environmentOptions"
                                class="xs-display-block"
                                interfaceType="action-sheet"
                                styleClass="xs-border-bottom-none-imp">
                        </xsi-input-select>
                    </ion-label>
                </ion-item>
                <ion-item class="xsi-padding-start-0" lines="full">
                    <ion-label class="xs-ml-0 xs-max-width-125" slot="start">
                        <div class="xs-flex-row xs-align-items-center">
                            <xsi-text color="secondary" size="intermediate" styleClass="xs-mr-10" value="API Base URL"></xsi-text>
                            <xs-icon (clickEvent)="editAPIBaseURL()" [value]="ICON_EDIT" color="primary" size="intermediate" styleClass="xs-display-block">
                            </xs-icon>
                        </div>
                    </ion-label>
                    <ion-label class="xs-mr-0" slot="end">
                        <xsi-text [fontWeight500]="true" [value]="currentAPIBaseURL" size="intermediate"></xsi-text>
                    </ion-label>
                </ion-item>
                <ion-item class="xsi-padding-start-0" lines="full">
                    <ion-label class="xs-ml-0 xs-max-width-125" slot="start">
                        <xsi-text color="secondary" size="intermediate" value="Version"></xsi-text>
                    </ion-label>
                    <ion-label class="xs-mr-0" slot="end">
                        <xsi-text [fontWeight500]="true" [value]="appVersion" size="default"></xsi-text>
                    </ion-label>
                </ion-item>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>

