import {Injectable} from '@angular/core';
import {globalCacheBusterNotifier} from 'ts-cacheable';
import {Subject} from 'rxjs';

@Injectable({providedIn: 'root'})
export class LCEIMFOCacheService {

    private clearGlobalCacheSubject = new Subject<void>();
    onClearGlobalCache = this.clearGlobalCacheSubject.asObservable();

    public disableCaching(): void {
        // TODO
        console.log('--- disableCaching not implemented yet !');
    }

    public clearGlobalCache(): void {
        globalCacheBusterNotifier.next();
        this.clearGlobalCacheSubject.next();
    }
}
