import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCommonService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-common.service';
import {XSSize} from '@iro-xs/xs-common';
import {LCEIMFOHomeServiceItem, LCEIMFOServiceArrangement} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';

@Component({selector: 'lcei-mfo-home-page', templateUrl: 'lcei-mfo-home.page.html', styleUrls: ['lcei-mfo-home.page.scss']})
export class LCEIMFOHomePage implements OnInit, AfterViewInit {

    readonly ICON_SEARCH: string = LCEI_MFO_ICON.search;
    readonly ICON_SCAN: string = LCEI_MFO_ICON.scan;

    readonly TR_BASE: string = 'lcei.mfo.features.home.';

    showFacilityName: boolean = false;
    facilityName: string = 'Marie de Yopougon Selmer';

    headerIconSize: XSSize = XSSize.MEDIUM;

    private readonly PAGE_WIDTH_THRESHOLD: number = 500;

    constructor(
        private router: Router,
        private elementRef: ElementRef,
        private commonService: LCEIMFOCommonService) {
    }

    get serviceArrangement(): LCEIMFOServiceArrangement {
        return this.commonService.getServiceArrangement();
    }

    get serviceItems(): LCEIMFOHomeServiceItem[] {
        return this.commonService.getServiceItems();
    }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.updateHeaderIconSizes();
    }

    public search(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.certificate.order.search).then();
    }

    public settings(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.settings.index).then();
    }

    public scan(): void {
        console.log('---| Scanning ...');
    }

    private updateHeaderIconSizes(): void {
        const pageWidth: number = this.getElementRefWidth();
        this.headerIconSize = pageWidth >= this.PAGE_WIDTH_THRESHOLD ? XSSize.LARGE : XSSize.MEDIUM;
    }

    private getElementRefWidth(): number {
        return this.elementRef.nativeElement.offsetWidth;
    }
}
