import {Directive, Input} from '@angular/core';
import {XSAssert, XSUtils} from '@xs/base';
import {XSIColor, XSIColorStr, XSIIcon, XSIText} from '@xs/ionic/common';
import {XSSize} from '@xs/common';
import {LCEIResourceCodablePartial} from '../../../api/domain/lcei-resource';

@Directive()
export abstract class LCEIResourceCodablePartialComponent<T extends LCEIResourceCodablePartial> {

    @Input() styleClass?: string;
    @Input() fullNameStyleClass?: string;
    @Input() codeStyleClass?: string;
    @Input() labelStyleClass?: string;

    @Input() label?: string;
    @Input() labelColor?: XSIColor | XSIColorStr | string;

    @Input() disabled?: boolean;
    @Input() ellipsis?: boolean;

    @Input() data: T;

    @Input() showCode?: boolean;

    @Input() alignEnd?: boolean;

    @Input() showAvatar?: boolean;
    @Input() avatarPosition?: 'left' | 'right';
    @Input() avatarColor?: XSIColor | XSIColorStr | string;

    lineLabel: XSIText | undefined;
    lineFullName: XSIText;
    lineCode: XSIText | undefined;

    onInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        XSAssert.notEmpty(this.data.fullName, 'data.fullName');
        if (XSUtils.isNull(this.showCode)) this.showCode = false;
        if (XSUtils.isNull(this.showAvatar)) this.showAvatar = false;
        if (this.showAvatar && XSUtils.isEmpty(this.avatarColor)) this.avatarColor = XSIColor.SECONDARY;
        this.buildLines();
    }

    public getLine1(): XSIText {
        return this.hasLabel() ? this.lineLabel! : this.lineFullName;
    }

    public getLine2(): XSIText | undefined {
        return this.hasLabel() ? this.lineFullName : (this.showCode ? this.lineCode : undefined);
    }

    public getLine3(): XSIText | undefined {
        return (this.hasLabel() && this.showCode) ? this.lineCode : undefined;
    }

    public getAvatar(): XSIIcon | undefined {
        if (!this.showAvatar) return undefined;
        return {
            value: this.getIconValue(),
            size: XSSize.MEDIUM,
            box: true,
            boxSize: XSSize.INTERMEDIATE,
            color: this.avatarColor
        };
    }

    protected abstract getIconValue(): string;

    private buildLines(): void {
        this.lineLabel = this.hasLabel() ? {
            styleClass: this.labelStyleClass,
            value: this.label,
            size: XSSize.EXTRA_SMALL,
            color: this.labelColor
        } as XSIText : undefined;

        this.lineFullName = {
            styleClass: this.fullNameStyleClass,
            value: this.data.fullName.trim(),
            size: XSSize.INTERMEDIATE,
            fontWeight500: true
        };

        this.lineCode = this.showCode ? {
            styleClass: this.codeStyleClass,
            value: this.data.code.trim(),
            uppercase: true,
            size: XSSize.SMALL,
            color: XSIColor.SECONDARY
        } : undefined;
    }

    private hasLabel(): boolean {
        return !XSUtils.isEmpty(this.label);
    }
}
