import {Component, OnInit} from '@angular/core';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {Router} from '@angular/router';
import {LCEIMFOCertificateOrderCreateOptions} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {LCEIMFOCertificateOrderCreateOptionsService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-certificate-order-create-options.service';
import {XSIAlertService} from '@xs/ionic/core';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';

@Component({
    selector: 'lcei-mfo-settings-certificate-order-create-page',
    templateUrl: 'lcei-mfo-settings-certificate-order-create.page.html',
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsCertificateOrderCreatePage implements OnInit {

    readonly TR_BASE: string = 'lcei.mfo.features.settings.certificateOrderForm.';

    readonly ICON_CERTIFICATE_ORDER: XSIIcon = {
        value: LCEI_MFO_ICON.certificateOrder,
        box: true,
        boxSize: 'medium',
        color: 'primary',
        styleClass: 'lcei-certificate-icon-size-35'
    };

    createOptions: LCEIMFOCertificateOrderCreateOptions;

    constructor(
        private router: Router,
        private alertService: XSIAlertService,
        private certificateOrderCreateOptionsService: LCEIMFOCertificateOrderCreateOptionsService) {
    }

    ngOnInit(): void {
        this.createOptions = this.certificateOrderCreateOptionsService.getCreateOptions();
    }

    public reset(): void {
        this.alertService.showConfirmationAlert({
            header: this.TR_BASE + 'resetConfirmation.title',
            message: this.TR_BASE + 'resetConfirmation.message',
            onYesButtonClick: () => this.createOptions = this.certificateOrderCreateOptionsService.reset()
        }).then();
        console.log('Reset ...');
    }

    public onOptionSwitch(event: any, key: string) {
        const checked: boolean = event.detail.checked;
        this.certificateOrderCreateOptionsService.saveCreateOptions(key, checked);
        // Not really needed since it's the same memory address, but it feels good to have it here.
        this.createOptions = this.certificateOrderCreateOptionsService.getCreateOptions();
    }

    public close(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
    }
}
