export const LCEI_SHARED_ICON = {
    // --- User ---
    user: {
        municipalEmployee: 'fas fa-user',
        customer: 'fas fa-user-tie',
        employee: 'fas fa-user-ninja',
        deliveryMan: 'fas fa-id-card-alt',
        system: 'fas fa-user-cog'
    },
    anonymous: 'fas fa-user-secret',
    // --- *** ---
    menu: 'fas fa-ellipsis-v',
    payment: 'fas fa-money-bill',
    procuration: 'fas fa-people-arrows',
    location: 'fas fa-map-pin',
    international: 'fas fa-globe-europe',
    phone: 'fas fa-phone-alt',
    icons: 'fas fa-icons',
    layout: 'fas fa-indent',
    recentOutline: 'far fa-clock',
    recent: 'fas fa-clock',
    home: 'fas fa-home',
    form: 'far fa-list-alt',
    service: 'fas fa-briefcase',
    logout: 'fas fa-sign-out-alt',
    ok: 'fas fa-thumbs-up',
    information: 'fas fa-info',
    delivery: 'fas fa-truck',
    order: 'fas fa-box-open',
    certificateOrder: 'lcei-certificate-icon',
    certificate: 'lcei-certificate-icon',
    birth: 'fas fa-baby',
    wedding: 'fas fa-ring',
    death: 'fas fa-skull',
    suggestion: 'fas fa-lightbulb',
    facility: 'fas fa-building',
    servicePoint: 'fas fa-briefcase',
    municipality: 'fas fa-university',
    district: 'fas fa-map',
    settings: 'fas fa-cogs',
    setting: 'fas fa-cog',
    scan: 'fas fa-qrcode',
    qrcode: 'fas fa-qrcode',
    security: 'fas fa-shield-alt',
    membership: 'fas fa-id-card',
    stats: 'far fa-chart-bar',
    manager: 'fas fa-tasks',
    box: 'fas fa-box',
    book: 'fas fa-book',
    contact: 'fas fa-at',
    help: 'fas fa-info',
    send: 'fas fa-paper-plane',
    developerMode: 'fas fa-terminal',
    notification: 'fas fa-bell',
    language: 'fas fa-language',
    darkMode: 'fas fa-adjust',
    unread: 'fas fa-circle',
    noResult: 'fas fa-frown',
    edit: 'far fa-edit',
    bookmarked: 'fas fa-bookmark',
    bookmark: 'far fa-bookmark',
    share: 'fas fa-share-alt',
    liked: 'fas fa-heart',
    like: 'far fa-heart',
    comment: 'far fa-comment',
    view: 'far fa-eye',
    search: 'fas fa-search',
    link: 'fas fa-external-link-alt',
    thankYou: 'fas fa-handshake',
    mobile: 'fas fa-mobile-alt'
};
