import {Component, Input, OnInit} from '@angular/core';
import {LCECertificateOrder} from '@lce/core';
import {XSAssert, XSUtils} from '@xs/base';
import {LCEICertificateOrderRecordBaseComponent} from '../lcei-certificate-order-record-base.component';
import {LCEI_SHARED_ICON} from '../../../../api/constants/lcei-shared-icon.constant';
import {XSIIcon} from '@xs/ionic/common';

@Component({
    selector: 'lcei-certificate-order-record-administration',
    templateUrl: 'lcei-certificate-order-record-administration.component.html',
    host: {class: 'xs-flex-row xs-width-full'}
})
export class LCEICertificateOrderRecordAdministrationComponent extends LCEICertificateOrderRecordBaseComponent implements OnInit {

    readonly ICON_FACILITY: XSIIcon = {value: LCEI_SHARED_ICON.facility};

    @Input() styleClass?: string;

    @Input() data: LCECertificateOrder;
    
    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
        this.buildSectionHeader('administration', 'administrationDescription');
    }

    public hasQueueNumber(): boolean {
        return !XSUtils.isEmpty(this.data.queueNumber);
    }

    public hasStampTokenCode(): boolean {
        return !XSUtils.isEmpty(this.data.stampTokenCode);
    }

    protected getSectionHeaderIcon(): XSIIcon {
        return this.ICON_FACILITY;
    }
}


