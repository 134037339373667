import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {SplashScreen} from '@capacitor/splash-screen';
import {LOG, XS_STR_EMPTY, XSLanguage, XSLoggerService, XSUtils} from '@xs/base';
import {XSTranslationService} from '@xs/common';
import {XSIAppInitializerService, XSIGeolocationService} from '@xs/ionic/core';
import {forkJoin, Subject} from 'rxjs';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LCEIMFOHttpClientService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-http-client.service';
import {LCEIMFOEnvironmentService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-environment.service';
import {LCEIMFOAppInitialData} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-app-initial-data';
import {LCEIMFOAppConfig} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-app-config';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {LCEIMFOCertificateOrderCreateOptionsService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-certificate-order-create-options.service';
import {LCEIMFOCommonService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-common.service';
import {LCETerminalRegistrationResponse} from '@lce/core';
import {LCEIMFOUtils} from '@lce/ionic/mfo/app/core/utils/lcei-mfo-utils';

@Injectable({providedIn: 'root'})
export class LCEIMFOAppInitializerService extends XSIAppInitializerService {

    private preloadCompletedSubject = new Subject<void>();
    onPreloadCompleted = this.preloadCompletedSubject.asObservable();

    constructor(
        private router: Router,
        private loggerService: XSLoggerService,
        private translationService: XSTranslationService,
        private storageService: LCEIMFOStorageService,
        private environmentService: LCEIMFOEnvironmentService,
        private httpClientService: LCEIMFOHttpClientService,
        private geolocationService: XSIGeolocationService,
        private contextService: LCEIMFOContextService,
        private commonService: LCEIMFOCommonService,
        private certificateOrderCreateOptionsService: LCEIMFOCertificateOrderCreateOptionsService) {
        super();

        this.contextService.onInitialized.subscribe(state => {
            if (!state || !this.contextService.hasUser()) return;
            setTimeout(() => this.preload(), 100);
        });
    }

    public async initialize(): Promise<void> {
        try {
            LOG().debug('Initializing ...');
            // Retrieve app config.
            const config: LCEIMFOAppConfig = await this.httpClientService.get<LCEIMFOAppConfig>(this.CONFIG_JSON_PATH).toPromise();
            this.contextService.initializeConfig(config);
            this.loggerService.initialize(config.logger);
            LOG().debug('[' + config.environment.toUpperCase() + '] config successfully retrieved.', config);

            this.configLoadedSubject.next(config);

            const language: XSLanguage = await this.initializeTranslation();

            this.httpClientService.initialize(config.apiBaseURL, language);

            try {
                //await this.geolocationService.initialize(LCEI_DEFAULT_COUNTRY_ISO);
            } catch (e) {

            }

            let initialData: LCEIMFOAppInitialData = await this.retrieveAppInitialData();

            const terminalRegistration = await this.storageService.getStoredTerminalRegistration();

            let redirectToURL: string;

            if (LCEIMFOUtils.isTerminalRegistered(terminalRegistration)) {
                redirectToURL = XS_STR_EMPTY;
                await this.setUp(terminalRegistration);
            } else {
                redirectToURL = LCEI_MFO_ROUTING.starter.index;
            }

            this.contextService.setSettings(initialData.mfoSettings);
            this.contextService.setAsInitialized();

            LOG().debug(`Initialization DOne :-) . [redirectToURL: ${redirectToURL}]`, 'Settings: ', initialData.mfoSettings);

            if (!XSUtils.isEmpty(redirectToURL)) this.router.navigateByUrl(redirectToURL).then();
        } catch (error) {
            const eMessage = 'Something wrong happened during the app initialization :-(';
            LOG().error(eMessage, error);
            console.error(eMessage, error); // Uncomment to have more details if an error occurred during the initialization.
            await SplashScreen.hide();
        }
    }

    public preload(): void {
        LOG().debug('---| Preloading data ...');
        forkJoin([
            // this.certificateOrderModuleService.findLast(),
        ])
            .subscribe(() => {
                LOG().debug('---| Data preloading successfully done ...');
                this.preloadCompletedSubject.next();
            });
    }

    public async setUp(terminalRegistration: LCETerminalRegistrationResponse): Promise<void> {
        this.httpClientService.setHeaderAuthorization(terminalRegistration.token);
        this.contextService.setTerminal(terminalRegistration.terminal);
        await this.serviceInitialization();
    }

    private async serviceInitialization(): Promise<void> {
        await this.commonService.initialize();
        await this.certificateOrderCreateOptionsService.initialize();
    }

    private async retrieveAppInitialData(): Promise<LCEIMFOAppInitialData> {
        // TODO
        // const initialData: LCEIMFOAppInitialData = await this.httpClientService.get<LCEIMFOAppInitialData>(LCEI_MFO_ENDPOINT.initialization).toPromise();
        const initialData: LCEIMFOAppInitialData = this.getFakeAppInitialData();
        this.storageService.storeAppInitialData(initialData);
        return initialData;
    }

    private async initializeTranslation(): Promise<XSLanguage> {
        const storedLanguage: XSLanguage | undefined = await this.storageService.getStoredLanguage();
        const language: XSLanguage = XSUtils.isEmpty(storedLanguage) ? this.environmentService.getDefaultLanguage() : storedLanguage!;

        await this.translationService.initialize(language);

        this.translationService.onLanguageChanged.subscribe(language => {
                if (XSUtils.isEmpty(language)) return;
                this.httpClientService.setHeaderLanguage(language);
                this.storageService.storeLanguage(language);
            }
        );

        return language;
    }

    private getFakeAppInitialData(): LCEIMFOAppInitialData {
        return {
            mfoSettings: {
                sessionDuration: 3600
            }
        };
    }
}
