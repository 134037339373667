import {Injectable} from '@angular/core';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEIMFOHomeServiceItem, LCEIMFOServiceActivation, LCEIMFOServiceArrangement, LCEIMFOServiceArrangementMode, LCEIMFOServiceID} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {XSIIcon} from '@xs/ionic/common';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {Router} from '@angular/router';
import {XSAssert, XSUtils} from '@xs/base';

@Injectable({providedIn: 'root'})
export class LCEIMFOCommonService {

    private readonly TR_BASE: string = 'lcei.mfo.features.home.';

    private readonly ICON_CERTIFICATE_ORDER: XSIIcon = {
        value: LCEI_MFO_ICON.certificateOrder,
        size: '35px',
        box: true,
        boxSize: 'large',
        color: 'primary',
        styleClass: 'lcei-certificate-icon-size-45',
        onClick: () => this.certificateOrderCreate()
    };
    private readonly ICON_BIRTH_DECLARATION: XSIIcon = {
        value: LCEI_MFO_ICON.birth,
        size: '37px',
        box: true,
        boxSize: 'large',
        color: 'primary',
        onClick: () => this.birthDeclaration()
    };
    private readonly ICON_WEDDING_BOOKING: XSIIcon = {
        value: LCEI_MFO_ICON.wedding,
        size: '40px',
        box: true,
        boxSize: 'large',
        color: 'primary',
        onClick: () => this.weddingBooking()
    };
    private readonly ICON_INFORMATION: XSIIcon = {
        value: LCEI_MFO_ICON.information,
        size: '35px',
        box: true,
        boxSize: 'large',
        color: 'primary',
        onClick: () => this.information()
    };

    private serviceItems: LCEIMFOHomeServiceItem[];

    private serviceActivation: LCEIMFOServiceActivation;
    private serviceArrangement: LCEIMFOServiceArrangement;

    constructor(private router: Router, private storageService: LCEIMFOStorageService) {
    }

    public getServiceArrangement(): LCEIMFOServiceArrangement {
        return this.serviceArrangement;
    }

    public getServiceActivation(): LCEIMFOServiceActivation {
        return this.serviceActivation;
    }

    public getServiceItems(): LCEIMFOHomeServiceItem[] {
        return this.serviceItems;
    }

    public saveServiceArrangement(arrangementPropertyKey: string, arrangementPropertyValue: any): void {
        XSAssert.notEmpty(arrangementPropertyKey, 'arrangementPropertyKey');
        XSUtils.setNestedPropertyValue(this.serviceArrangement, arrangementPropertyKey, arrangementPropertyValue);
        this.storageService.storeServiceArrangementItem(arrangementPropertyKey, arrangementPropertyValue);
    }

    public saveServiceActivation(serviceKey: string, serviceState: boolean): void {
        XSAssert.notEmpty(serviceKey, 'serviceKey');
        XSAssert.notEmpty(serviceState, 'serviceState');
        XSUtils.setNestedPropertyValue(this.serviceActivation, serviceKey, serviceState);
        this.storageService.storeServiceActivationItem(serviceKey, serviceState);
        this.buildServiceItems();
    }

    public async initialize(): Promise<void> {
        await this.loadServiceConfiguration();
        this.buildServiceItems();
    }

    private async loadServiceConfiguration(): Promise<void> {
        const serviceConfiguration = await this.storageService.getStoredServiceConfiguration();
        this.serviceActivation = serviceConfiguration?.activation;
        this.serviceArrangement = serviceConfiguration?.arrangement;

        if (XSUtils.isNull(this.serviceActivation)) this.serviceActivation = {};
        if (XSUtils.isNull(this.serviceActivation.certificateOrderCreate)) this.serviceActivation.certificateOrderCreate = true;
        if (XSUtils.isNull(this.serviceActivation.birthDeclaration)) this.serviceActivation.birthDeclaration = true;
        if (XSUtils.isNull(this.serviceActivation.weddingBooking)) this.serviceActivation.weddingBooking = true;
        if (XSUtils.isNull(this.serviceActivation.information)) this.serviceActivation.information = true;

        if (XSUtils.isNull(this.serviceArrangement)) this.serviceArrangement = {mode: LCEIMFOServiceArrangementMode.VERTICAL};

        this.storageService.storeServiceActivation(this.serviceActivation);
        this.storageService.storeServiceArrangement(this.serviceArrangement);
    }

    private buildServiceItems(): void {
        this.serviceItems = [];
        if (this.serviceActivation.certificateOrderCreate) {
            this.serviceItems.push({
                id: LCEIMFOServiceID.CERTIFICATE_ORDER_CREATE,
                icon: this.ICON_CERTIFICATE_ORDER,
                title: this.TR_BASE + 'service.certificateOrderCreate.line1',
                subtitle: this.TR_BASE + 'service.certificateOrderCreate.line2',
                onClick: () => this.certificateOrderCreate()
            });
        }
        if (this.serviceActivation.birthDeclaration) {
            this.serviceItems.push({
                id: LCEIMFOServiceID.BIRTH_DECLARATION,
                icon: this.ICON_BIRTH_DECLARATION,
                title: this.TR_BASE + 'service.birthDeclaration.line1',
                subtitle: this.TR_BASE + 'service.birthDeclaration.line2',
                onClick: () => this.birthDeclaration(),
                comingSoon: true
            });
        }
        if (this.serviceActivation.weddingBooking) {
            this.serviceItems.push({
                id: LCEIMFOServiceID.WEDDING_BOOKING,
                icon: this.ICON_WEDDING_BOOKING,
                title: this.TR_BASE + 'service.weddingBooking.line1',
                subtitle: this.TR_BASE + 'service.weddingBooking.line2',
                onClick: () => this.weddingBooking(),
                comingSoon: true
            });
        }
        if (this.serviceActivation.information) {
            this.serviceItems.push({
                id: LCEIMFOServiceID.INFORMATION,
                icon: this.ICON_INFORMATION,
                title: this.TR_BASE + 'service.information.line1',
                subtitle: this.TR_BASE + 'service.information.line2',
                onClick: () => this.information(),
                comingSoon: true
            });
        }
    }

    private certificateOrderCreate(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.certificate.order.create).then();
    }

    private birthDeclaration(): void {
        console.log('---| Birth Declaration [Not implemented yet].');
    }

    private weddingBooking(): void {
        console.log('---| Wedding Booking [Not implemented yet].');
    }

    private information(): void {
        console.log('---| Information [Not implemented yet].');
    }
}
