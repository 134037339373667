import {Injectable} from '@angular/core';
import {XSIStorageService} from '@xs/ionic/base';
import {
    LCEI_MFO_STORAGE_KEY,
    LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER,
    LCEI_MFO_STORAGE_KEY_SERVICE_ACTIVATION,
    LCEI_MFO_STORAGE_KEY_SERVICE_ARRANGEMENT,
    LCEI_MFO_STORAGE_KEY_SERVICE_CONFIGURATION,
    LCEI_MFO_STORAGE_KEY_TERMINAL_REGISTRATION
} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {XSAssert} from '@xs/base';
import {LCECertificateOrderCreate, LCEMFOSettings, LCETerminalRegistrationResponse, LCEUserMunicipalEmployeePartial} from '@lce/core';
import {LCEI_STORAGE_KEY_INITIAL_DATA, LCEI_STORAGE_KEY_USER, LCEIStorageService} from '@lce/ionic/shared';
import {LCEIMFOCertificateOrderCreateOptions, LCEIMFOCertificateOrderLast, LCEIMFOCertificateOrderSearchHistoryItem, LCEIMFOCertificateOrderStorage} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-certificate-order';
import {LCEIMFOServiceActivation, LCEIMFOServiceArrangement, LCEIMFOServiceConfiguration} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {LCEIMFOAppInitialData} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-app-initial-data';
import {XSUtils} from '@iro-xs/xs-base';

@Injectable({providedIn: 'root'})
export class LCEIMFOStorageService extends LCEIStorageService {

    constructor(protected storageService: XSIStorageService) {
        super(storageService);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Service Arrangement ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeServiceArrangementItem(arrangementPropertyKey: string, arrangementPropertyValue: any): void {
        XSAssert.notEmpty(arrangementPropertyKey, 'arrangementPropertyKey');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_SERVICE_ARRANGEMENT}.${arrangementPropertyKey}`, arrangementPropertyValue).then();
    }

    public storeServiceArrangement(serviceArrangement: LCEIMFOServiceArrangement): void {
        XSAssert.notNull(serviceArrangement, 'serviceArrangement');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_ARRANGEMENT, serviceArrangement).then();
    }

    public getStoredServiceArrangement(): Promise<LCEIMFOServiceArrangement> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_ARRANGEMENT);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Service Activation ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeServiceActivationItem(serviceKey: string, serviceState: boolean): void {
        XSAssert.notEmpty(serviceKey, 'serviceKey');
        XSAssert.notNull(serviceState, 'serviceState');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_SERVICE_ACTIVATION}.${serviceKey}`, serviceState).then();
    }

    public storeServiceActivation(serviceActivation: LCEIMFOServiceActivation): void {
        XSAssert.notNull(serviceActivation, 'serviceActivation');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_ACTIVATION, serviceActivation).then();
    }

    public getStoredServiceActivation(): Promise<LCEIMFOServiceActivation> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_ACTIVATION);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Service Configuration ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeServiceConfiguration(serviceConfiguration: LCEIMFOServiceConfiguration): void {
        XSAssert.notNull(serviceConfiguration, 'serviceConfiguration');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_CONFIGURATION, serviceConfiguration).then();
    }

    public getStoredServiceConfiguration(): Promise<LCEIMFOServiceConfiguration> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_SERVICE_CONFIGURATION);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Certificate Order ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeCertificateOrderSearchHistoryItems(searchHistoryItems: LCEIMFOCertificateOrderSearchHistoryItem[]): void {
        XSAssert.notNull(searchHistoryItems, 'searchHistoryItems');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER}.searchHistoryItems`, searchHistoryItems).then();
    }

    public storeCertificateOrderCreateOptions(createOptions: LCEIMFOCertificateOrderCreateOptions): void {
        XSAssert.notNull(createOptions, 'createOptions');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER}.createOptions`, createOptions).then();
    }

    public storeCertificateOrderCreate(create: LCECertificateOrderCreate): void {
        XSAssert.notEmpty(create, 'create');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER}.create`, create).then();
    }

    public storeCertificateOrderLast(last: LCEIMFOCertificateOrderLast): void {
        XSAssert.notEmpty(last, 'last');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, `${LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER}.last`, last).then();
    }

    public getStoredCertificateOrder(): Promise<LCEIMFOCertificateOrderStorage> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- User ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public storeUser(user: LCEUserMunicipalEmployeePartial): void {
        XSAssert.notEmpty(user, 'user');
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_STORAGE_KEY_USER, user).then();
    }

    public getStoredUser(): Promise<LCEUserMunicipalEmployeePartial> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_STORAGE_KEY_USER);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Terminal ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public async clearStoredTerminalRegistration(): Promise<void> {
        await this.storageService.removeObjectProperty(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_TERMINAL_REGISTRATION);
    }

    public storeTerminalRegistration(terminalRegistrationResponse: LCETerminalRegistrationResponse): void {
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_TERMINAL_REGISTRATION, terminalRegistrationResponse).then();
    }

    public async getStoredTokenExpiration(): Promise<string> {
        const appInitialData = await this.getStoredTerminalRegistration();
        return appInitialData.tokenExpirationDate;
    }

    public async getStoredToken(): Promise<string> {
        const appInitialData = await this.getStoredTerminalRegistration();
        return appInitialData.token;
    }

    public getStoredTerminalRegistration(): Promise<LCETerminalRegistrationResponse> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_TERMINAL_REGISTRATION);
    }

    public async isTerminalRegistered(): Promise<boolean> {
        const terminalRegistration: LCETerminalRegistrationResponse = await this.getStoredTerminalRegistration();
        return !XSUtils.isEmpty(terminalRegistration?.token) && !XSUtils.isEmpty(terminalRegistration?.tokenExpirationDate) && !XSUtils.isEmpty(terminalRegistration?.terminal);
    }

    public isTerminalRegistrationResponseRegistered(terminalRegistration: LCETerminalRegistrationResponse): boolean {
        return !XSUtils.isEmpty(terminalRegistration?.token) && !XSUtils.isEmpty(terminalRegistration?.tokenExpirationDate) && !XSUtils.isEmpty(terminalRegistration?.terminal);
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- Initial Data  ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public async clearStoredAppInitialData(): Promise<void> {
        await this.storageService.removeObjectProperty(LCEI_MFO_STORAGE_KEY, LCEI_STORAGE_KEY_INITIAL_DATA);
    }

    public async getStoredSettings(): Promise<LCEMFOSettings> {
        const appInitialData = await this.getStoredAppInitialData();
        return appInitialData.mfoSettings;
    }

    public getStoredAppInitialData(): Promise<LCEIMFOAppInitialData> {
        return this.storageService.getObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_STORAGE_KEY_INITIAL_DATA);
    }

    public storeAppInitialData(appInitialData: LCEIMFOAppInitialData): void {
        this.storageService.setObjectPropertyValue(LCEI_MFO_STORAGE_KEY, LCEI_STORAGE_KEY_INITIAL_DATA, appInitialData).then();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- *** ---
    // ---------------------------------------------------------------------------------------------------------------------------

    public clear(all: boolean): void {
        if (all) {
            (this.storageService.remove(LCEI_MFO_STORAGE_KEY) as Promise<void>).then();
            return;
        }
        (this.storageService.removeObjectProperty(LCEI_MFO_STORAGE_KEY, LCEI_MFO_STORAGE_KEY_CERTIFICATE_ORDER) as Promise<void>).then();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    // --- *** ---
    // ---------------------------------------------------------------------------------------------------------------------------

    protected getRootKey(): string {
        return LCEI_MFO_STORAGE_KEY;
    }
}
