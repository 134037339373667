import {XSLanguage} from '@xs/base';
import {LCERequestorType} from '@lce/core';
import {LCEIMFOEnvironmentCommon} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-environment';

export const ADDITIONAL_IMPORTS: any[] = [];
export const ADDITIONAL_PROVIDERS: any[] = [];

export const GOOGLE_MAPS_API_KEY_NON_PROD: string = 'AIzaSyCN5ORSWp5FdbCsXD4KkZyq9zrE0IJme3s';

export const environmentCommon: LCEIMFOEnvironmentCommon = {
    appPrefix: 'lce-mfo',
    appDisplayName: 'LCE Front Office',
    requestor: LCERequestorType.MUNICIPALITY_FRONT_OFFICE_APP,
    availableLanguages: [XSLanguage.ENGLISH, XSLanguage.FRENCH],
    defaultLanguage: XSLanguage.FRENCH
};
