import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {XSIAlertService, XSISelectOption, XSIToastService} from '@xs/ionic/core';
import {FormControl} from '@angular/forms';
import {XSEnvironmentName} from '@xs/base';
import {LCEI_MFO_ICON} from '@lce/ionic/mfo/app/core/constants/lcei-mfo-icon.constant';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';
import {LCEIMFOAppInitializerService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-app-initializer.service';
import {LCEIMFOContextService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-context.service';
import {LCEIMFOEnvironmentService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-environment.service';
import {LCEIMFOCacheService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-cache.service';
import {LCEI_MFO_ROUTING} from '@lce/ionic/mfo/app/core/constants/lcei-mfo.constant';
import {XSIIcon} from '@xs/ionic/common';

@Component({
    selector: 'lcei-mfo-settings-developer-mode-page',
    templateUrl: 'lcei-mfo-settings-developer-mode.page.html',
    host: {class: 'lcei-mfo-settings'}
})
export class LCEIMFOSettingsDeveloperModePage implements OnInit {

    readonly ICON_EDIT: string = LCEI_MFO_ICON.edit;

    readonly TR_BASE: string = 'lcei.mfo.features.settings.';

    readonly ICON_DEVELOPER_MODE: XSIIcon = {
        value: LCEI_MFO_ICON.developerMode,
        size: '25px',
        box: true,
        boxSize: 'medium',
        color: 'primary'
    };

    environmentControl: FormControl = new FormControl();

    environmentOptions: XSISelectOption[] = [
        {label: 'NOBACKEND', value: XSEnvironmentName.NOBACKEND},
        {label: 'LOCAL', value: XSEnvironmentName.LOCAL},
        {label: 'DEV', value: XSEnvironmentName.DEV},
        {label: 'TEST', value: XSEnvironmentName.TEST},
        {label: 'QA', value: XSEnvironmentName.QA},
        {label: 'PROD', value: XSEnvironmentName.PROD}
    ];

    currentEnvironment: XSEnvironmentName;
    currentAPIBaseURL: string;
    appVersion: string;

    constructor(
        private router: Router,
        private storageService: LCEIMFOStorageService,
        private environmentService: LCEIMFOEnvironmentService,
        private contextService: LCEIMFOContextService,
        private appInitializerService: LCEIMFOAppInitializerService,
        private cacheService: LCEIMFOCacheService,
        private toastService: XSIToastService,
        private alertService: XSIAlertService) {
    }

    ngOnInit(): void {
        this.currentEnvironment = this.contextService.getAppConfig().environment;
        this.environmentControl.setValue(this.currentEnvironment);
        this.currentAPIBaseURL = this.contextService.getAppConfig().apiBaseURL;
        this.appVersion = this.environmentService.getAppVersion();
    }

    public editAPIBaseURL(): void {
        console.log('---| editAPIBaseURL ...');
    }

    public disableCaching(): void {
        this.alertService.showConfirmationAlert({
            header: 'Disable caching',
            message: 'Are you sure you want to disable the caching feature for the whole application ?',
            onYesButtonClick: () => {
                this.cacheService.disableCaching();
                this.appInitializerService.preload();
                this.toastService.showToastSuccess('Caching feature successfully disabled.', 'bottom', 3000);
            }
        }).then();
    }

    public close(): void {
        this.router.navigateByUrl(LCEI_MFO_ROUTING.home.index).then();
    }
}
