import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {LCEIMFOModule} from '@lce/ionic/mfo/app/lcei-mfo.module';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(LCEIMFOModule).catch(err => console.log(err));
