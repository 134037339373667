import {Component, HostListener} from '@angular/core';
import {SplashScreen} from '@capacitor/splash-screen';
import {Platform} from '@ionic/angular';
import {XSISchemeColor} from '@xs-ionic/base';
import {XSISchemeService} from '@xs-ionic/core';
import {XSLanguage} from '@xs/base';
import {XSTranslationService} from '@xs/common';

@Component({selector: 'lcei-mfo', templateUrl: 'lcei-mfo.component.html'})
export class LCEIMFOComponent {

    constructor(
        private platform: Platform,
        private schemeService: XSISchemeService,
        private translationService: XSTranslationService) {

        this.schemeService.initialize().then();
        this.platform.ready().then(() => {
            SplashScreen.hide().then();
        });
    }
    
    @HostListener('document:keydown.control.d')
    onCtrlD() {
        const currentSchemeColor: XSISchemeColor = this.schemeService.getCurrentSchemeColor();
        const newSchemeColor = currentSchemeColor === XSISchemeColor.DARK ? XSISchemeColor.LIGHT : XSISchemeColor.DARK;
        this.schemeService.changeSchemeColor(newSchemeColor);
        console.log('Changed Scheme Color From [' + currentSchemeColor.toString().toUpperCase() + '] to [' + newSchemeColor.toString().toUpperCase() + '].');
    }

    @HostListener('document:keydown.control.l')
    onCtrlL() {
        const language: XSLanguage = this.translationService.getCurrentLanguage() === XSLanguage.ENGLISH ? XSLanguage.FRENCH : XSLanguage.ENGLISH;
        this.translationService.changeCurrentLanguage(language).subscribe();
    }
}
