import {Component, Input} from '@angular/core';

@Component({
    selector: 'lcei-copyright',
    template: `
        <xsi-copyright text="lcei.shared.label.copyright" [styleClass]="styleClass"></xsi-copyright>
    `
})
export class LCEICopyrightComponent {

    @Input() styleClass?: string;
}


