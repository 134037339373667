import {Component, Input, OnInit} from '@angular/core';
import {LCEIMFOHomeServiceItem} from '@lce/ionic/mfo/app/core/domain/lcei-mfo';
import {XSAssert} from '@xs/base';

@Component({
    selector: 'lcei-mfo-home-service-items-horizontal',
    templateUrl: 'lcei-mfo-home-service-items-horizontal.component.html',
    host: {class: 'xs-flex-row xs-width-full xs-max-width-750'}
})
export class LCEIMFOHomeServiceItemsHorizontalComponent implements OnInit {

    @Input() styleClass?: string;
    @Input() itemContainerStyleClass?: string;
    @Input() itemHostStyleClass?: string;
    @Input() itemStyleClass?: string;

    @Input() data: LCEIMFOHomeServiceItem[];

    ngOnInit(): void {
        XSAssert.notEmpty(this.data, 'data');
    }

    public onClick(serviceItem: LCEIMFOHomeServiceItem, event: Event): void {
        if (this.isDisabled(serviceItem)) {
            event.stopImmediatePropagation();
            return;
        }
        serviceItem.onClick(event);
    }

    public isDisabled(serviceItem: LCEIMFOHomeServiceItem): boolean {
        return serviceItem.disabled || serviceItem.comingSoon;
    }
}
