<ion-header [translucent]="true" class="xsi-ion-header xs-pb-30-imp">
    <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
        <lcei-app-name
                (doubleTapEvent)="settings()"
                [facilityName]="facilityName"
                [showFacilityName]="showFacilityName"
                class="xs-width-percent-50-imp"
                type="image">
        </lcei-app-name>
        <div class="xs-flex-row xs-align-items-center xs-pr-10">
            <xsi-icon
                    (clickEvent)="search()"
                    [size]="headerIconSize"
                    [value]="ICON_SEARCH"
                    color="primary"
                    styleClass="xs-mr-50">
            </xsi-icon>
            <xsi-icon
                    (clickEvent)="scan()"
                    [size]="headerIconSize"
                    [value]="ICON_SCAN"
                    color="primary">
            </xsi-icon>
        </div>
    </div>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center">
        <div class="lcei-mfo-page">
            <div class="lcei-mfo-home-page">
                <div class="xs-flex-column-center xs-width-full">
                    <lcei-mfo-home-service-items [arrangement]="serviceArrangement" [items]="serviceItems"></lcei-mfo-home-service-items>
                </div>
            </div>
        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>
