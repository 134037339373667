import {XSBuiltEnvironmentName} from '@xs/base';
import {environmentCommon} from '@lce/ionic/mfo/environments/environment-common';
import {LCEIMFOEnvironment} from '@lce/ionic/mfo/app/core/domain/lcei-mfo-environment';

export const environment: LCEIMFOEnvironment = {
    production: true,
    name: XSBuiltEnvironmentName.WEB,
    common: environmentCommon,
    googleMapsApiKey: '***' // TODO
};
