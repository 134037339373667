<ion-header class="xsi-ion-header">
    <xsi-page-header
            (cancelButtonClickEvent)="close()"
            [cancelButton]="true"
            [title]="TR_BASE + 'mainScreenServiceArrangement'"
            backButtonDefaultHref="/settings"
            cancelButtonLabel="xsi.core.label.close"
            titlePosition="bottom-center"
            titleSize="medium">
    </xsi-page-header>
</ion-header>
<ion-content>
    <div class="xsi-ion-content xs-align-items-center xs-scrollbar-hide">
        <div class="lcei-mfo-page xs-flex-1 xs-pt-30">

            <xsi-icon-renderer
                    [data]="ICON_MAIN_SCREEN_SERVICE_ARRANGEMENT"
                    class="xs-flex-row-center xs-width-full xs-mb-50"
                    styleClass="xs-filter-brightness-98">
            </xsi-icon-renderer>

            <ion-card [ngClass]="{ 'xs-disabled': !hasServiceItems() }" mode="ios">
                <ion-item lines="inset">
                    <ion-label class="ion-text-wrap">
                        <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                            <xsi-text value="{{ TR_BASE }}serviceArrangementMode"></xsi-text>
                            <xsi-input-select
                                    (valueChange)="onArrangementModeChange($event)"
                                    [control]="serviceArrangementModeControl"
                                    [multiple]="false"
                                    [options]="serviceArrangementModeOptions"
                                    class="xs-display-block"
                                    interfaceType="action-sheet"
                                    styleClass="xs-border-bottom-none-imp xs-font-weight-500">
                            </xsi-input-select>
                        </div>
                    </ion-label>
                </ion-item>
            </ion-card>

            <ng-container *ngIf="isArrangementModeCard() && hasServiceItems()">
                <div class="xs-flex-column xs-width-full xs-plr-15 xs-mb-10">
                    <xsi-text [fontWeight500]="true" color="secondary" size="intermediate" value="lcei.mfo.serviceArrangementMode.card"></xsi-text>
                </div>
                <ion-card class="xs-mtb-0-imp" mode="ios">
                    <ion-item lines="inset">
                        <ion-label class="ion-text-wrap">
                            <xsi-ivar [line1]="{ value: TR_BASE + 'serviceArrangement.card.showTitle' }"></xsi-ivar>
                        </ion-label>
                        <ion-toggle
                                (ionChange)="onArrangementCardShowTitleChange($event)"
                                [checked]="serviceArrangement?.cardOptions?.showTitle"
                                class="xs-pt-14-imp xs-align-self-start"
                                mode="md">
                        </ion-toggle>
                    </ion-item>
                    <ion-item lines="inset">
                        <ion-label class="ion-text-wrap">
                            <div class="xs-flex-row xs-align-items-center xs-justify-content-space-between">
                                <xsi-text value="{{ TR_BASE }}serviceArrangement.card.backgroundColor"></xsi-text>
                                <xsi-input-select
                                        (valueChange)="onArrangementCardBackgroundColorChange($event)"
                                        [control]="serviceArrangementCardBackgroundColorControl"
                                        [multiple]="false"
                                        [options]="serviceArrangementCardBackgroundColorOptions"
                                        class="xs-display-block"
                                        interfaceType="action-sheet"
                                        styleClass="xs-border-bottom-none-imp xs-font-weight-500">
                                </xsi-input-select>
                            </div>
                        </ion-label>
                    </ion-item>
                </ion-card>
            </ng-container>

            <div class="xs-flex-row-center xs-width-full xs-mt-25 xs-scrollbar-hide">
                <div class="lcei-mfo-settings-main-virtual-screen">
                    <lcei-mfo-home-service-items
                            [arrangement]="serviceArrangement"
                            [items]="serviceItems"
                            [ngClass]="{'xs-height-450': !hasServiceItems() }">
                    </lcei-mfo-home-service-items>
                </div>
            </div>

        </div>
    </div>
</ion-content>
<ion-footer class="xsi-ion-footer xsi-ion-footer-copyright" mode="ios">
    <lcei-copyright></lcei-copyright>
</ion-footer>

