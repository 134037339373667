import {Injectable} from '@angular/core';
import {LCEIMFOStorageService} from '@lce/ionic/mfo/app/core/services/lcei-mfo-storage.service';

@Injectable()
export class LCEIMFOSettingsModuleService {


    constructor(private storageService: LCEIMFOStorageService) {
    }
}
